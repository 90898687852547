/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined } from "@ant-design/icons";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchOrder50453FromFirestore } from "../../../firestore/firestoreService";
import {
  listenToOrder50453,
  listenToOrder50453Filter,
} from "../../../redux/data/order50453/order50453Actions";
import { Button, DatePicker, Input } from "../../atoms";
const { RangePicker } = DatePicker;
export const OrderListListToolbar: React.FC = () => {
  const { filterOrder50453 } = useSelector((state: any) => state.order50453);
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchOrder50453FromFirestore([]),
    data: listenToOrder50453,
    deps: [dispatch],
  });
  const handelRestFilter = () => {
    dispatch(listenToOrder50453Filter({}));
  };
  const handelOnSearch = (value: string) => {
    dispatch(
      listenToOrder50453Filter({ ...filterOrder50453, orderId: [value] })
    );
  };
  const handelOnData = (value: any) => {
    console.log(value);
    dispatch(
      listenToOrder50453Filter({ ...filterOrder50453, createAt: [value] })
    );
  };
  return (
    <PageToolbar>
      <div></div>
      <div className="page-toolbar-right-children-elements">
        <Input.Search
          placeholder="Search Order"
          allowClear
          onSearch={handelOnSearch}
          style={{ width: 304 }}
        />
        <RangePicker
          allowClear
          onChange={handelOnData}
          style={{ width: 304 }}
        />
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          Reset Filters
        </Button>
      </div>
    </PageToolbar>
  );
};
