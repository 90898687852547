/* eslint-disable no-console */
import { MinusCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { find } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { IIngredient56227 } from "../../../common/types";
import { Form } from "../../atoms";
import {
  IngredientSelectedField,
  InputField,
  InputNumberField,
} from "../commonFields";

interface IBookingExtra {
  field: any;
  index: number;
  remove: (value: number) => void;
}
export const ProductionLineGoods: React.FC<IBookingExtra> = ({
  field,
  index,
  remove,
}) => {
  const { ingredient56227 } = useSelector(
    (state: any) => state.ingredient56227
  );
  const form = Form.useFormInstance();
  const goodsValue = Form.useWatch(["goods", field.name], form) || {};
  React.useEffect(() => {
    const ingredient: IIngredient56227 = find(ingredient56227, [
      "id",
      goodsValue.ingredientId || "",
    ]);
    if (ingredient) {
      form.setFieldValue(["goods", field.name], {
        ...goodsValue,
        quantity: goodsValue.quantity || 1,
        unit: ingredient.unitId,
      });
    }
  }, [goodsValue.ingredientId]);
  // React.useEffect(() => {
  //   if (extraValue && extraValue.extraId) {
  //     form.setFieldValue(["bookingExtras", field.name], {
  //       ...extraValue,
  //       total:
  //         +(extraValue.quantity || 1) *
  //         +(extraValue.discountPrice || extraValue.price || 0),
  //     });
  //   }
  // }, [extraValue.discountPrice, extraValue.quantity]);

  return (
    <Row key={field.key} style={{ width: "100%" }} gutter={8}>
      <Col span={8}>
        {" "}
        <IngredientSelectedField
          label={index === 0 ? "Ingredient" : ""}
          name={[field.name, "ingredientId"]}
          placeholder="Ingredient"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Missing Ingredient" }]}
        />
      </Col>
      <Col span={7}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Quantity" : ""}
          name={[field.name, "quantity"]}
          min={1}
          placeholder="Quantity"
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={7}>
        {" "}
        <InputField
          label={index === 0 ? "Unit" : ""}
          name={[field.name, "unit"]}
          placeholder="Unit"
          style={{ width: "100%" }}
          disabled
        />
      </Col>
      <Col span={2}>
        {" "}
        <MinusCircleOutlined
          className="dynamic-delete-button"
          onClick={() => remove(field.name)}
          style={{ marginLeft: 20 }}
        />
      </Col>
    </Row>
  );
};
