/* eslint-disable no-console */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { find, forEach, get } from "lodash";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Alert, Button, Form, InputFormWrapper, Tabs } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { PageToolbar, StockCategory } from "../../molecules";
import {
  updateOrderSuggestion71819InFirestore,
  fetchLocation1688FromFirestore,
  listenToLocation1688FromFirestore,
  listenToOrderSuggestion71819FromFirestore,
} from "../../../firestore/firestoreService";
import {
  ILocation1688,
  IOrderSuggestion71819,
  IOrderSuggestion71819Input,
} from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { listenToLocation1688Locations } from "../../../redux/data/location1688/location1688Actions";
import { productionTypes } from "../../../enums";

export const UpdateSuggestionOrder: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const { location1688Locations } = useSelector(
    (state: any) => state.location1688
  );
  const { category59983 } = useSelector((state: any) => state.category59983);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [loading, setLoading] = React.useState(false);
  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const [orderSuggestion, setOrderSuggestion] =
    React.useState<IOrderSuggestion71819>();
  const [error, setError] = React.useState("");
  const params = useParams();
  const suggestionOrderId = params.suggestionOrderId || "";
  const productionLineId = params.productionLineId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToOrderSuggestion71819FromFirestore(suggestionOrderId),
    data: setOrderSuggestion,
    local: true,
    deps: [dispatch, suggestionOrderId],
    shouldExecute: !!suggestionOrderId,
  });
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });
  useFirestoreCollection({
    query: () =>
      fetchLocation1688FromFirestore([
        {
          type: "filter",
          name: "type",
          opr: "==",
          value: "LOCATION",
        },
      ]),
    data: listenToLocation1688Locations,
    deps: [dispatch],
  });
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values: IOrderSuggestion71819Input = await form.validateFields();
      console.log(values);
      forEach(values.stocks, (value, key: string) => {
        forEach(value, (locationValue, LocationKey) => {
          values.stocks[key][LocationKey] = {
            locationCurrentStock: locationValue?.locationCurrentStock || 0,
            locationSuggestionStock:
              locationValue?.locationSuggestionStock || 0,
          };
        });
      });

      await updateOrderSuggestion71819InFirestore(suggestionOrderId, {
        productionLineId,
        status: "COMPLETED",
        stocks: values.stocks,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(
    () => form.resetFields,
    [orderSuggestion, productionLineId, suggestionOrderId]
  );
  const HeaderPage = (
    <PageToolbar>
      <div className="input-form-description text-center">
        Create{" "}
        {productionTypes[orderSuggestion?.productionType || ""]?.label || ""}{" "}
        Locations Orders
      </div>
      <div className="page-toolbar-right-children-elements">
        <Button
          type="primary"
          className="input-form-btn"
          onClick={handleSubmit}
          loading={loading}
          icon={<CloudUploadOutlined />}
        >
          Create{" "}
          {productionTypes[orderSuggestion?.productionType || ""]?.label || ""}{" "}
          Locations Orders
        </Button>
      </div>
    </PageToolbar>
  );
  return (
    <AdminPageBody pageToolbar={HeaderPage}>
      <InputFormWrapper>
        <div className="input-form-body-full">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={orderSuggestion}
          >
            <Form.List name="stocks">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Tabs
                    destroyInactiveTabPane
                    defaultActiveKey={get(productionLine, "categories[0]", "0")}
                    type="card"
                  >
                    {productionLine?.categories?.map((category) => (
                      <Tabs.TabPane
                        tab={find(category59983, ["id", category])?.name || ""}
                        key={category}
                      >
                        <StockCategory
                          categoryId={category}
                          userRole={myProfile?.role || ""}
                          isRaw={orderSuggestion?.productionType === "RAW"}
                          locationId={productionLineId}
                          fixedView
                          isFleet
                          isVertical
                          locations={[
                            ...(location1688Locations?.map(
                              (location: ILocation1688) => ({
                                id: location.id,
                                name: location.name,
                                editable: true,
                              })
                            ) || []),
                            {
                              id: productionLineId,
                              name: "Current Stock",
                              isFixed: true,
                            },
                          ]}
                          viewsSetting={{
                            stockCreated: { show: false, disabled: false },
                            requestedStock: { show: false, disabled: false },
                            stockAvailable: { show: true, disabled: true },
                          }}
                        />
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </>
              )}
            </Form.List>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
