/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { OrderListMole } from "../../molecules";
import { LocationWrapper } from "./Location.style";
import { OrderListsListToolbar } from "./LocationOrderListToolbar";

export const LocationOrders: React.FC = () => {
  const { order50453 } = useSelector((state: any) => state.order50453);
  const history = useNavigate();
  const params = useParams();
  const locationId = params.locationId || "";
  const handelEditOrder = (id: string, productionLineId: string) => () => {
    history(
      `/shop-location/${locationId}/production-line/${productionLineId}/order/${id}`
    );
  };
  return (
    <AdminPageBody pageToolbar={<OrderListsListToolbar />}>
      <LocationWrapper>
        <OrderListMole
          data={order50453}
          showPagination
          showLocation={false}
          showProduction
          showDeleteBtn={false}
          editOrder={handelEditOrder}
        />
      </LocationWrapper>
    </AdminPageBody>
  );
};
