import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const returnTypes: ISelectOptionMap = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
    color: "blue",
  },
  ACCEPTED: {
    label: "Accepted",
    value: "ACCEPTED",
    color: "green",
  },
};

export const returnTypeOptions = values(returnTypes);
