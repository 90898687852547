import { IProductionLine45460 } from "../../../common/types";
import {
  CREATE_PRODUCTIONLINE45460,
  UPDATE_PRODUCTIONLINE45460,
  DELETE_PRODUCTIONLINE45460,
  FETCH_PRODUCTIONLINE45460,
  LISTEN_TO_SELECTED_PRODUCTIONLINE45460,
} from "./productionLine45460Constants";

const initialState = {
  productionLine45460: [],
  selectedProductionLine45460: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function productionLine45460Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_PRODUCTIONLINE45460:
      return {
        ...state,
        productionLine45460: [...state.productionLine45460, payload],
      };
    case UPDATE_PRODUCTIONLINE45460:
      return {
        ...state,
        productionLine45460: [
          ...state.productionLine45460.filter(
            (evt: IProductionLine45460) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_PRODUCTIONLINE45460:
      return {
        ...state,
        productionLine45460: [
          ...state.productionLine45460.filter(
            (evt: IProductionLine45460) => evt.id !== payload
          ),
        ],
      };
    case FETCH_PRODUCTIONLINE45460:
      return {
        ...state,
        productionLine45460: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_PRODUCTIONLINE45460:
      return {
        ...state,
        selectedproductionLine45460: payload,
      };

    default:
      return state;
  }
}
