import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const orderTypes: ISelectOptionMap = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
    color: "blue",
  },
  SHIPPED: {
    label: "Shipped",
    value: "SHIPPED",
    color: "orange",
  },
  ACCEPTED: {
    label: "Accepted",
    value: "ACCEPTED",
    color: "green",
  },
  ACCEPTED_WITH_ISSUES: {
    label: "Accepted with issues",
    value: "ACCEPTED_WITH_ISSUES",
    color: "red",
  },
};

export const orderTypeOptions = values(orderTypes);
