/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { ICategory59983 } from "../../../common/types";
import {
  UPDATE_CATEGORY59983,
  DELETE_CATEGORY59983,
  FETCH_CATEGORY59983,
  LISTEN_TO_SELECTED_CATEGORY59983,
  FILTER_CATEGORY59983,
} from "./category59983Constants";

export function listenToCategory59983(category59983: ICategory59983[]) {
  return {
    type: FETCH_CATEGORY59983,
    payload: category59983,
  };
}

export function listenToSelectedCategory59983(category59983: ICategory59983) {
  return {
    type: LISTEN_TO_SELECTED_CATEGORY59983,
    payload: category59983,
  };
}

export function updateCategory59983(category59983: ICategory59983) {
  return {
    type: UPDATE_CATEGORY59983,
    payload: category59983,
  };
}

export function deleteCategory59983(category59983Id: string) {
  return {
    type: DELETE_CATEGORY59983,
    payload: category59983Id,
  };
}

export function listenToCategory59983Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_CATEGORY59983,
    payload: filters,
  };
}
