import { IProduction67969 } from "../../../common/types";
import {
  CREATE_PRODUCTION67969,
  UPDATE_PRODUCTION67969,
  DELETE_PRODUCTION67969,
  FETCH_PRODUCTION67969,
  LISTEN_TO_SELECTED_PRODUCTION67969,
  FILTER_PRODUCTION67969,
} from "./production67969Constants";

const initialState = {
  production67969: [],
  selectedProduction67969: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterProduction67969: {},
};

export default function production67969Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_PRODUCTION67969:
      return {
        ...state,
        production67969: [...state.production67969, payload],
      };
    case UPDATE_PRODUCTION67969:
      return {
        ...state,
        production67969: [
          ...state.production67969.filter(
            (evt: IProduction67969) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_PRODUCTION67969:
      return {
        ...state,
        production67969: [
          ...state.production67969.filter(
            (evt: IProduction67969) => evt.id !== payload
          ),
        ],
      };
    case FETCH_PRODUCTION67969:
      return {
        ...state,
        production67969: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_PRODUCTION67969:
      return {
        ...state,
        selectedProduction67969: payload,
      };
    case FILTER_PRODUCTION67969:
      return {
        ...state,
        filterProduction67969: payload || {},
      };
    default:
      return state;
  }
}
