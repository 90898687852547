/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IUnit23243 } from "../../../common/types";
import { deleteUnit23243InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { ReportsWrapper } from "./Reports.style";
import { CustomerReportsListToolbar } from "./CustomerReportsListToolbar";

export const CustomerReports: React.FC = () => {
  const { unit23243 } = useSelector((state: any) => state.unit23243);
  const { loading } = useSelector((state: any) => state.async);
  const history = useNavigate();
  const handelDeleteUnit23243 = async (id: string) => {
    await deleteUnit23243InFirestore(id);
  };
  const handelEditCustomerReports = (id: string) => () => {
    history(`/reports/customerreports/${id}`);
  };
  const columnsList = [
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IUnit23243) => (
        <ActionCell
          onDelete={() => handelDeleteUnit23243(record.id)}
          onEdit={handelEditCustomerReports(record.id)}
        />
      ),
    },
  ];
  return (
    <AdminPageBody pageToolbar={<CustomerReportsListToolbar />}>
      <ReportsWrapper>
        <Table
          columns={columnsList}
          dataSource={unit23243}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
        />
      </ReportsWrapper>
    </AdminPageBody>
  );
};
