/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchUnit23243FromFirestore } from "../../../firestore/firestoreService";
import { listenToUnit23243 } from "../../../redux/data/unit23243/unit23243Actions";

export const CustomerReportsListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchUnit23243FromFirestore([]),
    data: listenToUnit23243,
    deps: [dispatch],
  });
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddCustomerReportsModal",
        modalProps: { title: "Add CustomerReports Form" },
      })
    );
  };

  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add CustomerReports
      </Button>

      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
};
