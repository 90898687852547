import { orderBy } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { ILocation1688 } from "../../../common/types";
import { Form, Select } from "../../atoms";
import { UserSelectedFieldWrapper } from "./Fields.style";

interface ILocationSelectedFieldProps {
  bordered?: boolean;
  name: string | Array<string | number>;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getLocation?: () => ILocation1688;
  onChange?: (value: any) => void;
}
export const LocationSelectedField: React.FC<ILocationSelectedFieldProps> = ({
  name,
  label,
  mode,
  rules,
  getLocation,
  onChange,
  bordered = true,
  style,
  placeholder,
  maxTagCount,
  initialValue,
}) => {
  const { location1688 } = useSelector((state: any) => state.location1688);

  return (
    <UserSelectedFieldWrapper>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
      >
        <Select
          showSearch
          bordered={bordered}
          style={style}
          placeholder={placeholder || label}
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={orderBy(location1688, ["name"], ["asc"]).map(
            (location: ILocation1688) => ({
              value: location.id,
              label: location.name,
              search: `${location?.name || ""}`,
            })
          )}
          mode={mode}
          maxTagCount={maxTagCount}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </UserSelectedFieldWrapper>
  );
};

export default LocationSelectedField;
