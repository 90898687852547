import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { find } from "lodash";
import { Collapse, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { OrderHeader, ReturnCategory } from "../../molecules";
import {
  listenToLocation1688FromFirestore,
  listenToReturn5216FromFirestore,
} from "../../../firestore/firestoreService";
import { ILocation1688 } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedReturn5216 } from "../../../redux/data/return5216/return5216Actions";

export const AdminReturnDetails: React.FC = () => {
  const [form] = Form.useForm();
  const { category59983 } = useSelector((state: any) => state.category59983);
  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const params = useParams();
  const returnId = params.returnId || "";
  const productionLineId = params.productionLineId || "";
  const { selectedReturn5216 } = useSelector((state: any) => state.return5216);
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToReturn5216FromFirestore(returnId),
    data: listenToSelectedReturn5216,
    deps: [dispatch, returnId],
    shouldExecute: !!returnId,
  });
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });

  React.useEffect(() => form.resetFields, [selectedReturn5216, returnId]);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedReturn5216}
          >
            {selectedReturn5216 && (
              <OrderHeader
                order={selectedReturn5216}
                productionLine={productionLine}
              />
            )}
            <Form.List name="stocks">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Collapse
                    defaultActiveKey={[...(productionLine?.categories || [])]}
                  >
                    {productionLine?.categories?.map((category) => (
                      <Collapse.Panel
                        header={
                          find(category59983, ["id", category])?.name || ""
                        }
                        key={category}
                      >
                        <ReturnCategory
                          categoryId={category}
                          viewsSetting={{
                            returnStock: { show: true, disabled: true },
                            wasteStock: { show: true, disabled: true },
                            backToShop: { show: true, disabled: true },
                            reproduction: { show: true, disabled: true },
                            charityStock: { show: true, disabled: true },
                            comment: { show: true, disabled: true },
                          }}
                        />
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </>
              )}
            </Form.List>

            <Divider></Divider>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
