import { Tag as AntTag, TagProps } from "antd";
import styled from "styled-components";

export const Tag: typeof AntTag = styled(AntTag)<TagProps>`
  font-size: 14px;
  padding: 2px 10px;
  min-width: 50px;
  margin-bottom: 5px;
  /* background-color: #f5b1a9;
  border-color: #f5b1a9; */
  text-align: center;
`;
