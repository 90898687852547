import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dataFromSnapshot } from "../firestore/firestoreService";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../redux/data/async/asyncReducer";

// interface IUserFirestoreDoc {
//   query?: string;
//   docId: string;
//   data?: (data: any) => void;
//   deps?: any[];
// }
export const userFirestoreDoc: any = ({
  query,
  data,
  deps,
  shouldExecute = true,
  local,
}: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!shouldExecute) return;
    if (!query()) return;
    !local && dispatch(asyncActionStart());
    const unsubscribe = query().onSnapshot(
      (snapshot: any) => {
        local
          ? data(dataFromSnapshot(snapshot))
          : dispatch(data(dataFromSnapshot(snapshot)));
        !local && dispatch(asyncActionFinish());
      },
      (error: any) => dispatch(asyncActionError(error))
    );
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }, deps);
};
