import { combineReducers } from "redux";
import asyncReducer from "../data/async/asyncReducer";
import modalReducer from "../data/modals/modalReducer";
import drawerReducer from "../data/drawer/drawerReducer";
import authReducer from "../data/auth/authReducer";
import myProfileReducer from "../data/myProfile/myProfileReducer";
// appendImportHere
import orderSuggestion71819Reducer from "../data/orderSuggestion71819/orderSuggestion71819Reducer";
import stockHistory36376Reducer from "../data/stockHistory36376/stockHistory36376Reducer";
import goodsReceived26115Reducer from "../data/goodsReceived26115/goodsReceived26115Reducer";
import productionLine45460Reducer from "../data/productionLine45460/productionLine45460Reducer";
import unit23243Reducer from "../data/unit23243/unit23243Reducer";
import category59983Reducer from "../data/category59983/category59983Reducer";
import vendor83788Reducer from "../data/vendor83788/vendor83788Reducer";
import production67969Reducer from "../data/production67969/production67969Reducer";
import user94013Reducer from "../data/user94013/user94013Reducer";
import order50453Reducer from "../data/order50453/order50453Reducer";
import ingredient56227Reducer from "../data/ingredient56227/ingredient56227Reducer";
import return5216Reducer from "../data/return5216/return5216Reducer";
import recipe75190Reducer from "../data/recipe75190/recipe75190Reducer";
import returnStock78285Reducer from "../data/returnStock78285/returnStock78285Reducer";
import stock16371Reducer from "../data/stock16371/stock16371Reducer";
import orderStocks35474Reducer from "../data/orderStocks35474/orderStocks35474Reducer";
import product2143Reducer from "../data/product2143/product2143Reducer";
import location1688Reducer from "../data/location1688/location1688Reducer";
import userReducer from "../data/user/userReducer";
const rootReducer = (history: any) =>
  combineReducers({
    async: asyncReducer,
    modals: modalReducer,
    drawer: drawerReducer,
    auth: authReducer,
    myProfile: myProfileReducer,
    users: userReducer,
    // appendReducerHere
    orderSuggestion71819: orderSuggestion71819Reducer,
    stockHistory36376: stockHistory36376Reducer,
    goodsReceived26115: goodsReceived26115Reducer,
    productionLine45460: productionLine45460Reducer,
    unit23243: unit23243Reducer,
    category59983: category59983Reducer,
    vendor83788: vendor83788Reducer,
    production67969: production67969Reducer,
    user94013: user94013Reducer,
    order50453: order50453Reducer,
    ingredient56227: ingredient56227Reducer,
    return5216: return5216Reducer,
    recipe75190: recipe75190Reducer,
    returnStock78285: returnStock78285Reducer,
    stock16371: stock16371Reducer,
    orderStocks35474: orderStocks35474Reducer,
    product2143: product2143Reducer,
    location1688: location1688Reducer,

    // router: connectRouter(history),
  });

export default rootReducer;
