import { InputNumber } from "antd";
import React from "react";
import { Form } from "../../atoms";

interface IInputNumberFieldProps {
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  placeholder?: string;
  style?: any;
  initialValue?: any;
  className?: string;
  bordered?: boolean;
  min?: any;
  max?: any;
  formatter?: any;
  parser?: any;
  disabled?: boolean;
  controls?: boolean;
  hidden?: boolean;
  onChange?: (value: any) => void;
  validateStatus?: "" | "warning" | "error";
  validateTrigger?: string;
  help?: string;
}

export const InputNumberField: React.FC<IInputNumberFieldProps> = ({
  label,
  rules,
  name,
  placeholder,
  style,
  className,
  initialValue,
  bordered = true,
  min,
  formatter,
  parser,
  disabled,
  max,
  controls,
  hidden,
  help,
  onChange,
  validateStatus,
  validateTrigger,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    className={className}
    initialValue={initialValue}
    style={style}
    hidden={hidden}
    validateStatus={validateStatus}
    validateTrigger={validateTrigger}
    validateFirst
    help={help}
  >
    <InputNumber
      placeholder={placeholder}
      bordered={bordered}
      style={style}
      min={min}
      max={max}
      formatter={formatter}
      parser={parser}
      disabled={disabled}
      keyboard
      pattern="[0-9]*"
      controls={controls}
      onChange={onChange}
    />
  </Form.Item>
);
