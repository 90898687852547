/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { find } from "lodash";
import moment from "moment";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IOrder50453 } from "../../../common/types";
import { Table } from "../../atoms";
import { orderTypes } from "../../../enums";
import { deleteOrderSuggestion71819InFirestore } from "../../../firestore/firestoreService";
import { ProductionLineWrapper } from "./ProductionLine.style";
import { ProductionLineSuggestionListToolbar } from "./ProductionLineSuggestionListToolbar";

export const ProductionLineSuggestionOrders: React.FC = () => {
  const { orderSuggestion71819 } = useSelector(
    (state: any) => state.orderSuggestion71819
  );
  const { user94013 } = useSelector((state: any) => state.user94013);
  const { loading } = useSelector((state: any) => state.async);
  const history = useNavigate();
  const params = useParams();
  const productionLineId = params.productionLineId || "";
  const handelDeleteOrderSuggestion71819 = async (id: string) => {
    await deleteOrderSuggestion71819InFirestore(id);
  };
  const handelEditOrderLists = (id: string) => () => {
    history(`/production-location/${productionLineId}/suggestion-order/${id}`);
  };
  const columnsList = [
    {
      title: "Date",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "Time",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("HH:mm"),
    },
    {
      title: "Submitted by",
      dataIndex: "createdById",
      key: "createdById",
      render: (v: string) => find(user94013, ["id", v])?.name || "",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: string) => orderTypes[v]?.label || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IOrder50453) => (
        <ActionCell
          onEdit={handelEditOrderLists(record.id)}
          onDelete={() => handelDeleteOrderSuggestion71819(record.id)}
        />
      ),
    },
  ];
  return (
    <AdminPageBody pageToolbar={<ProductionLineSuggestionListToolbar />}>
      <ProductionLineWrapper>
        <Table
          columns={columnsList}
          dataSource={orderSuggestion71819}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
        />
      </ProductionLineWrapper>
    </AdminPageBody>
  );
};
