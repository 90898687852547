/* eslint-disable @typescript-eslint/no-unsafe-argument */

import {
  DoubleLeftOutlined,
  HomeOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { MenuProps } from "antd";
import { get } from "lodash";
import { IMenuOption } from "../../common/types";
import { Breadcrumb, Button, Logo, Menu } from "../../components/atoms";
import { Header } from "../../components/organisms";
import { permissionTypes } from "../../enums";
import { AdminPageWrapper } from "./AdminPage.style";

interface IAdminPageProps {
  children: React.ReactNode;
  title: string | React.ReactElement;
  route?: any[];
  pageToolbar?: React.ReactNode;
  headerMenu?: IMenuOption[];
  breadcrumb?: IMenuOption[];
}

const getItems = (id: string) => ({
  [permissionTypes.ADMIN.value]: [
    { label: <Link to="/">Home</Link>, key: "home", icon: <HomeOutlined /> },
    // appendDomainHere
    { label: <Link to="/orders">Orders</Link>, key: "orders" },
    { label: <Link to="/returns">Returns</Link>, key: "returns" },
    {
      label: "",
      key: "divider",
      type: "divider",
    },
    { label: <Link to="/locations">Locations</Link>, key: "locations" },
    {
      label: <Link to="/productionLines">Production Lines</Link>,
      key: "productionLines",
    },
    { label: <Link to="/inventory/vendors">Inventory</Link>, key: "inventory" },
    { label: <Link to="/users">Users</Link>, key: "users" },
  ],
  [permissionTypes.PRODUCTION_MANAGER.value]: [
    { label: <Link to="/">Home</Link>, key: "home", icon: <HomeOutlined /> },
    {
      label: <Link to={`/production-location/${id}/orders`}>Orders</Link>,
      key: "orders",
    },
    {
      label: <Link to={`/production-location/${id}/returns`}>Returns</Link>,
      key: "returns",
    },
    {
      label: <Link to={`/production-location/${id}/inventory`}>Inventory</Link>,
      key: "inventory",
    },
    {
      label: (
        <Link to={`/production-location/${id}/productions`}>Productions</Link>
      ),
      key: "productions",
    },
    {
      label: (
        <Link to={`/production-location/${id}/baked-stocks`}>Baked stocks</Link>
      ),
      key: "baked-stocks",
    },
    {
      label: (
        <Link to={`/production-location/${id}/raw-stocks`}>Raw Stocks</Link>
      ),
      key: "raw-stocks",
    },
  ],
  [permissionTypes.PRODUCTION_BAKER.value]: [
    { label: <Link to="/">Home</Link>, key: "home", icon: <HomeOutlined /> },
    {
      label: (
        <Link to={`/production-baker/${id}/productions`}>Productions</Link>
      ),
      key: "productions",
    },
  ],
  [permissionTypes.LOCATION_MANAGER.value]: [
    { label: <Link to="/">Home</Link>, key: "home", icon: <HomeOutlined /> },
    {
      label: <Link to={`/shop-location/${id}/orders`}>Orders</Link>,
      key: "orders",
    },
    {
      label: <Link to={`/shop-location/${id}/returns`}>Returns</Link>,
      key: "returns",
    },
    {
      label: (
        <Link to={`/shop-location/${id}/update-stock-history`}>
          Stock history
        </Link>
      ),
      key: "stockHistory",
    },
  ],
  [permissionTypes.LOCATION_BAKER.value]: [
    { label: <Link to="/">Home</Link>, key: "home", icon: <HomeOutlined /> },
    {
      label: <Link to={`/shop-location/${id}/orders`}>Orders</Link>,
      key: "orders",
    },
    {
      label: <Link to={`/shop-location/${id}/returns`}>Returns</Link>,
      key: "returns",
    },
    {
      label: (
        <Link to={`/shop-location/${id}/update-stock-history`}>
          Stock history
        </Link>
      ),
      key: "stockHistory",
    },
  ],
  [permissionTypes.FLEET_MANAGER.value]: [
    { label: <Link to="/">Home</Link>, key: "home", icon: <HomeOutlined /> },
    {
      label: <Link to={`/fleet/productions`}>Productions</Link>,
      key: "productions",
    },
  ],
});
export const AdminPage: React.FC<IAdminPageProps> = ({
  children,
  route,
  title,
  pageToolbar,
  breadcrumb,
  headerMenu,
}) => {
  const history = useNavigate();
  // const dispatch = useDispatch();
  const location = useLocation();
  const { myProfile } = useSelector((state: any) => state.myProfile);
  // const { loading } = useSelector((state: any) => state.async);
  const [selectedKey, setSelectedKey] = useState("");
  const [hideLeftMenu, setHideRightMenu] = useState(false);
  const handelOnChange: MenuProps["onClick"] = ({ key }) => {
    setSelectedKey(key);
  };
  const handleHideMenu = () => {
    setHideRightMenu((v) => !v);
  };
  const handleGoBack = () => {
    history(-1);
  };
  React.useEffect(() => {
    if (location) {
      let path = "home";
      if (location.pathname.includes("orders")) {
        path = "orders";
      }
      if (location.pathname.includes("suggestion-orders")) {
        path = "suggestion-orders";
      }
      if (location.pathname.includes("returns")) {
        path = "returns";
      }
      if (location.pathname.includes("locations")) {
        path = "locations";
      }
      if (location.pathname.includes("inventory")) {
        path = "inventory";
      }
      if (location.pathname.includes("users")) {
        path = "users";
      }
      if (location.pathname.includes("productions")) {
        path = "productions";
      }
      if (location.pathname.includes("baked-stocks")) {
        path = "baked-stocks";
      }
      if (location.pathname.includes("raw-stocks")) {
        path = "raw-stocks";
      }
      if (location.pathname.includes("update-stock-history")) {
        path = "stockHistory";
      }
      if (path !== selectedKey) {
        setSelectedKey(path);
      }
    }
  }, [location]);

  React.useEffect(() => {
    if (myProfile?.role === permissionTypes.LOCATION_MANAGER.value) {
      setHideRightMenu(true);
    }
  }, [myProfile?.role]);

  return (
    <AdminPageWrapper>
      <div className="admin-page-container">
        <div
          className={classNames("admin-page-menu", {
            "admin-page-menu-hidden": hideLeftMenu,
          })}
        >
          <div className="admin-page-header">
            <Logo className="admin-page-logo" />
            <MenuFoldOutlined
              className="admin-page-side-burger-icon"
              onClick={handleHideMenu}
            />
          </div>

          <Menu
            items={get(getItems(myProfile?.locationId), myProfile?.role, [])}
            mode="inline"
            className="admin-page-menu-menu"
            selectedKeys={[selectedKey]}
            onClick={handelOnChange}
          />
        </div>
        <div className="admin-page">
          <Header
            className="site-page-header"
            title={title}
            headerMenu={headerMenu}
            hideLeftMenu={hideLeftMenu}
            hideMenuOnClick={handleHideMenu}
            // breadcrumb={{ routes: [...defaultRoutes] }}
          />
          {breadcrumb && (
            <div className="admin-page-breadcrumb">
              <div className="admin-page-breadcrumb-back">
                <Button
                  type="link"
                  className="admin-page-breadcrumb-back-btn"
                  onClick={handleGoBack}
                >
                  <DoubleLeftOutlined /> Back
                </Button>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                {breadcrumb?.map((item) => (
                  <Breadcrumb.Item key={item.key}>
                    <Link to={item.link}>{item.label}</Link>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>
          )}
          {children}
        </div>
      </div>
    </AdminPageWrapper>
  );
};
