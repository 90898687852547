import { ICategory59983 } from "../../../common/types";
import {
  CREATE_CATEGORY59983,
  UPDATE_CATEGORY59983,
  DELETE_CATEGORY59983,
  FETCH_CATEGORY59983,
  LISTEN_TO_SELECTED_CATEGORY59983,
  FILTER_CATEGORY59983,
} from "./category59983Constants";

const initialState = {
  category59983: [],
  selectedCategory59983: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterCategory59983: {},
};

export default function category59983Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_CATEGORY59983:
      return {
        ...state,
        category59983: [...state.category59983, payload],
      };
    case UPDATE_CATEGORY59983:
      return {
        ...state,
        category59983: [
          ...state.category59983.filter(
            (evt: ICategory59983) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_CATEGORY59983:
      return {
        ...state,
        category59983: [
          ...state.category59983.filter(
            (evt: ICategory59983) => evt.id !== payload
          ),
        ],
      };
    case FETCH_CATEGORY59983:
      return {
        ...state,
        category59983: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_CATEGORY59983:
      return {
        ...state,
        selectedCategory59983: payload,
      };
    case FILTER_CATEGORY59983:
      return {
        ...state,
        filterCategory59983: payload || {},
      };
    default:
      return state;
  }
}
