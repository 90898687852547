/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { IUnit23243 } from "../../../common/types";
import {
  UPDATE_UNIT23243,
  DELETE_UNIT23243,
  FETCH_UNIT23243,
  LISTEN_TO_SELECTED_UNIT23243,
  FILTER_UNIT23243,
} from "./unit23243Constants";

export function listenToUnit23243(unit23243: IUnit23243[]) {
  return {
    type: FETCH_UNIT23243,
    payload: unit23243,
  };
}

export function listenToSelectedUnit23243(unit23243: IUnit23243) {
  return {
    type: LISTEN_TO_SELECTED_UNIT23243,
    payload: unit23243,
  };
}

export function updateUnit23243(unit23243: IUnit23243) {
  return {
    type: UPDATE_UNIT23243,
    payload: unit23243,
  };
}

export function deleteUnit23243(unit23243Id: string) {
  return {
    type: DELETE_UNIT23243,
    payload: unit23243Id,
  };
}

export function listenToUnit23243Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_UNIT23243,
    payload: filters,
  };
}
