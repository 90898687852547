/* eslint-disable no-console */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { find, get } from "lodash";
import moment from "moment";
import { Alert, Form, InputFormWrapper, Tabs } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ItemNotFound, Loader, StockCategory } from "../../molecules";
import {
  fetchLocation1688FromFirestore,
  listenToLocation1688FromFirestore,
} from "../../../firestore/firestoreService";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { ILocation1688 } from "../../../common/types";
import { listenToLocation1688Locations } from "../../../redux/data/location1688/location1688Actions";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";

interface IProps {
  productionType: string;
}
export const Stock: React.FC<IProps> = ({ productionType }) => {
  const [form] = Form.useForm();
  const { location1688Locations } = useSelector(
    (state: any) => state.location1688
  );
  const { category59983 } = useSelector((state: any) => state.category59983);
  const { loading: dataLoading, error: dataError } = useSelector(
    (state: any) => state.async
  );
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const [error] = React.useState("");
  const params = useParams();
  const productionLineId = params.productionLineId || "";
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () =>
      fetchLocation1688FromFirestore([
        {
          type: "filter",
          name: "type",
          opr: "==",
          value: "LOCATION",
        },
      ]),
    data: listenToLocation1688Locations,
    deps: [dispatch, productionType],
  });

  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId, productionType],
    shouldExecute: !!productionLineId,
  });

  React.useEffect(() => form.resetFields, [location, productionType]);
  if (dataLoading) {
    return <Loader color="success" title="Loading" />;
  }
  if (dataError) {
    return (
      <ItemNotFound
        redirectTo="/"
        redirectBtnText="Back to List"
        itemType="Production"
      />
    );
  }
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body-full">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={{}}
          >
            <Tabs
              defaultActiveKey={get(productionLine, "categories[0]", "0")}
              type="card"
              items={productionLine?.categories?.map((category) => ({
                label: find(category59983, ["id", category])?.name || "",
                key: category,
                children: (
                  <>
                    <StockCategory
                      categoryId={category}
                      userRole={myProfile?.role || ""}
                      isRaw={productionType === "RAW"}
                      locationId={productionLineId}
                      isVertical
                      fixedView
                      locations={[
                        ...(location1688Locations?.map(
                          (location: ILocation1688) => ({
                            id: location.id,
                            name: location.name,
                            editable: false,
                            lastUpdate: moment(location.lastStockUpdate).format(
                              "HH:mm DD/MM "
                            ),
                          })
                        ) || []),
                        {
                          id: productionLineId,
                          name: "Current Stock",
                          isFixed: true,
                        },
                      ]}
                      viewsSetting={{
                        stockCreated: { show: false, disabled: false },
                        requestedStock: { show: false, disabled: false },
                      }}
                    />
                  </>
                ),
              }))}
            />
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
