import { orderBy } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { IVendor83788 } from "../../../common/types";
import { Form, Select } from "../../atoms";
import { UserSelectedFieldWrapper } from "./Fields.style";

interface IVendorSelectedFieldProps {
  bordered?: boolean;
  name: string | Array<string | number>;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getVendor?: () => IVendor83788;
  onChange?: (value: any) => void;
}
export const VendorSelectedField: React.FC<IVendorSelectedFieldProps> = ({
  name,
  label,
  mode,
  rules,
  getVendor,
  onChange,
  bordered = true,
  style,
  placeholder,
  maxTagCount,
  initialValue,
}) => {
  const { vendor83788 } = useSelector((state: any) => state.vendor83788);

  return (
    <UserSelectedFieldWrapper>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
      >
        <Select
          showSearch
          bordered={bordered}
          style={style}
          placeholder={placeholder || label}
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={orderBy(vendor83788, ["name"], ["asc"]).map(
            (vendor: IVendor83788) => ({
              value: vendor.id,
              label: vendor.name,
              search: `${vendor?.name || ""}`,
            })
          )}
          mode={mode}
          maxTagCount={maxTagCount}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </UserSelectedFieldWrapper>
  );
};

export default VendorSelectedField;
