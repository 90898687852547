import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAKBuIzOue-O8k4CAj_pfiU14vO1VfvO_U",
  authDomain: "sigma-bakeries.firebaseapp.com",
  projectId: "sigma-bakeries",
  storageBucket: "sigma-bakeries.appspot.com",
  messagingSenderId: "593728483704",
  appId: "1:593728483704:web:728e452ec4ced9335e588d",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
export default firebase;
