import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const productionTypes: ISelectOptionMap = {
  RAW: {
    label: "Raw",
    value: "RAW",
    color: "blue",
  },
  BAKED: {
    label: "Baked",
    value: "BAKED",
    color: "green",
  },
};

export const productionTypeOptions = values(productionTypes);
