export const COLLECTION_USER = "COLLECTION_USER";
// appendHere
export const COLLECTION_ORDERSUGGESTION71819 =
  "COLLECTION_ORDERSUGGESTION71819";
export const COLLECTION_STOCKHISTORY36376 = "COLLECTION_STOCKHISTORY36376";
export const COLLECTION_GOODSRECEIVED26115 = "COLLECTION_GOODSRECEIVED26115";
export const COLLECTION_UNIT23243 = "COLLECTION_UNIT23243";
export const COLLECTION_CATEGORY59983 = "COLLECTION_CATEGORY59983";
export const COLLECTION_VENDOR83788 = "COLLECTION_VENDOR83788";
export const COLLECTION_PRODUCTION67969 = "COLLECTION_PRODUCTION67969";
export const COLLECTION_USER94013 = "COLLECTION_USER94013";
export const COLLECTION_ORDER50453 = "COLLECTION_ORDER50453";
export const COLLECTION_INGREDIENT56227 = "COLLECTION_INGREDIENT56227";
export const COLLECTION_RETURN5216 = "COLLECTION_RETURN5216";
export const COLLECTION_RECIPE75190 = "COLLECTION_RECIPE75190";
export const COLLECTION_RETURNSTOCK78285 = "COLLECTION_RETURNSTOCK78285";
export const COLLECTION_STOCK16371 = "COLLECTION_STOCK16371";
export const COLLECTION_ORDERSTOCKS35474 = "COLLECTION_ORDERSTOCKS35474";
export const COLLECTION_PRODUCT2143 = "COLLECTION_PRODUCT2143";
export const COLLECTION_LOCATION1688 = "COLLECTION_LOCATION1688";
export const COLLECTION_PRODUCTIONLINE45460 = "COLLECTION_PRODUCTIONLINE45460";
