/* eslint-disable no-console */
import { findIndex, forEach, get, orderBy, values } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { Switch } from "antd";
import { IProduct2143, IQueryConfigArgs } from "../../../common/types";
import { Table, Form, Input } from "../../atoms";
import { InputNumberField } from "../commonFields";
import { Tag } from "../../atoms/Tag/Tag";
import { fetchProduct2143FromFirestore } from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";

interface IViewSetting {
  show: boolean;
  disabled: boolean;
  title?: string;
}
interface IViewSettingMap {
  [key: string]: IViewSetting;
}
interface ILocation {
  name: string;
  id: string;
  isFixed?: boolean;
  editable?: boolean;
  lastUpdate: string;
}
interface IProps {
  categoryId: string;
  userRole?: string;
  viewsSetting: IViewSettingMap;
  isRaw?: boolean;
  locations?: ILocation[];
  locationId: string;
  fixedView?: boolean;
  isVertical?: boolean;
  isFleet?: boolean;
  stocks?: any;
}
const filter = (categoryId: string, isRaw?: boolean): IQueryConfigArgs[] => {
  if (isRaw) {
    return [
      {
        type: "filter",
        name: "categoryId",
        opr: "==",
        value: categoryId,
      },
      {
        type: "filter",
        name: "isRaw",
        opr: "==",
        value: true,
      },
    ];
  }
  return [
    {
      type: "filter",
      name: "categoryId",
      opr: "==",
      value: categoryId,
    },
  ];
};

export const StockCategory: React.FC<IProps> = ({
  categoryId,
  userRole,
  viewsSetting,
  isRaw,
  locations,
  locationId,
  fixedView,
  isVertical,
  isFleet,
  stocks,
}) => {
  const [products, setProducts] = React.useState<IProduct2143[]>([]);
  const productsRef = React.useRef<IProduct2143[]>([]);
  const form = Form.useFormInstance();
  const dispatch = useDispatch();

  useFirestoreCollection({
    query: () => fetchProduct2143FromFirestore(filter(categoryId, isRaw)),
    data: setProducts,
    local: true,
    deps: [dispatch, categoryId],
  });

  const handleStockCount =
    (id: string, locId: string) => async (value: number) => {
      // const totalStock = stocksValue[id][locationId];
      // console.log(totalStock);
      const productIndex = findIndex(productsRef.current, ["id", id]);

      if (productIndex !== -1) {
        if (productsRef.current[productIndex].stocks[locId]) {
          if (value === null && isFleet) {
            const locationSuggestionStock =
              productsRef.current[productIndex].stocks[locId]
                .locationSuggestionStock || 0;
            productsRef.current[productIndex].stocks[locId].locationSendStock =
              locationSuggestionStock;
            form.setFieldValue(
              ["stocks", categoryId, id, "stocks", locId, "locationSendStock"],
              +locationSuggestionStock
            );
          } else {
            productsRef.current[productIndex].stocks[locId][
              isFleet ? "locationSendStock" : "locationSuggestionStock"
            ] = value;
          }
        } else {
          productsRef.current[productIndex].stocks[locId] = {
            locationSuggestionStock: +value,
            locationSendStock: +value,
            locationId: locId,
            stock: 0,
            rawStock: 0,
          };
        }
        const totalAmount =
          values(productsRef.current[productIndex].stocks)?.reduce(
            (v, b) =>
              v +
              +(
                b[isFleet ? "locationSendStock" : "locationSuggestionStock"] ||
                0
              ),
            0
          ) || 0;
        form.setFieldValue(
          ["stocks", categoryId, id, "requestedStock"],
          +totalAmount
        );
        await form.validateFields([
          ["stocks", categoryId, id, "requestedStock"],
          ["stocks", categoryId, id, "stocks", locId, "locationSendStock"],
        ]);
      }
    };
  const columnsList: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: fixedView && "left",
      width: 200,
      render: (v: string) => v || "",
    },
    ...(locations?.map((location: ILocation) => ({
      title: (
        <>
          {location.name}{" "}
          <span className="stockLastUpdate">{location.lastUpdate}</span>
        </>
      ),
      dataIndex: location.id,
      key: location.id,
      width: 70,
      fixed: location.isFixed && "right",
      render: (v: string, record: IProduct2143) => {
        const stock = isRaw
          ? get(record, `stocks[${location.id}].rawStock`, 0)
          : get(record, `stocks[${location.id}].stock`, 0);
        let locationSuggestionStock = 0;
        if (isFleet) {
          locationSuggestionStock = get(
            stocks,
            `[${categoryId}][${record.id}].stocks[${location.id}].locationSuggestionStock`,
            0
          );
        }
        if (location.id === locationId) {
          form.setFieldValue([record.id, "currentStock"], +stock);
          return (
            <InputNumberField
              label=""
              name={[record.id, "currentStock"]}
              min="0"
              max={999}
              initialValue={+stock}
              placeholder="0"
              style={{ width: 50, marginBottom: 0 }}
              controls={false}
              disabled
            />
          );
        }
        return location.editable ? (
          <div>
            <Tag color="#f50">{stock}</Tag>
            <InputNumberField
              label=""
              name={[
                record.id,
                "stocks",
                location.id,
                isFleet ? "locationSendStock" : "locationSuggestionStock",
              ]}
              min="0"
              max={999}
              placeholder="0"
              controls={false}
              style={{ width: 50, marginBottom: 0 }}
              onChange={handleStockCount(record.id, location.id)}
              rules={
                isFleet
                  ? [
                      {
                        type: "number",
                        min: +locationSuggestionStock,
                        max: +locationSuggestionStock,
                        message: "",
                        validateTrigger: "onChange",
                        warningOnly: true,
                      },
                    ]
                  : []
              }
            />
          </div>
        ) : (
          <Input
            min="0"
            placeholder="S"
            style={{ width: 50, marginBottom: 0 }}
            disabled
            value={stock}
          />
        );
      },
    })) || []),
    viewsSetting.requestedStock.show && {
      title: viewsSetting.requestedStock?.title || "Production Order",
      dataIndex: "requestedStock",
      key: "requestedStock",
      fixed: locations ? "right" : false,
      width: 100,
      render: (v: string, record: IProduct2143) => {
        const stock = isRaw
          ? get(record, `stocks[${locationId}].rawStock`, 0)
          : get(record, `stocks[${locationId}].stock`, 0);
        return (
          <InputNumberField
            label=""
            name={[record.id, "requestedStock"]}
            min="0"
            validateTrigger="onChange"
            placeholder={
              viewsSetting.requestedStock?.title || "Production Order"
            }
            style={{ width: 70, marginBottom: 0 }}
            controls={false}
            disabled={viewsSetting.requestedStock.disabled}
            rules={
              isFleet
                ? [
                    {
                      type: "number",
                      max: +stock,
                      message: "",
                      validateTrigger: "onChange",
                    },
                  ]
                : []
            }
          />
        );
      },
    },
    viewsSetting.stockAvailable?.show && {
      title: "stockAvailable",
      dataIndex: "stockAvailable",
      key: "stockAvailable",
      fixed: locations ? "right" : false,
      width: 100,
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "stockAvailable"]}
          min="0"
          placeholder="Stock Available"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.stockAvailable?.disabled}
        />
      ),
    },
    viewsSetting.transferRawStocks?.show && {
      title: "Transfer raw stock ",
      dataIndex: "transferRawStocks",
      key: "transferRawStocks",
      fixed: locations ? "right" : false,
      width: 100,
      render: (v: string, record: IProduct2143) => (
        <Form.Item
          name={[record.id, "transferRawStocks"]}
          label=""
          valuePropName="checked"
          initialValue={true}
        >
          <Switch disabled={!record.isRaw} />
        </Form.Item>
      ),
    },
    viewsSetting.stockCreated.show && {
      title: "Stock created",
      dataIndex: "stockCreated",
      key: "stockCreated",
      width: 100,
      render: (v: string, record: IProduct2143) => {
        const stock = isRaw
          ? get(record, `stocks[${locationId}].rawStock`, 0)
          : get(record, `stocks[${locationId}].stock`, 0);

        form.setFieldValue(
          ["stocks", categoryId, record.id, "previousStock"],
          +stock
        );

        return (
          <>
            <InputNumberField
              label=""
              key="stockCreated"
              name={[record.id, "stockCreated"]}
              min="0"
              placeholder="Stock created"
              style={{ width: "100%", marginBottom: 0 }}
              disabled={viewsSetting.stockCreated.disabled}
            />
            <InputNumberField
              label=""
              key="previousStock"
              name={[record.id, "previousStock"]}
              min="0"
              placeholder="Stock created"
              hidden
            />
          </>
        );
      },
      // form.setFieldValue(
      //   ["stocks", record.id, "previousStock"],
      //   record.stocks ? +(record.stocks[locationId]?.stock || 0) : 0
      // );
    },
  ].filter((v) => !!v);
  React.useEffect(() => {
    productsRef.current = products;
    if (stocks && stocks[categoryId]) {
      forEach(stocks[categoryId], (value, key: any) => {
        const productIndex = findIndex(productsRef.current, ["id", key]);
        if (productIndex !== -1) {
          productsRef.current[productIndex].stocks = {
            ...productsRef.current[productIndex].stocks,
            ...value.stocks,
          };
        }
      });
    }
  }, [products, stocks, isFleet]);
  return (
    <Form.List name={["stocks", categoryId]}>
      {(fields, { add, remove }, { errors }) => (
        <>
          <Table
            columns={columnsList}
            className={isVertical ? "vertical" : ""}
            dataSource={orderBy(products, ["sorting"])}
            loading={false}
            rowKey="id"
            pagination={false}
            scroll={fixedView ? { x: 1300, y: 500 } : { x: "100%", y: 500 }}
          />
        </>
      )}
    </Form.List>
  );
};
