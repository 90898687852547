import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { find, forEach } from "lodash";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Form,
  InputFormWrapper,
} from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ReturnCategory } from "../../molecules";
import {
  addReturn5216ToFirestore,
  listenToLocation1688FromFirestore,
} from "../../../firestore/firestoreService";
import { ILocation1688, IReturn5216Input } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";

export const AddReturnLocation: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const { category59983 } = useSelector((state: any) => state.category59983);
  const { location1688 } = useSelector((state: any) => state.location1688);
  const [loading, setLoading] = React.useState(false);
  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const [error, setError] = React.useState("");
  const params = useParams();
  const locationId = params.locationId || "";
  const productionLineId = params.productionLineId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });
  const handleSubmit = async () => {
    try {
      const values: IReturn5216Input = await form.validateFields();
      forEach(values.stocks, (value, key: string) => {
        values.stocks[key] = {
          ...value,
          comment: value.comment || "",
          returnStock: value.returnStock || 0,
        };
      });
      const locationCode = find(location1688, ["id", locationId])?.code || "00";
      setLoading(true);
      await addReturn5216ToFirestore({
        status: "PENDING",
        locationId,
        productionLineId,
        locationCode,
        stocks: { ...values.stocks },
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };

  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={{}}
          >
            <div className="input-form-title text-center">
              Create New Return
            </div>
            <div className="input-form-description text-center">
              {productionLine?.name || ""}
            </div>
            <Form.List name="stocks">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Collapse
                    defaultActiveKey={[...(productionLine?.categories || [])]}
                  >
                    {productionLine?.categories?.map((category) => (
                      <Collapse.Panel
                        header={
                          find(category59983, ["id", category])?.name || ""
                        }
                        key={category}
                      >
                        <ReturnCategory
                          categoryId={category}
                          viewsSetting={{
                            returnStock: { show: true, disabled: false },
                            wasteStock: { show: false, disabled: false },
                            backToShop: { show: false, disabled: false },
                            reproduction: { show: false, disabled: false },
                            charityStock: { show: false, disabled: false },
                            comment: { show: true, disabled: false },
                          }}
                        />
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </>
              )}
            </Form.List>

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Create Return
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
