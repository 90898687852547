import { find } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Tag } from "../../App/components/atoms";
import { listenToLocation1688FromFirestore } from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToSelectedLocation1688 } from "../../App/redux/data/location1688/location1688Actions";

const currentMenu = (id: string) => [
  // appendRouteHere
  {
    key: "home",
    label: "Home",
    link: "/",
  },
  {
    key: "orders",
    label: "Orders",
    link: `/shop-location/${id}/orders`,
  },
  {
    key: "returns",
    label: "Returns",
    link: `/shop-location/${id}/returns`,
  },
  {
    key: "update-stock-history",
    label: "Stock history",
    link: `/shop-location/${id}/update-stock-history`,
  },
];
const currentBreadcrumb = (name: string) => [
  {
    key: "page",
    label: name,
    link: "#",
  },
];

const ShopPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const [pageName, setPageName] = React.useState<string>("");
  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  const locationId = params.locationId || "";
  const headerMenu = currentMenu(locationId);
  const { selectedLocation1688 } = useSelector(
    (state: any) => state.location1688
  );
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(locationId),
    data: listenToSelectedLocation1688,
    shouldExecute: !!locationId,
    deps: [dispatch, locationId],
  });

  React.useEffect(() => {
    const name = find(headerMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(name?.label || "") || []);
    setPageName(name?.label || "");
  }, [location.pathname]);

  return (
    <AdminPage
      title={
        <div className="admin-page-title">
          {selectedLocation1688?.name} <Tag>{pageName}</Tag>
        </div>
      }
      headerMenu={headerMenu}
      breadcrumb={breadcrumb}
    >
      <Outlet />
    </AdminPage>
  );
};

export default ShopPage;
