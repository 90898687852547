import styled from "styled-components";
export const OrderWrapper = styled.div`
  background-color: #fff;
`;

export const OrderHeaderWrapper = styled.div`
  background-color: #f8f8f8;
  margin-bottom: 10px;
  padding: 10px;
  .ant-form-item {
    margin-bottom: 0px;
    width: 100%;
  }
  .total-amount {
    background-color: #10657f;
    color: #fff;
    padding: 6px;
    font-size: 14px;
    text-align: center;
    width: 100%;
    border-radius: 4px;
  }
`;
