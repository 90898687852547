/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchCategory59983FromFirestore } from "../../../firestore/firestoreService";
import {
  listenToCategory59983,
  listenToCategory59983Filter,
} from "../../../redux/data/category59983/category59983Actions";

export const CategoriesListToolbar: React.FC = () => {
  const { filterCategory59983 } = useSelector(
    (state: any) => state.category59983
  );
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchCategory59983FromFirestore([]),
    data: listenToCategory59983,
    deps: [dispatch],
  });
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddCategoryModal",
        modalProps: { title: "Add Category" },
      })
    );
  };
  const handelOnSearch = (value: string) => {
    dispatch(
      listenToCategory59983Filter({ ...filterCategory59983, name: [value] })
    );
  };
  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Category
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Input.Search
          placeholder="Search Category"
          allowClear
          onSearch={handelOnSearch}
          style={{ width: 304 }}
        />
      </div>
    </PageToolbar>
  );
};
