import { IUser94013 } from "../../../common/types";
import {
  CREATE_USER94013,
  UPDATE_USER94013,
  DELETE_USER94013,
  FETCH_USER94013,
  LISTEN_TO_SELECTED_USER94013,
  FETCH_USER94013_LOCATIONS,
  FETCH_USER94013_PRODUCTION_LINES,
  FILTER_USER94013,
} from "./user94013Constants";

const initialState = {
  user94013: [],
  user94013Locations: [],
  user94013ProductionLines: [],
  selectedUser94013: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterUser94013: {},
};

export default function user94013Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_USER94013:
      return {
        ...state,
        user94013: [...state.user94013, payload],
      };
    case UPDATE_USER94013:
      return {
        ...state,
        user94013: [
          ...state.user94013.filter((evt: IUser94013) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_USER94013:
      return {
        ...state,
        user94013: [
          ...state.user94013.filter((evt: IUser94013) => evt.id !== payload),
        ],
      };
    case FETCH_USER94013:
      return {
        ...state,
        user94013: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case FETCH_USER94013_LOCATIONS:
      return {
        ...state,
        user94013Locations: payload,
      };
    case FETCH_USER94013_PRODUCTION_LINES:
      return {
        ...state,
        user94013ProductionLines: payload,
      };
    case LISTEN_TO_SELECTED_USER94013:
      return {
        ...state,
        selectedUser94013: payload,
      };
    case FILTER_USER94013:
      return {
        ...state,
        filterUser94013: payload || {},
      };
    default:
      return state;
  }
}
