/* eslint-disable no-console */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { find, get } from "lodash";
import { CheckOutlined, CloudUploadOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  Alert,
  Button,
  Divider,
  Form,
  InputFormWrapper,
  Tabs,
} from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { PageToolbar, StockCategory } from "../../molecules";
import {
  addProduction67969ToFirestore,
  createOrdersProduction67969ToFirestore,
  fetchLocation1688FromFirestore,
  listenToLocation1688FromFirestore,
} from "../../../firestore/firestoreService";
import { ILocation1688 } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { listenToLocation1688Locations } from "../../../redux/data/location1688/location1688Actions";
import { IProduction67969Input } from "../../../common/types/production67969Types";
import { productionTypes } from "../../../enums";

interface IProps {
  productionType: string;
  createOrders?: boolean;
}

export const AddProductionStock: React.FC<IProps> = ({
  productionType,
  createOrders,
}) => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const { location1688Locations } = useSelector(
    (state: any) => state.location1688
  );
  const { category59983 } = useSelector((state: any) => state.category59983);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [loading, setLoading] = React.useState(false);
  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const [error, setError] = React.useState("");
  const params = useParams();
  const locationId = params.locationId || "";
  const productionLineId = params.productionLineId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });
  useFirestoreCollection({
    query: () =>
      fetchLocation1688FromFirestore([
        {
          type: "filter",
          name: "type",
          opr: "==",
          value: "LOCATION",
        },
      ]),
    data: listenToLocation1688Locations,
    deps: [dispatch],
  });
  const handleSubmit = (action: string) => async () => {
    try {
      setLoading(true);
      const values: IProduction67969Input = await form.validateFields();
      if (createOrders) {
        await createOrdersProduction67969ToFirestore({
          productionLineId,
          type: productionType,
          stocks: values.stocks,
        });
      } else {
        await addProduction67969ToFirestore({
          productionLineId,
          status: action === "save" ? "PENDING" : "PENDING_PRODUCTION",
          type: productionType,
          stocks: values.stocks,
        });
      }

      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields, [productionLineId, locationId]);
  const HeaderPage = (
    <PageToolbar>
      <div className="input-form-description text-center">
        {createOrders
          ? `Distribute ${
              productionTypes[productionType || ""]?.label || ""
            } Order`
          : `Create ${
              productionTypes[productionType || ""]?.label || ""
            } Production Order`}
      </div>
      <div className="page-toolbar-right-children-elements">
        <>
          {!createOrders && (
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit("save")}
              loading={loading}
              icon={<CheckOutlined />}
            >
              Save
            </Button>
          )}
          <Button
            type="primary"
            className="input-form-btn"
            onClick={handleSubmit("")}
            loading={loading}
            icon={<CloudUploadOutlined />}
          >
            {createOrders
              ? `Distribute ${
                  productionTypes[productionType || ""]?.label || ""
                } Order`
              : `Create ${
                  productionTypes[productionType || ""]?.label || ""
                } Production Order`}
          </Button>
        </>
      </div>
    </PageToolbar>
  );
  return (
    <AdminPageBody pageToolbar={HeaderPage}>
      <InputFormWrapper>
        <div className="input-form-body-full">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={{ stocks: {} }}
          >
            <Tabs
              defaultActiveKey={get(productionLine, "categories[0]", "0")}
              type="card"
              items={productionLine?.categories?.map((category) => ({
                label: find(category59983, ["id", category])?.name || "",
                key: category,
                children: (
                  <StockCategory
                    categoryId={category}
                    userRole={myProfile?.role || ""}
                    isRaw={productionType === "RAW"}
                    locationId={productionLineId}
                    isVertical
                    fixedView
                    locations={[
                      ...(location1688Locations?.map(
                        (location: ILocation1688) => ({
                          id: location.id,
                          name: location.name,
                          editable: true,
                          lastUpdate: moment(location.lastStockUpdate).format(
                            "HH:mm DD/MM "
                          ),
                        })
                      ) || []),
                      {
                        id: productionLineId,
                        name: "Current Stock",
                        isFixed: true,
                      },
                    ]}
                    viewsSetting={{
                      stockCreated: { show: false, disabled: false },
                      requestedStock: { show: true, disabled: false },
                    }}
                  />
                ),
              }))}
            />

            <Divider></Divider>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
