/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IReturn5216 } from "../../../common/types";
import { deleteReturn5216InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { ProductionLineWrapper } from "./ProductionLine.style";
import { ReturnListsListToolbar } from "./ReturnListsListToolbar";

export const ReturnLists: React.FC = () => {
  const { return5216 } = useSelector((state: any) => state.return5216);
  const { loading } = useSelector((state: any) => state.async);
  const history = useNavigate();
  const handelDeleteReturn5216 = async (id: string) => {
    await deleteReturn5216InFirestore(id);
  };
  const handelEditReturnLists = (id: string) => () => {
    history(`/return/${id}`);
  };
  const columnsList = [
    {
      title: "Return Id",
      dataIndex: "returnId",
      key: "returnId",
      render: (v: string) => v || "",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (v: string) => v || "",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (v: string) => v || "",
    },
    {
      title: "Submitted by",
      dataIndex: "submittedById",
      key: "submittedById",
      render: (v: string) => v || "",
    },
    {
      title: "Location",
      dataIndex: "LocationId",
      key: "LocationId",
      render: (v: string) => v || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IReturn5216) => (
        <ActionCell
          onDelete={() => handelDeleteReturn5216(record.id)}
          onEdit={handelEditReturnLists(record.id)}
        />
      ),
    },
  ];
  return (
    <AdminPageBody pageToolbar={<ReturnListsListToolbar />}>
      <ProductionLineWrapper>
        <Table
          columns={columnsList}
          dataSource={return5216}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
        />
      </ProductionLineWrapper>
    </AdminPageBody>
  );
};
