/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchUnit23243FromFirestore } from "../../../firestore/firestoreService";
import {
  listenToUnit23243,
  listenToUnit23243Filter,
} from "../../../redux/data/unit23243/unit23243Actions";

export const UnitsListToolbar: React.FC = () => {
  const { filterUnit23243 } = useSelector((state: any) => state.unit23243);
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchUnit23243FromFirestore([]),
    data: listenToUnit23243,
    deps: [dispatch],
  });
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddUnitModal",
        modalProps: { title: "Add Unit" },
      })
    );
  };
  const handelRestFilter = () => {
    dispatch(listenToUnit23243Filter({}));
  };
  const handelOnSearch = (value: string) => {
    dispatch(listenToUnit23243Filter({ ...filterUnit23243, name: [value] }));
  };
  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Units
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Input.Search
          placeholder="Search Unit"
          allowClear
          onSearch={handelOnSearch}
          style={{ width: 304 }}
        />
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          Reset Filters
        </Button>
      </div>
    </PageToolbar>
  );
};
