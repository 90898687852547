/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ReturnListMole } from "../../molecules";
import { ReturnsWrapper } from "./Returns.style";
import { LocationReturnListToolbar } from "./LocationReturnListToolbar";

export const LocationReturnList: React.FC = () => {
  const { return5216 } = useSelector((state: any) => state.return5216);
  return (
    <AdminPageBody pageToolbar={<LocationReturnListToolbar />}>
      <ReturnsWrapper>
        <ReturnListMole
          data={return5216}
          showPagination
          showLocation={false}
          showProduction
          showDeleteBtn={false}
        />
      </ReturnsWrapper>
    </AdminPageBody>
  );
};
