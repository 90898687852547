import { IProduct2143 } from "../../../common/types";
import {
  CREATE_PRODUCT2143,
  UPDATE_PRODUCT2143,
  DELETE_PRODUCT2143,
  FETCH_PRODUCT2143,
  LISTEN_TO_SELECTED_PRODUCT2143,
  FILTER_PRODUCT21433,
} from "./product2143Constants";

const initialState = {
  product2143: [],
  selectedProduct2143: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterProduct2143: {},
};

export default function product2143Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_PRODUCT2143:
      return {
        ...state,
        product2143: [...state.product2143, payload],
      };
    case UPDATE_PRODUCT2143:
      return {
        ...state,
        product2143: [
          ...state.product2143.filter(
            (evt: IProduct2143) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_PRODUCT2143:
      return {
        ...state,
        product2143: [
          ...state.product2143.filter(
            (evt: IProduct2143) => evt.id !== payload
          ),
        ],
      };
    case FETCH_PRODUCT2143:
      return {
        ...state,
        product2143: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_PRODUCT2143:
      return {
        ...state,
        selectedProduct2143: payload,
      };
    case FILTER_PRODUCT21433:
      return {
        ...state,
        filterProduct2143: payload || {},
      };
    default:
      return state;
  }
}
