/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchProduct2143FromFirestore } from "../../../firestore/firestoreService";
import {
  listenToProduct2143,
  listenToProduct2143Filter,
} from "../../../redux/data/product2143/product2143Actions";

export const ProductsListToolbar: React.FC = () => {
  const { filterProduct2143 } = useSelector((state: any) => state.product2143);
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchProduct2143FromFirestore([]),
    data: listenToProduct2143,
    deps: [dispatch],
  });
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddProductModal",
        modalProps: { title: "Add Product" },
      })
    );
  };
  const handelRestFilter = () => {
    dispatch(listenToProduct2143Filter({}));
  };
  const handelOnSearch = (value: string) => {
    dispatch(
      listenToProduct2143Filter({ ...filterProduct2143, name: [value] })
    );
  };
  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Product
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Input.Search
          placeholder="Search User"
          allowClear
          onSearch={handelOnSearch}
          style={{ width: 304 }}
        />
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          Reset Filters
        </Button>
      </div>
    </PageToolbar>
  );
};
