import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const cityTypes: ISelectOptionMap = {
  LIMASSOL: {
    label: "Limassol",
    value: "LIMASSOL",
  },
  NICOSIA: {
    label: "Nicosia",
    value: "NICOSIA",
  },
  LARNACA: {
    label: "Larnaca",
    value: "LARNACA",
  },
  PAPHOS: {
    label: "Paphos",
    value: "PAPHOS",
  },
};

export const cityTypeOptions = values(cityTypes);
