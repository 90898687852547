/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IVendor83788 } from "../../../common/types";
import { deleteVendor83788InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { cityTypeOptions, cityTypes } from "../../../enums";
import { listenToVendor83788Filter } from "../../../redux/data/vendor83788/vendor83788Actions";
import { InventoryWrapper } from "./Inventory.style";
import { VendorsListToolbar } from "./VendorsListToolbar";

export const Vendors: React.FC = () => {
  const { vendor83788, filterVendor83788 } = useSelector(
    (state: any) => state.vendor83788
  );
  const { loading } = useSelector((state: any) => state.async);
  const dispatch = useDispatch();
  const handelDeleteVendor83788 = async (id: string) => {
    await deleteVendor83788InFirestore(id);
  };
  const handelEditVendors = (record: IVendor83788) => () => {
    dispatch(
      openModal({
        modalType: "AddVendorModal",
        modalProps: { title: "Update Vendor", record },
      })
    );
  };
  const columnsList: ColumnsType<IVendor83788> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: filterVendor83788.name || null,
      render: (v: string) => v || "",
      sorter: (a: IVendor83788, b: IVendor83788) =>
        a.name.localeCompare(b.name),
      onFilter: (value: any, record: IVendor83788) =>
        record.name.toLowerCase().indexOf(value.toLowerCase()) === 0,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (v: string) => cityTypes[v]?.label || "",
      filteredValue: filterVendor83788.city || null,
      filters: cityTypeOptions.map((order: any) => ({
        text: order.label,
        value: order.value,
      })),
      onFilter: (value: any, record: IVendor83788) =>
        record?.city?.indexOf(value) === 0,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (v: string) => v || "",
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (v: string) => v || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IVendor83788) => (
        <ActionCell
          onDelete={() => handelDeleteVendor83788(record.id)}
          onEdit={handelEditVendors(record)}
        />
      ),
    },
  ];
  const handleChange: TableProps<IVendor83788>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    dispatch(listenToVendor83788Filter(filters));
  };
  return (
    <AdminPageBody pageToolbar={<VendorsListToolbar />}>
      <InventoryWrapper>
        <Table
          columns={columnsList}
          dataSource={vendor83788}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
          onChange={handleChange}
        />
      </InventoryWrapper>
    </AdminPageBody>
  );
};
