import { FilterValue } from "antd/lib/table/interface";
import { IStockHistory36376 } from "../../../common/types";
import {
  UPDATE_STOCKHISTORY36376,
  DELETE_STOCKHISTORY36376,
  FETCH_STOCKHISTORY36376,
  LISTEN_TO_SELECTED_STOCKHISTORY36376,
  FILTER_STOCKHISTORY36376,
} from "./stockHistory36376Constants";

export function listenToStockHistory36376(
  stockHistory36376: IStockHistory36376[]
) {
  return {
    type: FETCH_STOCKHISTORY36376,
    payload: stockHistory36376,
  };
}

export function listenToSelectedStockHistory36376(
  stockHistory36376: IStockHistory36376
) {
  return {
    type: LISTEN_TO_SELECTED_STOCKHISTORY36376,
    payload: stockHistory36376,
  };
}

export function updateStockHistory36376(stockHistory36376: IStockHistory36376) {
  return {
    type: UPDATE_STOCKHISTORY36376,
    payload: stockHistory36376,
  };
}

export function deleteStockHistory36376(stockHistory36376Id: string) {
  return {
    type: DELETE_STOCKHISTORY36376,
    payload: stockHistory36376Id,
  };
}

export function listenToStockHistory36376Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_STOCKHISTORY36376,
    payload: filters,
  };
}
