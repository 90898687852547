import {
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import React from "react";
import { Popconfirm } from "antd";
import { MenuActionCellWrapper } from "./Cells.style";

interface IMenuActionCell {
  title: string | React.ReactNode;
  action?: () => void;
  icon?: React.ReactNode;
  divider?: boolean;
  hidden?: boolean;
}
interface IActionCellProps {
  editLink?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  onExport?: () => void;
  menu?: IMenuActionCell[];
  className?: string;
}
export const ActionCell: React.FC<IActionCellProps> = ({
  menu,
  onDelete,
  onEdit,
  onExport,
  className,
}) => (
  <MenuActionCellWrapper>
    {onExport && (
      <FileExcelOutlined onClick={onExport} className="menu-action-cell-item" />
    )}
    {onEdit && (
      <EditOutlined onClick={onEdit} className="menu-action-cell-item" />
    )}
    {onDelete && (
      <Popconfirm
        placement="left"
        title="Are you sure you want to delete the item？"
        onConfirm={onDelete}
      >
        <DeleteOutlined className="menu-action-cell-item menu-action-cell-delete" />
      </Popconfirm>
    )}
  </MenuActionCellWrapper>
);
