/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchStockHistory36376FromFirestore } from "../../../firestore/firestoreService";
import { listenToStockHistory36376 } from "../../../redux/data/stockHistory36376/stockHistory36376Actions";

export const LocationOrderHistoryListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const locationId = params.locationId || "";
  useFirestoreCollection({
    query: () =>
      fetchStockHistory36376FromFirestore([
        {
          type: "filter",
          name: "locationId",
          opr: "==",
          value: locationId,
        },
      ]),
    data: listenToStockHistory36376,
    deps: [dispatch, locationId],
  });

  return (
    <PageToolbar>
      <div></div>
      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
};
