import { IOrder50453 } from "../../../common/types";
import {
  CREATE_ORDER50453,
  UPDATE_ORDER50453,
  DELETE_ORDER50453,
  FETCH_ORDER50453,
  LISTEN_TO_SELECTED_ORDER50453,
  FILTER_ORDER50453,
} from "./order50453Constants";

const initialState = {
  order50453: [],
  selectedOrder50453: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterOrder50453: {},
};

export default function order50453Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_ORDER50453:
      return {
        ...state,
        order50453: [...state.order50453, payload],
      };
    case UPDATE_ORDER50453:
      return {
        ...state,
        order50453: [
          ...state.order50453.filter(
            (evt: IOrder50453) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_ORDER50453:
      return {
        ...state,
        order50453: [
          ...state.order50453.filter((evt: IOrder50453) => evt.id !== payload),
        ],
      };
    case FETCH_ORDER50453:
      return {
        ...state,
        order50453: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_ORDER50453:
      return {
        ...state,
        selectedOrder50453: payload,
      };
    case FILTER_ORDER50453:
      return {
        ...state,
        filterOrder50453: payload || {},
      };
    default:
      return state;
  }
}
