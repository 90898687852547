import { IUser94013Input, IQueryConfigArgs } from "../../common/types";
import firebase from "../../config/firebase";
import { getInitialFormName } from "../../common/util/util";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_USER94013 } from "./firebaseConstants";

export function fetchUser94013FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_USER94013);
  return collectionWithArgs(ref, args || []);
}
export function listenToUser94013FromFirestore(id: string) {
  return db.collection(COLLECTION_USER94013).doc(id);
}

export async function addUser94013ToFirestore(doc: IUser94013Input) {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(doc.email, doc?.password || "");
    if (!result?.user) {
      throw new Error("Registration Filed");
    }
    await result.user.updateProfile({
      displayName: doc.name,
    });
    await db
      .collection(COLLECTION_USER94013)
      .doc(result.user.uid)
      .set({
        email: doc.email,
        name: doc.name,
        avatar: getInitialFormName(doc.name),
        status: true,
        locationId: doc.locationId,
        role: doc.role,
      });
    return result.user.uid;
  } catch (error) {
    throw error;
  }
}

export function updateUser94013InFirestore(
  docId: string,
  doc: IUser94013Input | any
) {
  return db.collection(COLLECTION_USER94013).doc(docId).update(doc);
}

export function activeToggleUser94013InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_USER94013).doc(docId).update({
    status,
  });
}

export function deleteUser94013InFirestore(docId: string) {
  return db.collection(COLLECTION_USER94013).doc(docId).delete();
}
