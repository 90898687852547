/* eslint-disable no-console */
import { find, get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { permissionTypes, productionTypes } from "../../../enums";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { Divider, InputFormWrapper } from "../../atoms";
import { AdminDashboard } from "./AdminDashboard";
import { FleetManagerDashboard } from "./FleetManagerDashboard";
import { HomeWrapper } from "./Home.style";
import { LocationDashboard } from "./LocationDashboard";
import { ProductionBakerDashboard } from "./ProductionBakerDashboard";
import { ProductionDashboard } from "./ProductionDashboard";

interface ISelectOptionMap {
  [key: string]: {
    dashboard: React.ReactNode;
    welcome: string;
  };
}
export const Dashboard: React.FC = () => {
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const { location1688 } = useSelector((state: any) => state.location1688);
  const DashboardType: ISelectOptionMap = {
    [permissionTypes.ADMIN.value]: {
      dashboard: <AdminDashboard />,
      welcome: "Welcome to Admin",
    },
    [permissionTypes.FLEET_MANAGER.value]: {
      dashboard: <FleetManagerDashboard />,
      welcome: "Welcome to Fleet Manager",
    },
    [permissionTypes.PRODUCTION_MANAGER.value]: {
      dashboard: <ProductionDashboard />,
      welcome: `Welcome to ${
        find(location1688, ["id", myProfile?.locationId])?.name || ""
      }`,
    },
    [permissionTypes.PRODUCTION_BAKER.value]: {
      dashboard: <ProductionBakerDashboard />,
      welcome: `Welcome to ${
        find(location1688, ["id", myProfile?.locationId])?.name || ""
      } Baker`,
    },
    [permissionTypes.LOCATION_MANAGER.value]: {
      dashboard: (
        <LocationDashboard
          locationId={myProfile?.locationId || ""}
          productionType={productionTypes.BAKED.value}
        />
      ),
      welcome: `Welcome to ${
        find(location1688, ["id", myProfile?.locationId])?.name || ""
      }`,
    },
    [permissionTypes.LOCATION_BAKER.value]: {
      dashboard: (
        <LocationDashboard
          locationId={myProfile?.locationId || ""}
          productionType={productionTypes.RAW.value}
        />
      ),
      welcome: `Welcome to ${
        find(location1688, ["id", myProfile?.locationId])?.name || ""
      }`,
    },
  };
  return (
    <AdminPageBody>
      <HomeWrapper>
        <InputFormWrapper>
          <div className="input-form-body">
            <div className="input-form-title text-center">
              {get(DashboardType, myProfile?.role)?.welcome || ""}
            </div>
            <Divider> Dashboard</Divider>
            {get(DashboardType, myProfile?.role)?.dashboard || ""}
          </div>
        </InputFormWrapper>
      </HomeWrapper>
    </AdminPageBody>
  );
};
