import { IVendor83788Input, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_VENDOR83788 } from "./firebaseConstants";

export function fetchVendor83788FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_VENDOR83788);
  return collectionWithArgs(ref, args || []);
}
export function listenToVendor83788FromFirestore(id: string) {
  return db.collection(COLLECTION_VENDOR83788).doc(id);
}

export function addVendor83788ToFirestore(doc: IVendor83788Input) {
  return db.collection(COLLECTION_VENDOR83788).add({
    ...doc,
    createDate: new Date(),
  });
}

export function updateVendor83788InFirestore(
  docId: string,
  doc: IVendor83788Input | any
) {
  return db.collection(COLLECTION_VENDOR83788).doc(docId).update(doc);
}

export function activeToggleVendor83788InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_VENDOR83788).doc(docId).update({
    status,
  });
}

export function deleteVendor83788InFirestore(docId: string) {
  return db.collection(COLLECTION_VENDOR83788).doc(docId).delete();
}
