/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../atoms";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchGoodsReceived26115FromFirestore } from "../../../firestore/firestoreService";
import { listenToGoodsReceived26115 } from "../../../redux/data/goodsReceived26115/goodsReceived26115Actions";

export const GoodsReceivedListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useNavigate();
  const productionLineId = params.productionLineId || "";
  useFirestoreCollection({
    query: () =>
      fetchGoodsReceived26115FromFirestore([
        {
          type: "filter",
          name: "productionLineId",
          opr: "==",
          value: productionLineId,
        },
      ]),
    data: listenToGoodsReceived26115,
    deps: [dispatch, productionLineId],
  });
  const handelAddBoard = () => {
    history(`/production-line/${productionLineId}/addGoodsReceived`);
  };

  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Goods Received
      </Button>

      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
};
