import { filter } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { IProduct2143 } from "../../../common/types";
import { Table } from "../../atoms";
import { InputNumberField, InputTextAreaField } from "../commonFields";

interface IViewSetting {
  show: boolean;
  disabled: boolean;
}
interface IViewSettingMap {
  [key: string]: IViewSetting;
}
interface IProps {
  categoryId: string;
  viewsSetting: IViewSettingMap;
}
export const ReturnCategory: React.FC<IProps> = ({
  categoryId,
  viewsSetting,
}) => {
  const { product2143 } = useSelector((state: any) => state.product2143);
  const columnsList: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v: string) => v || "",
    },
    viewsSetting.returnStock.show && {
      title: "Return stock",
      dataIndex: "returnStock",
      key: "returnStock",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "returnStock"]}
          min="0"
          placeholder="Current stock"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.returnStock.disabled}
        />
      ),
    },
    viewsSetting.wasteStock.show && {
      title: "Waste",
      dataIndex: "wasteStock",
      key: "wasteStock",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "wasteStock"]}
          min="0"
          placeholder="Waste"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.wasteStock.disabled}
        />
      ),
    },
    viewsSetting.charityStock.show && {
      title: "Charity",
      dataIndex: "charityStock",
      key: "charityStock",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "charityStock"]}
          min="0"
          placeholder="Charity"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.charityStock.disabled}
        />
      ),
    },
    viewsSetting.backToShop.show && {
      title: "Back to shop",
      dataIndex: "backToShop",
      key: "backToShop",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "backToShop"]}
          min="0"
          placeholder="Back to shop"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.backToShop.disabled}
        />
      ),
    },
    viewsSetting.reproduction.show && {
      title: "Reproduction",
      dataIndex: "reproduction",
      key: "reproduction",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "reproduction"]}
          min="0"
          placeholder="Reproduction"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.reproduction.disabled}
        />
      ),
    },
    viewsSetting.comment.show && {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (v: string, record: IProduct2143) => (
        <InputTextAreaField
          label=""
          name={[record.id, "comment"]}
          placeholder="Comments"
          rows={1}
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.comment.disabled}
        />
      ),
    },
  ].filter((v) => !!v);

  return (
    <Table
      columns={columnsList}
      dataSource={filter(product2143, ["categoryId", categoryId])}
      loading={false}
      rowKey="id"
      pagination={false}
    />
  );
};
