/* eslint-disable no-console */
import { findIndex, forEach } from "lodash";
import {
  IProduction67969Input,
  IQueryConfigArgs,
  IOrder50453Input,
} from "../../common/types";
import {
  COLLECTION_ORDER50453,
  COLLECTION_PRODUCT2143,
  COLLECTION_PRODUCTION67969,
} from "./firebaseConstants";
import {
  db,
  collectionWithArgs,
  setUserUpdateLog,
  setUserAddLog,
} from "./firestoreService";
import { getOrderIdFromFirestore } from "./Order50453Store";

export function fetchProduction67969FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_PRODUCTION67969);
  return collectionWithArgs(ref, args || []);
}
export function listenToProduction67969FromFirestore(id: string) {
  return db.collection(COLLECTION_PRODUCTION67969).doc(id);
}

export async function addProduction67969ToFirestore(
  doc: IProduction67969Input
) {
  forEach(doc.stocks, (category: any) => {
    forEach(category, (value, key: string) => {
      if (typeof category[key] !== "undefined") {
        forEach(value.stocks, (loc: any, locKey: string) => {
          if (loc.locationSuggestionStock) {
            category[key].stocks[locKey] = {
              ...loc,
              locationSendStock: loc.locationSuggestionStock,
            };
          }
        });
      }
    });
  });
  return db.collection(COLLECTION_PRODUCTION67969).add({
    ...doc,
    stocks: JSON.stringify(doc.stocks),
    ...setUserAddLog(),
  });
}

export async function updateProduction67969InFirestore(
  docId: string,
  doc: IProduction67969Input | any
  // locations?: ILocation1688[]
) {
  try {
    const batch = db.batch();
    const orders: IOrder50453Input[] = [];
    const productionRef = db.collection(COLLECTION_PRODUCTION67969).doc(docId);
    const productRef = db.collection(COLLECTION_PRODUCT2143);

    if (doc.status === "PENDING") {
      forEach(doc.stocks, (category: any) => {
        forEach(category, (value, key: string) => {
          delete value.currentStock;
          if (typeof category[key] !== "undefined") {
            forEach(value.stocks, (loc: any, locKey: string) => {
              if (loc.locationSuggestionStock) {
                category[key].stocks[locKey] = {
                  ...loc,
                  locationSendStock: loc.locationSuggestionStock,
                };
              }
            });
          }
        });
      });
    }
    if (doc.status === "COMPLETED") {
      forEach(doc.stocks, (category) => {
        forEach(category, (value, key: string) => {
          delete value.currentStock;
          if (typeof category[key] !== "undefined") {
            const stockType = doc.type === "RAW" ? "rawStock" : "stock";
            batch.update(productRef.doc(key), {
              [`stocks.${doc.productionLineId}`]: {
                [stockType]:
                  +(value.previousStock || 0) + +(value.stockCreated || 0),
                locationId: doc.productionLineId,
              },
            });
          }
        });
      });
    }

    if (doc.status === "DISTRIBUTED") {
      forEach(doc.stocks, (category) => {
        forEach(category, (value, key: string) => {
          if (typeof category[key] !== "undefined") {
            console.log(value);
            if (+(value.currentStock || 0) - +(value.requestedStock || 0) < 0) {
              throw new Error(
                `you can have requested more then you in the stock `
              );
            } else {
              delete value.currentStock;
            }

            forEach(value.stocks, (loc, locKey) => {
              const orderIndex = findIndex(orders, ["locationId", locKey]);
              if (orderIndex !== -1) {
                if (loc?.locationSendStock) {
                  orders[orderIndex].stocks[key] = {
                    qtySent: loc?.locationSendStock || 0,
                    comment: "",
                  };
                }
              } else {
                if (loc?.locationSendStock) {
                  orders.push({
                    status: "PENDING",
                    locationId: locKey,
                    productionLineId: doc.productionLineId,
                    stocks: {
                      [key]: {
                        qtySent: loc?.locationSendStock || 0,
                        comment: "",
                      },
                    },
                    locationCode: "",
                    type: doc.type,
                  });
                }
              }
            });
          }
        });
      });
      const orderId = await getOrderIdFromFirestore();
      if (orders.length === 0) {
        throw new Error("order is empty");
      }
      orders.map((order: IOrder50453Input, orderIndex) => {
        const orderRefId = db.collection(COLLECTION_ORDER50453).doc().id;
        const orderRef = db.collection(COLLECTION_ORDER50453).doc(orderRefId);
        batch.set(orderRef, {
          ...order,
          orderId: +orderId + +orderIndex,
          ...setUserAddLog(),
        });
      });
    }

    batch.update(productionRef, {
      ...doc,
      stocks: JSON.stringify(doc.stocks),
      ...setUserUpdateLog(),
    });
    await batch.commit();
    return true;
  } catch (error) {
    throw error;
  }
}

export function activeToggleProduction67969InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_PRODUCTION67969).doc(docId).update({
    status,
  });
}

export function deleteProduction67969InFirestore(docId: string) {
  return db.collection(COLLECTION_PRODUCTION67969).doc(docId).delete();
}

export async function createOrdersProduction67969ToFirestore(
  doc: IProduction67969Input | any
) {
  try {
    console.log(doc);
    const batch = db.batch();
    const orders: IOrder50453Input[] = [];
    forEach(doc.stocks, (category) => {
      forEach(category, (value, key: string) => {
        if (typeof category[key] !== "undefined") {
          if (+(value.currentStock || 0) - +(value.stockCreated || 0) < 0) {
            throw new Error(
              `you can have requested more than you in the stock `
            );
          }
          forEach(value.stocks, (loc, locKey) => {
            const orderIndex = findIndex(orders, ["locationId", locKey]);
            if (orderIndex !== -1) {
              if (loc?.locationSuggestionStock) {
                orders[orderIndex].stocks[key] = {
                  qtySent: loc?.locationSuggestionStock || 0,
                  comment: "",
                };
              }
            } else {
              if (loc?.locationSuggestionStock) {
                orders.push({
                  status: "PENDING",
                  locationId: locKey,
                  productionLineId: doc.productionLineId,
                  stocks: {
                    [key]: {
                      qtySent: loc?.locationSuggestionStock || 0,
                      comment: "",
                    },
                  },
                  locationCode: "",
                  type: doc.type,
                });
              }
            }
          });
        }
      });
    });
    const orderId = await getOrderIdFromFirestore();
    if (orders.length === 0) {
      throw new Error("order is empty");
    }
    orders.map((order: IOrder50453Input, orderIndex) => {
      const orderRefId = db.collection(COLLECTION_ORDER50453).doc().id;
      const orderRef = db.collection(COLLECTION_ORDER50453).doc(orderRefId);
      batch.set(orderRef, {
        ...order,
        orderId: +orderId + +orderIndex,
        ...setUserAddLog(),
      });
    });
    await batch.commit();
    return true;
  } catch (error) {
    throw error;
  }
}
