/* eslint-disable no-console */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { find, get } from "lodash";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Alert, Button, Form, InputFormWrapper, Tabs } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { PageToolbar, StockCategory } from "../../molecules";
import {
  listenToLocation1688FromFirestore,
  updateLocation1688StocksToFirestore,
} from "../../../firestore/firestoreService";
import { ILocation1688 } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";

interface IProps {
  productionType: string;
}

export const LocationUpdateStock: React.FC<IProps> = ({ productionType }) => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const { category59983 } = useSelector((state: any) => state.category59983);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [loading, setLoading] = React.useState(false);
  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const [error, setError] = React.useState("");
  const params = useParams();
  const locationId = params.locationId || "";
  const productionLineId = params.productionLineId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values: any = await form.validateFields();
      await updateLocation1688StocksToFirestore(
        locationId,
        productionLineId,
        productionType,
        values.stocks
      );
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields, [productionLineId, locationId]);
  const HeaderPage = (
    <PageToolbar>
      <div className="input-form-description text-center">
        Stock Update: {productionLine?.name || ""}
      </div>
      <div className="page-toolbar-right-children-elements">
        <Button
          type="primary"
          className="input-form-btn"
          onClick={handleSubmit}
          loading={loading}
          icon={<CloudUploadOutlined />}
        >
          Save Updated Stock
        </Button>
      </div>
    </PageToolbar>
  );
  return (
    <AdminPageBody pageToolbar={HeaderPage}>
      <InputFormWrapper>
        <div className="input-form-body-full">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={{ stocks: {} }}
          >
            <Tabs
              defaultActiveKey={get(productionLine, "categories[0]", "0")}
              type="card"
              items={productionLine?.categories?.map((category) => ({
                label: find(category59983, ["id", category])?.name || "",
                key: category,
                children: (
                  <StockCategory
                    categoryId={category}
                    locations={[
                      {
                        id: locationId,
                        name: "Previous Stock",
                        lastUpdate: "",
                      },
                    ]}
                    isRaw={productionType === "RAW"}
                    locationId={locationId}
                    userRole={myProfile?.role || ""}
                    viewsSetting={{
                      stockCreated: { show: false, disabled: false },
                      requestedStock: {
                        show: true,
                        disabled: false,
                        title: "Current Stock",
                      },
                    }}
                  />
                ),
              }))}
            />
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
