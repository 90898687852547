/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import moment from "moment";
import { TableProps } from "antd";
import { ActionCell } from "..";
import { IProduction67969 } from "../../../common/types";
import { deleteProduction67969InFirestore } from "../../../firestore/firestoreService";
import { Table, Tag } from "../../atoms";
import { ILocation1688 } from "../../../common/types/location1688Types";
import { listenToProduction67969Filter } from "../../../redux/data/production67969/production67969Actions";
import { productionStatusTypes, productionTypes } from "../../../enums";

interface IProps {
  data: IProduction67969[];
  showProductionLine: boolean;
  showDeleteBtn: boolean;
  showPagination: boolean;
  editProduction: (id: string, productionLineId: string) => () => void;
}
export const ProductionList: React.FC<IProps> = ({
  data,
  showProductionLine,
  showDeleteBtn,
  showPagination,
  editProduction,
}) => {
  const dispatch = useDispatch();
  const { user94013 } = useSelector((state: any) => state.user94013);
  const { loading } = useSelector((state: any) => state.async);
  const { filterProduction67969 } = useSelector(
    (state: any) => state.production67969
  );
  const { location1688 } = useSelector((state: any) => state.location1688);

  const handelDeleteProduction67969 = async (id: string) => {
    await deleteProduction67969InFirestore(id);
  };

  const columnsList: any = [
    {
      title: "Date",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "Time",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("HH:mm"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (v: string) => (
        <Tag color={productionTypes[v].color}>{productionTypes[v].label}</Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: string) => (
        <Tag color={productionStatusTypes[v].color}>
          {productionStatusTypes[v].label}
        </Tag>
      ),
    },
    showProductionLine && {
      title: "Production Line",
      dataIndex: "productionLineId",
      key: "productionLineId",
      filterSearch: true,
      filteredValue: filterProduction67969.productionLineId || null,
      render: (v: string) => find(location1688, ["id", v])?.name || "",
      filters: location1688.map((location: ILocation1688) => ({
        text: location.name,
        value: location.id,
      })),
      onFilter: (value: any, record: IProduction67969) =>
        record?.productionLineId?.indexOf(value) === 0,
    },
    {
      title: "Created by",
      dataIndex: "createdById",
      key: "createdById",
      render: (v: string) => find(user94013, ["id", v])?.name || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IProduction67969) => (
        <ActionCell
          onDelete={
            showDeleteBtn
              ? () => handelDeleteProduction67969(record.id)
              : undefined
          }
          onEdit={editProduction(record.id, record.productionLineId)}
        />
      ),
    },
  ].filter((v) => !!v);
  const handleChange: TableProps<IProduction67969>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    dispatch(listenToProduction67969Filter(filters));
  };
  return (
    <Table
      columns={columnsList}
      dataSource={data}
      loading={loading}
      rowKey="id"
      onChange={handleChange}
      pagination={
        showPagination && {
          pageSize: 20,
        }
      }
      onRow={(record, rowIndex) => ({
        onDoubleClick: editProduction(
          record.id,
          record?.productionLineId || ""
        ),
        // double click row
      })}
    />
  );
};
