/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchOrder50453FromFirestore } from "../../../firestore/firestoreService";
import { listenToOrder50453 } from "../../../redux/data/order50453/order50453Actions";

export const OrderListsListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const locationId = params.locationId || "";
  useFirestoreCollection({
    query: () =>
      fetchOrder50453FromFirestore([
        {
          type: "filter",
          name: "locationId",
          opr: "==",
          value: locationId,
        },
      ]),
    data: listenToOrder50453,
    deps: [dispatch, locationId],
  });
  return (
    <PageToolbar>
      <div></div>

      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
};
