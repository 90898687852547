/* eslint-disable no-console */
import { IGoodsReceived26115 } from "../../../common/types";
import {
  UPDATE_GOODSRECEIVED26115,
  DELETE_GOODSRECEIVED26115,
  FETCH_GOODSRECEIVED26115,
  LISTEN_TO_SELECTED_GOODSRECEIVED26115,
} from "./goodsReceived26115Constants";

export function listenToGoodsReceived26115(
  goodsReceived26115: IGoodsReceived26115[]
) {
  return {
    type: FETCH_GOODSRECEIVED26115,
    payload: goodsReceived26115,
  };
}

export function listenToSelectedGoodsReceived26115(
  goodsReceived26115: IGoodsReceived26115
) {
  return {
    type: LISTEN_TO_SELECTED_GOODSRECEIVED26115,
    payload: goodsReceived26115,
  };
}

export function updateGoodsReceived26115(
  goodsReceived26115: IGoodsReceived26115
) {
  return {
    type: UPDATE_GOODSRECEIVED26115,
    payload: goodsReceived26115,
  };
}

export function deleteGoodsReceived26115(goodsReceived26115Id: string) {
  return {
    type: DELETE_GOODSRECEIVED26115,
    payload: goodsReceived26115Id,
  };
}
