/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { ILocation1688 } from "../../../common/types";
import {
  UPDATE_LOCATION1688,
  DELETE_LOCATION1688,
  FETCH_LOCATION1688,
  LISTEN_TO_SELECTED_LOCATION1688,
  FETCH_LOCATION1688_LOCATIONS,
  FETCH_LOCATION1688_PRODUCTION_LINES,
  FILTER_LOCATION1688,
} from "./location1688Constants";

export function listenToLocation1688(location1688: ILocation1688[]) {
  return {
    type: FETCH_LOCATION1688,
    payload: location1688,
  };
}
export function listenToLocation1688Locations(location1688: ILocation1688[]) {
  return {
    type: FETCH_LOCATION1688_LOCATIONS,
    payload: location1688,
  };
}
export function listenToLocation1688ProductionLine(
  location1688: ILocation1688[]
) {
  return {
    type: FETCH_LOCATION1688_PRODUCTION_LINES,
    payload: location1688,
  };
}

export function listenToSelectedLocation1688(location1688: ILocation1688) {
  return {
    type: LISTEN_TO_SELECTED_LOCATION1688,
    payload: location1688,
  };
}

export function updateLocation1688(location1688: ILocation1688) {
  return {
    type: UPDATE_LOCATION1688,
    payload: location1688,
  };
}

export function deleteLocation1688(location1688Id: string) {
  return {
    type: DELETE_LOCATION1688,
    payload: location1688Id,
  };
}

export function listenToLocation1688Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_LOCATION1688,
    payload: filters,
  };
}
