/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchIngredient56227FromFirestore } from "../../../firestore/firestoreService";
import {
  listenToIngredient56227,
  listenToIngredient56227Filter,
} from "../../../redux/data/ingredient56227/ingredient56227Actions";

export const IngredientsListToolbar: React.FC = () => {
  const { filterIngredient56227 } = useSelector(
    (state: any) => state.ingredient56227
  );
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchIngredient56227FromFirestore([]),
    data: listenToIngredient56227,
    deps: [dispatch],
  });
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddIngredientModal",
        modalProps: { title: "Add Ingredient" },
      })
    );
  };
  const handelRestFilter = () => {
    dispatch(listenToIngredient56227Filter({}));
  };
  const handelOnSearch = (value: string) => {
    dispatch(
      listenToIngredient56227Filter({
        ...filterIngredient56227,
        name: [value],
      })
    );
  };
  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Ingredient
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Input.Search
          placeholder="Search User"
          allowClear
          onSearch={handelOnSearch}
          style={{ width: 304 }}
        />
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          Reset Filters
        </Button>
      </div>
    </PageToolbar>
  );
};
