/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { find, orderBy } from "lodash";
import { TableProps } from "antd";
import { ActionCell } from "../../molecules";
import { ILocation1688, IOrder50453, IUser94013 } from "../../../common/types";
import { deleteOrder50453InFirestore } from "../../../firestore/firestoreService";
import { Table, Tag } from "../../atoms";
import { orderTypeOptions, orderTypes } from "../../../enums/orderType";
import { listenToOrder50453Filter } from "../../../redux/data/order50453/order50453Actions";
import { productionTypes } from "../../../enums";
import { openModal } from "../../../redux/data/modals/modalReducer";

interface IProps {
  data: IOrder50453[];
  showPagination: boolean;
  showLocation: boolean;
  showProduction: boolean;
  showDeleteBtn: boolean;
  showExportBtn?: boolean;
  editOrder: (id: string, productionLineId: string) => () => void;
}

export const OrderListMole: React.FC<IProps> = ({
  data,
  showPagination,
  showLocation,
  showProduction,
  showDeleteBtn,
  showExportBtn,
  editOrder,
}) => {
  const dispatch = useDispatch();
  const [dataSorted, setDataSorted] = React.useState<IOrder50453[]>([]);
  const { user94013 } = useSelector((state: any) => state.user94013);
  const { filterOrder50453 } = useSelector((state: any) => state.order50453);
  const { location1688 } = useSelector((state: any) => state.location1688);
  const { loading } = useSelector((state: any) => state.async);

  const handelDeleteOrder50453 = async (id: string) => {
    await deleteOrder50453InFirestore(id);
  };
  const handelExportOrder = (record: IOrder50453) => () => {
    console.log(record);
    dispatch(
      openModal({
        modalType: "OrderExclModal",
        modalProps: {
          title: "Export",
          orderId: record.id,
          productionLineId: record.productionLineId,
        },
      })
    );
  };
  const columnsList: any = [
    {
      title: "Order id",
      dataIndex: "orderId",
      key: "orderId",
      filteredValue: filterOrder50453.orderId || null,
      render: (v: string, record: IOrder50453) =>
        `O-${find(location1688, ["id", record.locationId])?.code || ""}-${v}` ||
        "",
      sorter: (a: IOrder50453, b: IOrder50453) => +a.orderId - +b.orderId,
      onFilter: (value: any, record: IOrder50453) => +record.orderId === +value,
    },
    {
      title: "Date",
      dataIndex: "createAt",
      key: "createAt",
      filteredValue: filterOrder50453.createAt || null,
      render: (v: string) => moment(v).format("DD/MM/YYYY"),
      onFilter: (value: any, record: IOrder50453) => {
        const [weekStart, weekEnd] = value.split(",") || [];
        return moment(record.createAt).isBetween(weekStart, weekEnd);
      },
    },
    {
      title: "Time",
      dataIndex: "createAt",
      key: "createAt",

      render: (v: string) => moment(v).format("HH:mm"),
    },
    {
      title: "Submitted by",
      dataIndex: "createdById",
      key: "createdById",
      filterSearch: true,
      filteredValue: filterOrder50453.createdById || null,
      render: (v: string) => find(user94013, ["id", v])?.name || "",
      filters: user94013.map((user: IUser94013) => ({
        text: user.name,
        value: user.id,
      })),
      onFilter: (value: any, record: IOrder50453) =>
        record?.createdById?.indexOf(value) === 0,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",

      render: (v: string) => (
        <Tag color={productionTypes[v]?.color || ""}>
          {productionTypes[v]?.label || ""}
        </Tag>
      ),
    },
    showLocation && {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      filterSearch: true,
      filteredValue: filterOrder50453.locationId || null,
      render: (v: string) => find(location1688, ["id", v])?.name || "",
      filters: location1688.map((location: ILocation1688) => ({
        text: location.name,
        value: location.id,
      })),
      onFilter: (value: any, record: IOrder50453) =>
        record?.locationId?.indexOf(value) === 0,
    },
    showProduction && {
      title: "Production Line",
      dataIndex: "productionLineId",
      key: "productionLineId",
      filterSearch: true,
      filteredValue: filterOrder50453.productionLineId || null,
      render: (v: string) => find(location1688, ["id", v])?.name || "",
      filters: location1688.map((location: ILocation1688) => ({
        text: location.name,
        value: location.id,
      })),
      onFilter: (value: any, record: IOrder50453) =>
        record?.productionLineId?.indexOf(value) === 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: string) => (
        <Tag color={orderTypes[v].color}>{orderTypes[v].label}</Tag>
      ),
      filteredValue: filterOrder50453.status || null,
      filters: orderTypeOptions.map((order: any) => ({
        text: order.label,
        value: order.value,
      })),
      onFilter: (value: any, record: IOrder50453) =>
        record?.status?.indexOf(value) === 0,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IOrder50453) => (
        <ActionCell
          onExport={showExportBtn ? handelExportOrder(record) : undefined}
          onDelete={
            showDeleteBtn ? () => handelDeleteOrder50453(record.id) : undefined
          }
          onEdit={editOrder(record.id, record?.productionLineId || "")}
        />
      ),
    },
  ].filter((v) => !!v);
  const handleChange: TableProps<IOrder50453>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    dispatch(listenToOrder50453Filter(filters));
  };
  React.useEffect(() => {
    setDataSorted(orderBy(data, ["createAt"], ["desc"]));
  }, [data]);
  return (
    <Table
      columns={columnsList}
      dataSource={dataSorted}
      loading={loading}
      rowKey="id"
      pagination={
        showPagination && {
          pageSize: 20,
        }
      }
      onChange={handleChange}
      onRow={(record, rowIndex) => ({
        onDoubleClick: editOrder(record.id, record?.productionLineId || ""),
        // double click row
      })}
    />
  );
};
