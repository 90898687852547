import { IUnit23243Input, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_UNIT23243 } from "./firebaseConstants";

export function fetchUnit23243FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_UNIT23243);
  return collectionWithArgs(ref, args || []);
}
export function listenToUnit23243FromFirestore(id: string) {
  return db.collection(COLLECTION_UNIT23243).doc(id);
}

export function addUnit23243ToFirestore(doc: IUnit23243Input) {
  return db.collection(COLLECTION_UNIT23243).add({
    ...doc,
    createDate: new Date(),
  });
}

export function updateUnit23243InFirestore(
  docId: string,
  doc: IUnit23243Input | any
) {
  return db.collection(COLLECTION_UNIT23243).doc(docId).update(doc);
}

export function activeToggleUnit23243InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_UNIT23243).doc(docId).update({
    status,
  });
}

export function deleteUnit23243InFirestore(docId: string) {
  return db.collection(COLLECTION_UNIT23243).doc(docId).delete();
}
