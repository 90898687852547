import { find } from "lodash";
import React from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";

const currentMenu = (id: string) => [
  // appendRouteHere
  {
    key: "home",
    label: "Home",
    link: "/",
  },
  {
    key: "productions",
    label: "Productions",
    link: `/production-baker/${id}/productions`,
  },
];
const currentBreadcrumb = (name: string) => [
  {
    key: "page",
    label: name,
    link: "#",
  },
];

const ProductionBaker: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  const productionLineId = params.productionLineId || "";
  const headerMenu = currentMenu(productionLineId);

  React.useEffect(() => {
    const name = find(headerMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(name?.label || "") || []);
  }, [location.pathname]);

  return (
    <AdminPage
      title={<div className="admin-page-title">Production Baker</div>}
      headerMenu={headerMenu}
      breadcrumb={breadcrumb}
    >
      <Outlet />
    </AdminPage>
  );
};

export default ProductionBaker;
