/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputField } from "../../molecules";
import {
  listenToUnit23243FromFirestore,
  updateUnit23243InFirestore,
} from "../../../firestore/firestoreService";
import { IUnit23243Input } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedUnit23243 } from "../../../redux/data/unit23243/unit23243Actions";

export const UpdateGoodsReceived: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const updategoodsreceivedId = params.updategoodsreceivedId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToUnit23243FromFirestore(updategoodsreceivedId),
    data: listenToSelectedUnit23243,
    deps: [dispatch, updategoodsreceivedId],
    shouldExecute: !!updategoodsreceivedId,
  });

  const { selectedUnit23243 } = useSelector((state: any) => state.unit23243);
  const handleSubmit = async () => {
    try {
      const values: IUnit23243Input = await form.validateFields();
      setLoading(true);
      await updateUnit23243InFirestore(updategoodsreceivedId, {
        name: values.name,
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      {error !== "" && <Alert message={error} type="error" />}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedUnit23243}
          >
            <Row gutter={30}>
              <Col span={24}>
                <InputField
                  label="UpdateGoodsReceived Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Missing UpdateGoodsReceived Name",
                    },
                  ]}
                  placeholder="UpdateGoodsReceived  Name"
                />
              </Col>
            </Row>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update UpdateGoodsReceived
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
