import { ICategory59983Input, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_CATEGORY59983 } from "./firebaseConstants";

export function fetchCategory59983FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_CATEGORY59983);
  return collectionWithArgs(ref, args || []);
}
export function listenToCategory59983FromFirestore(id: string) {
  return db.collection(COLLECTION_CATEGORY59983).doc(id);
}

export function addCategory59983ToFirestore(doc: ICategory59983Input) {
  return db.collection(COLLECTION_CATEGORY59983).add({
    ...doc,
    createDate: new Date(),
  });
}

export function updateCategory59983InFirestore(
  docId: string,
  doc: ICategory59983Input | any
) {
  return db.collection(COLLECTION_CATEGORY59983).doc(docId).update(doc);
}

export function activeToggleCategory59983InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_CATEGORY59983).doc(docId).update({
    status,
  });
}

export function deleteCategory59983InFirestore(docId: string) {
  return db.collection(COLLECTION_CATEGORY59983).doc(docId).delete();
}
