import { Drawer as AntDrawer, DrawerProps } from "antd";
import styled from "styled-components";

export const Drawer: any = styled(AntDrawer)<DrawerProps>`
  .drawer {
    &-extra {
      align-items: center;
      display: flex;
      margin-left: auto;

      &-btn,
      &-btn-link,
      &-btn-group {
        font-size: 15px;
        height: 28px;
        line-height: 26px;
        padding: 0 8px;
        border-radius: 6px;
        user-select: none;
      }
      &-btn-link {
        font-weight: 500;
        color: #6d6e6f;
        align-items: center;
        display: flex;
        &.isLiked {
          color: #f1bd6c;
          background: rgb(55 23 23 / 3%);
          &:hover {
            color: #f1bd6c;
          }
        }
        &:hover,
        &:active {
          background: rgb(55 23 23 / 3%);
          color: #1e1f21;
        }
      }
    }
    &-title {
      align-items: center;
      display: flex;
      margin-left: auto;
      &-tag {
        background-color: rgb(213 95 142 / 5%);
        color: #d55f8e;
        border: rgb(213 95 142 / 6%);
        border-radius: 6px;
        font-size: 16px;
        line-height: 28px;
      }
      &-btn {
        font-size: 16px;
        height: 28px;
        line-height: 26px;
        padding: 0 8px;
        border-radius: 6px;
        user-select: none;
        &:hover,
        &:active {
          background: rgb(55 23 23 / 3%);
          border: rgb(55 23 23 / 3%);
          color: #1e1f21;
        }
      }
    }
  }
  .ant-drawer {
    &-header {
      padding: 10px 20px;
    }
    &-body {
      padding: 0px;
    }
    &-footer {
      flex-shrink: 0;
      padding: 0;
      border-top: 1px solid #f0f0f0;
    }
  }
`;
