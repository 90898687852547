import { IUnit23243 } from "../../../common/types";
import {
  CREATE_UNIT23243,
  UPDATE_UNIT23243,
  DELETE_UNIT23243,
  FETCH_UNIT23243,
  LISTEN_TO_SELECTED_UNIT23243,
  FILTER_UNIT23243,
} from "./unit23243Constants";

const initialState = {
  unit23243: [],
  selectedUnit23243: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterUnit23243: {},
};

export default function unit23243Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_UNIT23243:
      return {
        ...state,
        unit23243: [...state.unit23243, payload],
      };
    case UPDATE_UNIT23243:
      return {
        ...state,
        unit23243: [
          ...state.unit23243.filter((evt: IUnit23243) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_UNIT23243:
      return {
        ...state,
        unit23243: [
          ...state.unit23243.filter((evt: IUnit23243) => evt.id !== payload),
        ],
      };
    case FETCH_UNIT23243:
      return {
        ...state,
        unit23243: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_UNIT23243:
      return {
        ...state,
        selectedUnit23243: payload,
      };
    case FILTER_UNIT23243:
      return {
        ...state,
        filterUnit23243: payload || {},
      };
    default:
      return state;
  }
}
