/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { IUser94013 } from "../../../common/types";
import {
  UPDATE_USER94013,
  DELETE_USER94013,
  FETCH_USER94013,
  LISTEN_TO_SELECTED_USER94013,
  FETCH_USER94013_LOCATIONS,
  FETCH_USER94013_PRODUCTION_LINES,
  FILTER_USER94013,
} from "./user94013Constants";

export function listenToUser94013(user94013: IUser94013[]) {
  return {
    type: FETCH_USER94013,
    payload: user94013,
  };
}
export function listenToUser94013Locations(user94013: IUser94013[]) {
  return {
    type: FETCH_USER94013_LOCATIONS,
    payload: user94013,
  };
}
export function listenToUser94013ProductionLines(user94013: IUser94013[]) {
  return {
    type: FETCH_USER94013_PRODUCTION_LINES,
    payload: user94013,
  };
}

export function listenToSelectedUser94013(user94013: IUser94013) {
  return {
    type: LISTEN_TO_SELECTED_USER94013,
    payload: user94013,
  };
}

export function updateUser94013(user94013: IUser94013) {
  return {
    type: UPDATE_USER94013,
    payload: user94013,
  };
}

export function deleteUser94013(user94013Id: string) {
  return {
    type: DELETE_USER94013,
    payload: user94013Id,
  };
}

export function listenToUser94013Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_USER94013,
    payload: filters,
  };
}
