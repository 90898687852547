/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { find } from "lodash";
import moment from "moment";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IOrder50453 } from "../../../common/types";
import { fetchOrder50453FromFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { orderTypes } from "../../../enums";
import { ProductionLineWrapper } from "./ProductionLine.style";

export const ProductionLineOrders: React.FC = () => {
  const [orders, setOrders] = React.useState<IOrder50453[]>([]);
  const { user94013 } = useSelector((state: any) => state.user94013);
  const { location1688 } = useSelector((state: any) => state.location1688);
  const { loading } = useSelector((state: any) => state.async);
  const history = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const productionLineId = params.productionLineId || "";
  useFirestoreCollection({
    query: () =>
      fetchOrder50453FromFirestore([
        {
          type: "filter",
          name: "productionLineId",
          opr: "==",
          value: productionLineId,
        },
      ]),
    data: setOrders,
    local: true,
    deps: [dispatch, productionLineId],
  });
  const handelEditOrderLists = (id: string) => () => {
    history(`/order/${id}`);
  };
  const columnsList = [
    {
      title: "Order id",
      dataIndex: "orderId",
      key: "orderId",
      render: (v: string) => v || "",
    },
    {
      title: "Date",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "Time",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("HH:mm"),
    },
    {
      title: "Submitted by",
      dataIndex: "createdById",
      key: "createdById",
      render: (v: string) => find(user94013, ["id", v])?.name || "",
    },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      render: (v: string) => find(location1688, ["id", v])?.name || "",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: string) => orderTypes[v].label,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IOrder50453) => (
        <ActionCell onEdit={handelEditOrderLists(record.id)} />
      ),
    },
  ];
  return (
    <AdminPageBody>
      <ProductionLineWrapper>
        <Table
          columns={columnsList}
          dataSource={orders}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
        />
      </ProductionLineWrapper>
    </AdminPageBody>
  );
};
