import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { find } from "lodash";
import { Collapse, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { OrderHeader, OrderCategory } from "../../molecules";
import {
  listenToLocation1688FromFirestore,
  listenToOrder50453FromFirestore,
} from "../../../firestore/firestoreService";
import { ILocation1688 } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedOrder50453 } from "../../../redux/data/order50453/order50453Actions";

export const AdminOrderDetails: React.FC = () => {
  const [form] = Form.useForm();
  const { category59983 } = useSelector((state: any) => state.category59983);
  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const params = useParams();
  const orderId = params.orderId || "";
  const productionLineId = params.productionLineId || "";
  const { selectedOrder50453 } = useSelector((state: any) => state.order50453);
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToOrder50453FromFirestore(orderId),
    data: listenToSelectedOrder50453,
    deps: [dispatch, orderId],
    shouldExecute: !!orderId,
  });
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });

  React.useEffect(() => form.resetFields, [selectedOrder50453, orderId]);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedOrder50453}
          >
            {selectedOrder50453 && (
              <OrderHeader
                order={selectedOrder50453}
                productionLine={productionLine}
              />
            )}
            <Form.List name="stocks">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Collapse
                    defaultActiveKey={[...(productionLine?.categories || [])]}
                  >
                    {productionLine?.categories?.map((category) => (
                      <Collapse.Panel
                        header={
                          find(category59983, ["id", category])?.name || ""
                        }
                        key={category}
                      >
                        <OrderCategory
                          categoryId={category}
                          stocks={selectedOrder50453?.stocks || {}}
                          viewsSetting={{
                            currentStock: { show: true, disabled: true },
                            request: { show: true, disabled: true },
                            inventoryStock: { show: true, disabled: true },
                            qtySent: { show: true, disabled: true },
                            receivedStock: { show: true, disabled: true },
                            comment: { show: true, disabled: true },
                            issueComment: { show: true, disabled: true },
                          }}
                        />
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </>
              )}
            </Form.List>

            <Divider></Divider>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
