/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  InputFormWrapper,
} from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputField, InputNumberField } from "../../molecules";
import {
  listenToUserFromFirestore,
  updateUserInFirestore,
} from "../../../firestore/firestoreService";
import { IUserInput } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedUser } from "../../../redux/data/user/userActions";

export const UserDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const userId = params.userId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToUserFromFirestore(userId),
    data: listenToSelectedUser,
    deps: [dispatch, userId],
    shouldExecute: !!userId,
  });

  const { selectedUser } = useSelector((state: any) => state.users);
  const handleSubmit = async () => {
    try {
      const values: IUserInput = await form.validateFields();
      setLoading(true);
      await updateUserInFirestore(userId, {
        name: values.name,
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      {error !== "" && <Alert message={error} type="error" />}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedUser}
          >
            <Row gutter={30}>
              <Col span={18}>
                <Divider orientation="left">{selectedUser?.name}</Divider>
                <Row gutter={8}>
                  <Col span={24}>
                    <InputField
                      label="User Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Missing User  Name",
                        },
                      ]}
                      placeholder="User  Name"
                    />
                  </Col>

                  <Col span={24}>
                    <InputField
                      label="User code"
                      name="userCode"
                      rules={[
                        {
                          required: true,
                          message: "Missing User code",
                        },
                      ]}
                      placeholder="User code"
                    />
                  </Col>
                </Row>
                <Divider>Info</Divider>
                <Row gutter={8}>
                  <Col span={12}>
                    <InputField
                      label="Phone Number"
                      name="phoneNumber"
                      placeholder="Phone Number"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Phone Number 2"
                      name="phoneNumber2"
                      placeholder="Phone Number 2"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Fax Number"
                      name="faxNumber"
                      placeholder="Fax Number"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Email"
                      name="email"
                      placeholder="Email"
                    />
                  </Col>
                  <Col span={18}>
                    <InputField
                      label="Address"
                      name="address"
                      placeholder="Address"
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      label="Post Code"
                      name="postCode"
                      placeholder="Post Code"
                    />
                  </Col>
                </Row>
                <Divider>User representatives</Divider>
                <Form.List name="userRepresents">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          label={index === 0 ? "Represent Name" : ""}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Please input passenger's name or delete this field.",
                              },
                            ]}
                            noStyle
                          >
                            <Input placeholder="passenger name" />
                          </Form.Item>
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Represent
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col span={6}>
                <Divider>User discounts</Divider>
                <InputNumberField
                  label="Private Yacht Discount"
                  name="privateYachtDiscount"
                  min={0}
                  formatter={(value: string) => `${value} %`}
                  placeholder="Private Yacht Discount"
                />
                <InputNumberField
                  label="Group Yacht Discount"
                  name="groupYachtDiscount"
                  min={0}
                  formatter={(value: string) => `${value} %`}
                  placeholder="Group Yacht Discount"
                />
                <InputNumberField
                  label="Car Discount"
                  name="carDiscount"
                  min={0}
                  formatter={(value: string) => `${value} %`}
                  placeholder="Car Discount"
                />
                <InputNumberField
                  label="Airplane Discount"
                  name="airplaneDiscount"
                  min={0}
                  formatter={(value: string) => `${value} %`}
                  placeholder="Airplane Discount"
                />
              </Col>
            </Row>

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update User
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
