/* eslint-disable no-console */
import { forEach } from "lodash";
import { ILocation1688Input, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs, setUserAddLog } from "./firestoreService";
import {
  COLLECTION_LOCATION1688,
  COLLECTION_PRODUCT2143,
  COLLECTION_STOCKHISTORY36376,
} from "./firebaseConstants";

export function fetchLocation1688FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_LOCATION1688);
  return collectionWithArgs(ref, args || []);
}
export function listenToLocation1688FromFirestore(id: string) {
  return db.collection(COLLECTION_LOCATION1688).doc(id);
}

export function addLocation1688ToFirestore(doc: ILocation1688Input) {
  return db.collection(COLLECTION_LOCATION1688).add({
    ...doc,
    createDate: new Date(),
  });
}

export function updateLocation1688InFirestore(
  docId: string,
  doc: ILocation1688Input
) {
  return db.collection(COLLECTION_LOCATION1688).doc(docId).update(doc);
}

export function activeToggleLocation1688InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_LOCATION1688).doc(docId).update({
    status,
  });
}

export function deleteLocation1688InFirestore(docId: string) {
  return db.collection(COLLECTION_LOCATION1688).doc(docId).delete();
}

// interface IStockMap {
//   [key: string]: IStock16371;
// }
export async function updateLocation1688StocksToFirestore(
  docId: string,
  productionLineId: string,
  productionType: string,
  stocks: any
) {
  try {
    const batch = db.batch();
    const locationRef = db.collection(COLLECTION_LOCATION1688).doc(docId);
    const productionId = db.collection(COLLECTION_STOCKHISTORY36376).doc().id;
    const stockHistoryRef = db
      .collection(COLLECTION_STOCKHISTORY36376)
      .doc(productionId);

    const productRef = db.collection(COLLECTION_PRODUCT2143);

    forEach(stocks, (category) => {
      forEach(category, (value, key: string) => {
        if (typeof category[key] !== "undefined") {
          const stockType = productionType === "RAW" ? "rawStock" : "stock";
          if (value.requestedStock !== undefined) {
            batch.update(productRef.doc(key), {
              [`stocks.${docId}`]: {
                [stockType]: +value.requestedStock,
                locationId: docId,
              },
            });
          }
        }
      });
    });
    batch.set(stockHistoryRef, {
      locationId: docId,
      productionLineId,
      productionType,
      stocks: JSON.stringify(stocks),
      ...setUserAddLog(),
    });
    batch.update(locationRef, {
      lastStockUpdate: new Date(),
    });
    await batch.commit();
    return true;
  } catch (error) {
    throw error;
  }
}
