/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputField, SelectField, UserSelectedField } from "../../molecules";
import {
  listenToLocation1688FromFirestore,
  updateLocation1688InFirestore,
} from "../../../firestore/firestoreService";
import { ILocation1688Input } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedLocation1688 } from "../../../redux/data/location1688/location1688Actions";
import { cityTypeOptions } from "../../../enums";

export const LocationMainDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const locationId = params.locationId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(locationId),
    data: listenToSelectedLocation1688,
    deps: [dispatch, locationId],
    shouldExecute: !!locationId,
  });

  const { selectedLocation1688 } = useSelector(
    (state: any) => state.location1688
  );
  const handleSubmit = async () => {
    try {
      const values: ILocation1688Input = await form.validateFields();
      setLoading(true);
      await updateLocation1688InFirestore(locationId, {
        name: values.name,
        status: true,
        address: values.address,
        managerId: values.managerId,
        city: values.city,
        code: values.code,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      {error !== "" && <Alert message={error} type="error" />}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedLocation1688}
          >
            <Row gutter={30}>
              <Col span={18}>
                <InputField
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: "Missing Name" }]}
                  placeholder="Name"
                />
              </Col>
              <Col span={6}>
                <InputField
                  label="Code"
                  name="code"
                  rules={[{ required: true, message: "Missing Location Code" }]}
                  placeholder="Location Code"
                />
              </Col>
              <Col span={24}>
                <SelectField
                  label="City"
                  name="city"
                  style={{ width: "100%" }}
                  placeholder="City"
                  options={cityTypeOptions}
                  rules={[{ required: true, message: "Missing City" }]}
                />
                <InputField
                  label="Address"
                  name="address"
                  rules={[{ required: true, message: "Missing Address" }]}
                  placeholder="Address"
                />
                <UserSelectedField
                  label="Manager"
                  name="managerId"
                  rules={[{ required: true, message: "Missing Manager" }]}
                  placeholder="Manager"
                />
              </Col>
            </Row>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
