import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const productionStatusTypes: ISelectOptionMap = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
    color: "blue",
  },
  PENDING_PRODUCTION: {
    label: "Pending Production",
    value: "PENDING_PRODUCTION",
    color: "orange",
  },
  COMPLETED: {
    label: "Completed",
    value: "COMPLETED",
    color: "green",
  },
  DISTRIBUTED: {
    label: "Distributed",
    value: "DISTRIBUTED",
    color: "green",
  },
};

export const productionStatusTypeOptions = values(productionStatusTypes);
