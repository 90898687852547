/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { find } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { Table } from "../../atoms";
import { productionTypes } from "../../../enums/productionType";
import { ActionCell } from "../../molecules";
import { IStockHistory36376 } from "../../../common/types";
import { LocationWrapper } from "./Location.style";
import { LocationOrderHistoryListToolbar } from "./LocationOrderHistoryListToolbar";

export const LocationOrderHistoryList: React.FC = () => {
  const { user94013 } = useSelector((state: any) => state.user94013);
  const history = useNavigate();
  const { location1688 } = useSelector((state: any) => state.location1688);
  const { stockHistory36376 } = useSelector(
    (state: any) => state.stockHistory36376
  );
  const { loading } = useSelector((state: any) => state.async);
  const params = useParams();
  const locationId = params.locationId || "";
  const handelViewsStockHistory =
    (id: string, productionLineId: string) => () => {
      history(
        `/shop-location/${locationId}/production-line/${productionLineId}/stock-history/${id}`
      );
    };
  const columnsList = [
    {
      title: "Date",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "Time",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("HH:mm"),
    },
    {
      title: "Submitted by",
      dataIndex: "createdById",
      key: "createdById",
      render: (v: string) => find(user94013, ["id", v])?.name || "",
    },
    {
      title: "Type",
      dataIndex: "productionType",
      key: "productionType",
      render: (v: string) => productionTypes[v]?.label || "",
    },
    {
      title: "Production Line",
      dataIndex: "productionLineId",
      key: "productionLineId",
      render: (v: string) => find(location1688, ["id", v])?.name || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IStockHistory36376) => (
        <ActionCell
          onEdit={handelViewsStockHistory(record.id, record.productionLineId)}
        />
      ),
    },
  ];

  return (
    <AdminPageBody pageToolbar={<LocationOrderHistoryListToolbar />}>
      <LocationWrapper>
        <Table
          columns={columnsList}
          dataSource={stockHistory36376}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
        />
      </LocationWrapper>
    </AdminPageBody>
  );
};
