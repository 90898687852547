/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { IVendor83788 } from "../../../common/types";
import {
  UPDATE_VENDOR83788,
  DELETE_VENDOR83788,
  FETCH_VENDOR83788,
  LISTEN_TO_SELECTED_VENDOR83788,
  FILTER_VENDOR83788,
} from "./vendor83788Constants";

export function listenToVendor83788(vendor83788: IVendor83788[]) {
  return {
    type: FETCH_VENDOR83788,
    payload: vendor83788,
  };
}

export function listenToSelectedVendor83788(vendor83788: IVendor83788) {
  return {
    type: LISTEN_TO_SELECTED_VENDOR83788,
    payload: vendor83788,
  };
}

export function updateVendor83788(vendor83788: IVendor83788) {
  return {
    type: UPDATE_VENDOR83788,
    payload: vendor83788,
  };
}

export function deleteVendor83788(vendor83788Id: string) {
  return {
    type: DELETE_VENDOR83788,
    payload: vendor83788Id,
  };
}

export function listenToVendor83788Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_VENDOR83788,
    payload: filters,
  };
}
