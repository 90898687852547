/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import { TableProps } from "antd";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IIngredient56227, IUnit23243 } from "../../../common/types";
import { deleteIngredient56227InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { listenToIngredient56227Filter } from "../../../redux/data/ingredient56227/ingredient56227Actions";
import { InventoryWrapper } from "./Inventory.style";
import { IngredientsListToolbar } from "./IngredientsListToolbar";

export const Ingredients: React.FC = () => {
  const { ingredient56227, filterIngredient56227 } = useSelector(
    (state: any) => state.ingredient56227
  );
  const { unit23243 } = useSelector((state: any) => state.unit23243);
  const { loading } = useSelector((state: any) => state.async);
  const dispatch = useDispatch();
  const handelDeleteIngredient56227 = async (id: string) => {
    await deleteIngredient56227InFirestore(id);
  };
  const handelEditIngredients = (record: IIngredient56227) => () => {
    dispatch(
      openModal({
        modalType: "AddIngredientModal",
        modalProps: { title: "Update Ingredient", record },
      })
    );
  };
  const columnsList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: filterIngredient56227.name || null,
      render: (v: string) => v || "",
      sorter: (a: IIngredient56227, b: IIngredient56227) =>
        a.name.localeCompare(b.name),
      onFilter: (value: any, record: IIngredient56227) =>
        record.name.toLowerCase().indexOf(value.toLowerCase()) === 0,
    },
    {
      title: "Unit",
      dataIndex: "unitId",
      key: "unitId",
      filterSearch: true,
      filteredValue: filterIngredient56227.unitId || null,
      render: (v: string) => find(unit23243, ["id", v])?.name || "",
      filters: unit23243.map((unit: IUnit23243) => ({
        text: unit.name,
        value: unit.id,
      })),
      onFilter: (value: any, record: IIngredient56227) =>
        record?.unitId?.indexOf(value) === 0,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IIngredient56227) => (
        <ActionCell
          onDelete={() => handelDeleteIngredient56227(record.id)}
          onEdit={handelEditIngredients(record)}
        />
      ),
    },
  ];
  const handleChange: TableProps<IIngredient56227>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    dispatch(listenToIngredient56227Filter(filters));
  };
  return (
    <AdminPageBody pageToolbar={<IngredientsListToolbar />}>
      <InventoryWrapper>
        <Table
          columns={columnsList}
          dataSource={ingredient56227}
          loading={loading}
          rowKey="id"
          onChange={handleChange}
        />
      </InventoryWrapper>
    </AdminPageBody>
  );
};
