import { IRecipe75190 } from "../../../common/types";
import {
  CREATE_RECIPE75190,
  UPDATE_RECIPE75190,
  DELETE_RECIPE75190,
  FETCH_RECIPE75190,
  LISTEN_TO_SELECTED_RECIPE75190,
} from "./recipe75190Constants";

const initialState = {
  recipe75190: [],
  selectedRecipe75190: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function recipe75190Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_RECIPE75190:
      return {
        ...state,
        recipe75190: [...state.recipe75190, payload],
      };
    case UPDATE_RECIPE75190:
      return {
        ...state,
        recipe75190: [
          ...state.recipe75190.filter(
            (evt: IRecipe75190) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_RECIPE75190:
      return {
        ...state,
        recipe75190: [
          ...state.recipe75190.filter(
            (evt: IRecipe75190) => evt.id !== payload
          ),
        ],
      };
    case FETCH_RECIPE75190:
      return {
        ...state,
        recipe75190: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_RECIPE75190:
      return {
        ...state,
        selectedrecipe75190: payload,
      };

    default:
      return state;
  }
}
