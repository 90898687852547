import {
  IOrderSuggestion71819Input,
  IQueryConfigArgs,
} from "../../common/types";
import {
  db,
  collectionWithArgs,
  setUserAddLog,
  setUserUpdateLog,
} from "./firestoreService";
import { COLLECTION_ORDERSUGGESTION71819 } from "./firebaseConstants";

export function fetchOrderSuggestion71819FromFirestore(
  args?: IQueryConfigArgs[]
) {
  const ref = db.collection(COLLECTION_ORDERSUGGESTION71819);
  return collectionWithArgs(ref, args || []);
}
export function listenToOrderSuggestion71819FromFirestore(id: string) {
  return db.collection(COLLECTION_ORDERSUGGESTION71819).doc(id);
}

export function addOrderSuggestion71819ToFirestore(
  doc: IOrderSuggestion71819Input
) {
  return db.collection(COLLECTION_ORDERSUGGESTION71819).add({
    ...doc,
    ...setUserAddLog(),
  });
}

export function updateOrderSuggestion71819InFirestore(
  docId: string,
  doc: IOrderSuggestion71819Input | any
) {
  return db
    .collection(COLLECTION_ORDERSUGGESTION71819)
    .doc(docId)
    .update({
      ...doc,
      ...setUserUpdateLog(),
    });
}

export function activeToggleOrderSuggestion71819InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_ORDERSUGGESTION71819).doc(docId).update({
    status,
  });
}

export function deleteOrderSuggestion71819InFirestore(docId: string) {
  return db.collection(COLLECTION_ORDERSUGGESTION71819).doc(docId).delete();
}
