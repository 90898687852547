/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ReturnListMole } from "../../molecules";
import { ReturnsWrapper } from "./Returns.style";
import { ReturnListListToolbar } from "./ReturnListToolbar";

export const ReturnList: React.FC = () => {
  const { return5216 } = useSelector((state: any) => state.return5216);

  return (
    <AdminPageBody pageToolbar={<ReturnListListToolbar />}>
      <ReturnsWrapper>
        <ReturnListMole
          data={return5216}
          showPagination
          showLocation
          showProduction
          showDeleteBtn
        />
      </ReturnsWrapper>
    </AdminPageBody>
  );
};
