/* eslint-disable no-console */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { find, get } from "lodash";
import { Form, InputFormWrapper, Tabs } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { PageToolbar, StockCategory } from "../../molecules";
import {
  listenToLocation1688FromFirestore,
  listenToStockHistory36376FromFirestore,
} from "../../../firestore/firestoreService";
import { ILocation1688, IStockHistory36376 } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";

export const LocationStockHistoryView: React.FC = ({}) => {
  const [form] = Form.useForm();
  const { category59983 } = useSelector((state: any) => state.category59983);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const [stockHistory, setStockHistory] = React.useState<IStockHistory36376>();
  const params = useParams();
  const locationId = params.locationId || "";
  const productionLineId = params.productionLineId || "";
  const stockHistoryId = params.stockHistoryId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });
  userFirestoreDoc({
    query: () => listenToStockHistory36376FromFirestore(stockHistoryId),
    data: setStockHistory,
    local: true,
    deps: [dispatch, stockHistoryId],
    shouldExecute: !!stockHistoryId,
  });
  React.useEffect(
    () => form.resetFields,
    [productionLineId, locationId, stockHistoryId, stockHistory]
  );
  const HeaderPage = (
    <PageToolbar>
      <div className="input-form-description text-center">
        Stock History: {productionLine?.name || ""}
      </div>
      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
  return (
    <AdminPageBody pageToolbar={HeaderPage}>
      <InputFormWrapper>
        <div className="input-form-body-full">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={stockHistory}
          >
            <Tabs
              defaultActiveKey={get(productionLine, "categories[0]", "0")}
              type="card"
              items={productionLine?.categories?.map((category) => ({
                label: find(category59983, ["id", category])?.name || "",
                key: category,
                children: (
                  <StockCategory
                    categoryId={category}
                    isRaw={stockHistory?.productionType === "RAW"}
                    locationId={locationId}
                    userRole={myProfile?.role || ""}
                    viewsSetting={{
                      stockCreated: { show: false, disabled: true },
                      requestedStock: {
                        show: true,
                        disabled: true,
                        title: "Stock",
                      },
                    }}
                  />
                ),
              }))}
            />
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
