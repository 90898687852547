/* eslint-disable no-console */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { find, get } from "lodash";
import { CheckOutlined, CloudUploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { Alert, Button, Form, InputFormWrapper, Tabs } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  ItemNotFound,
  Loader,
  PageToolbar,
  StockCategory,
} from "../../molecules";
import {
  fetchLocation1688FromFirestore,
  listenToLocation1688FromFirestore,
  listenToProduction67969FromFirestore,
  updateProduction67969InFirestore,
} from "../../../firestore/firestoreService";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import {
  IProduction67969,
  IProduction67969Input,
} from "../../../common/types/production67969Types";
import { ILocation1688 } from "../../../common/types";
import { productionStatusTypes, productionTypes } from "../../../enums";
import { listenToLocation1688Locations } from "../../../redux/data/location1688/location1688Actions";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";

export const UpdateProduction: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const { location1688Locations } = useSelector(
    (state: any) => state.location1688
  );
  const { category59983 } = useSelector((state: any) => state.category59983);
  const { loading: dataLoading, error: dataError } = useSelector(
    (state: any) => state.async
  );
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [loading, setLoading] = React.useState(false);
  const [production, setProduction] = React.useState<IProduction67969>();
  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const [error, setError] = React.useState("");
  const params = useParams();
  const productionId = params.productionId || "";
  const productionLineId = params.productionLineId || "";
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () =>
      fetchLocation1688FromFirestore([
        {
          type: "filter",
          name: "type",
          opr: "==",
          value: "LOCATION",
        },
      ]),
    data: listenToLocation1688Locations,
    deps: [dispatch],
  });
  userFirestoreDoc({
    query: () => listenToProduction67969FromFirestore(productionId),
    data: setProduction,
    local: true,
    deps: [dispatch, productionId],
    shouldExecute: !!productionId,
  });
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });
  const handleSubmit = (action: string) => async () => {
    try {
      setLoading(true);
      const values: IProduction67969Input = await form.validateFields();
      console.log(
        "productionStocks:",
        production?.stocks,
        "stocks:",
        values.stocks
      );
      await updateProduction67969InFirestore(productionId, {
        status: action === "save" ? production?.status : action,
        productionLineId,
        stocks: { ...(production?.stocks || {}), ...values.stocks },
        type: production?.type,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields, [production, productionId, location]);
  const HeaderPage = (
    <PageToolbar>
      <div className="input-form-description text-center">
        Update {productionTypes[production?.type || ""]?.label || ""} Production
      </div>
      <div className="page-toolbar-right-children-elements">
        {production?.status !== productionStatusTypes.DISTRIBUTED.value && (
          <Button
            type="primary"
            className="input-form-btn"
            onClick={handleSubmit("save")}
            loading={loading}
            icon={<CheckOutlined />}
          >
            Save
          </Button>
        )}
        {production?.status === "PENDING" && (
          <>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit(
                productionStatusTypes.PENDING_PRODUCTION.value
              )}
              loading={loading}
              icon={<CloudUploadOutlined />}
            >
              Create {productionTypes[production?.type || ""]?.label || ""}{" "}
              Production Order
            </Button>
          </>
        )}
        {production?.status ===
          productionStatusTypes.PENDING_PRODUCTION.value && (
          <>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit(productionStatusTypes.COMPLETED.value)}
              loading={loading}
              icon={<CloudUploadOutlined />}
            >
              Complete {productionTypes[production?.type || ""]?.label || ""}{" "}
              Production Order
            </Button>
          </>
        )}
        {production?.status === productionStatusTypes.COMPLETED.value && (
          <>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit(productionStatusTypes.DISTRIBUTED.value)}
              loading={loading}
              icon={<CloudUploadOutlined />}
            >
              Distribute {productionTypes[production?.type || ""]?.label || ""}{" "}
              Production Order
            </Button>
          </>
        )}
      </div>
    </PageToolbar>
  );
  if (!production || dataLoading) {
    return <Loader color="success" title="Loading" />;
  }
  if (dataError) {
    return (
      <ItemNotFound
        redirectTo="/"
        redirectBtnText="Back to List"
        itemType="Production"
      />
    );
  }
  return (
    <AdminPageBody pageToolbar={HeaderPage}>
      <InputFormWrapper>
        <div className="input-form-body-full">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={production}
          >
            <Tabs
              defaultActiveKey={get(productionLine, "categories[0]", "0")}
              type="card"
              items={productionLine?.categories?.map((category) => ({
                label: find(category59983, ["id", category])?.name || "",
                key: category,
                children: (
                  <>
                    {production?.status ===
                      productionStatusTypes.PENDING.value && (
                      <StockCategory
                        categoryId={category}
                        userRole={myProfile?.role || ""}
                        isRaw={production?.type === "RAW"}
                        locationId={productionLineId}
                        isVertical
                        fixedView
                        stocks={production?.stocks}
                        locations={[
                          ...(location1688Locations?.map(
                            (location: ILocation1688) => ({
                              id: location.id,
                              name: location.name,
                              editable: true,
                              lastUpdate: moment(
                                location.lastStockUpdate
                              ).format("HH:mm DD/MM "),
                            })
                          ) || []),
                          {
                            id: productionLineId,
                            name: "Current Stock",
                            isFixed: true,
                          },
                        ]}
                        viewsSetting={{
                          stockCreated: { show: false, disabled: false },
                          requestedStock: { show: true, disabled: false },
                        }}
                      />
                    )}
                    {production?.status ===
                      productionStatusTypes.PENDING_PRODUCTION.value && (
                      <StockCategory
                        categoryId={category}
                        locationId={productionLineId}
                        userRole={myProfile?.role || ""}
                        isRaw={production?.type === "RAW"}
                        viewsSetting={{
                          stockCreated: {
                            show: true,
                            disabled: production?.status === "COMPLETED",
                          },
                          requestedStock: { show: true, disabled: true },
                          transferRawStocks: {
                            show: production?.type !== "RAW",
                            disabled: true,
                          },
                        }}
                      />
                    )}
                    {production?.status ===
                      productionStatusTypes.COMPLETED.value && (
                      <StockCategory
                        categoryId={category}
                        userRole={myProfile?.role || ""}
                        isRaw={production?.type === "RAW"}
                        locationId={productionLineId}
                        isVertical
                        fixedView
                        isFleet
                        stocks={production?.stocks}
                        locations={[
                          ...(location1688Locations?.map(
                            (location: ILocation1688) => ({
                              id: location.id,
                              name: location.name,
                              editable: true,
                              lastUpdate: moment(
                                location.lastStockUpdate
                              ).format("HH:mm DD/MM "),
                            })
                          ) || []),
                          {
                            id: productionLineId,
                            name: "Current Stock",
                            isFixed: true,
                          },
                        ]}
                        viewsSetting={{
                          stockCreated: { show: false, disabled: false },
                          requestedStock: {
                            show: true,
                            disabled: false,
                            title: "Distribution order",
                          },
                        }}
                      />
                    )}
                    {production?.status ===
                      productionStatusTypes.DISTRIBUTED.value && (
                      <StockCategory
                        categoryId={category}
                        userRole={myProfile?.role || ""}
                        isRaw={production?.type === "RAW"}
                        locationId={productionLineId}
                        isVertical
                        fixedView
                        isFleet
                        locations={[
                          ...(location1688Locations?.map(
                            (location: ILocation1688) => ({
                              id: location.id,
                              name: location.name,
                              editable: true,
                              lastUpdate: moment(
                                location.lastStockUpdate
                              ).format("HH:mm DD/MM "),
                            })
                          ) || []),
                          {
                            id: productionLineId,
                            name: "Current Stock",
                            isFixed: true,
                          },
                        ]}
                        viewsSetting={{
                          stockCreated: { show: false, disabled: true },
                          requestedStock: {
                            show: true,
                            disabled: true,
                            title: "Distribution order",
                          },
                        }}
                      />
                    )}
                  </>
                ),
              }))}
            />
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
