import { IVendor83788 } from "../../../common/types";
import {
  CREATE_VENDOR83788,
  UPDATE_VENDOR83788,
  DELETE_VENDOR83788,
  FETCH_VENDOR83788,
  LISTEN_TO_SELECTED_VENDOR83788,
  FILTER_VENDOR83788,
} from "./vendor83788Constants";

const initialState = {
  vendor83788: [],
  selectedVendor83788: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterVendor83788: {},
};

export default function vendor83788Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_VENDOR83788:
      return {
        ...state,
        vendor83788: [...state.vendor83788, payload],
      };
    case UPDATE_VENDOR83788:
      return {
        ...state,
        vendor83788: [
          ...state.vendor83788.filter(
            (evt: IVendor83788) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_VENDOR83788:
      return {
        ...state,
        vendor83788: [
          ...state.vendor83788.filter(
            (evt: IVendor83788) => evt.id !== payload
          ),
        ],
      };
    case FETCH_VENDOR83788:
      return {
        ...state,
        vendor83788: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_VENDOR83788:
      return {
        ...state,
        selectedVendor83788: payload,
      };
    case FILTER_VENDOR83788:
      return {
        ...state,
        filterVendor83788: payload || {},
      };

    default:
      return state;
  }
}
