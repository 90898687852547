/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchOrderSuggestion71819FromFirestore } from "../../../firestore/firestoreService";
import {
  listenToOrderSuggestion71819,
  listenToOrderSuggestion71819Filter,
} from "../../../redux/data/orderSuggestion71819/orderSuggestion71819Actions";
import { Button, DatePicker, Input } from "../../atoms";
const { RangePicker } = DatePicker;
export const OrderSuggestionListToolbar: React.FC = () => {
  const { filterOrderSuggestion71819 } = useSelector(
    (state: any) => state.orderSuggestion71819
  );
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = useParams();
  const productionLineId = params.productionLineId || "";
  useFirestoreCollection({
    query: () => fetchOrderSuggestion71819FromFirestore([]),
    data: listenToOrderSuggestion71819,
    deps: [dispatch],
  });
  const handelRestFilter = () => {
    dispatch(listenToOrderSuggestion71819Filter({}));
  };
  const handelOnSearch = (value: string) => {
    dispatch(
      listenToOrderSuggestion71819Filter({
        ...filterOrderSuggestion71819,
        orderId: [value],
      })
    );
  };
  const handelOnData = (value: any) => {
    console.log(value);
    dispatch(
      listenToOrderSuggestion71819Filter({
        ...filterOrderSuggestion71819,
        createAt: [value],
      })
    );
  };
  const handelAddRawOrder = () => {
    history(
      `/production-location/${productionLineId}/add-raw-suggestion-order`
    );
  };
  const handelAddOrder = () => {
    history(`/production-location/${productionLineId}/add-suggestion-order`);
  };
  return (
    <PageToolbar>
      <div className="page-toolbar-left-children-elements">
        {" "}
        <Button
          type="primary"
          onClick={handelAddRawOrder}
          block
          icon={<PlusOutlined />}
          className="page-toolbar-btn"
        >
          New raw suggestion order
        </Button>
        <Button
          type="primary"
          onClick={handelAddOrder}
          block
          icon={<PlusOutlined />}
          className="page-toolbar-btn"
        >
          New baked suggestion order
        </Button>
      </div>
      <div className="page-toolbar-right-children-elements">
        <Input.Search
          placeholder="Search Order"
          allowClear
          onSearch={handelOnSearch}
          style={{ width: 304 }}
        />
        <RangePicker
          allowClear
          onChange={handelOnData}
          style={{ width: 304 }}
        />
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          Reset Filters
        </Button>
      </div>
    </PageToolbar>
  );
};
