import firebase from "firebase/compat/app";
import { get } from "lodash";
import moment from "moment";
import { IAddress } from "../types";
export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getFileExtension = (filename: string) =>
  // eslint-disable-next-line no-bitwise
  filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);

export const getFlightDuration = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const duration = moment.utc(endDate.diff(startDate)).format("HH:mm");
  return duration;
};

export const getPackageDuration = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const duration1 = moment.duration(endDate.diff(startDate)).asDays();
  const duration = Math.round(duration1);
  return {
    nights: duration,
    days: duration + 1,
    daysNights: `${duration + 1} Days / ${duration} Nights `,
  };
};

export const formatReadableAddress = (
  address: IAddress | undefined
): string => {
  if (!address) return "";
  const streetLine1 = get(address, "streetLine1") || "";
  const streetLine2 = get(address, "streetLine2") || "";
  const city = get(address, "city.name") || "";
  const countryName = get(address, "country.name") || "";
  return [streetLine1, streetLine2, city, countryName]
    .filter((f) => !!f)
    .join(", ");
};

export const getYearMonthsOptions = () =>
  Array.apply(0, Array(12)).map((_, i) => ({
    label: moment().add(i, "month").format("MMMM - YYYY"),
    value: moment().add(i, "month").format("MM-YYYY"),
  }));

export const urlStringToOBject = (search: string) =>
  JSON.parse(
    `{"${decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`
  );

export const dateFormatFirestore = (data: any) => {
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      } else if (data[prop] === undefined) {
        data[prop] = "";
      }
    }
  }
  return data;
};

export const jccConvertAmount = (amount: number): string => {
  const purchaseAmt = `${amount}.00`;
  const purchaseAmtPadStart = purchaseAmt.padStart(13, "0");
  return purchaseAmtPadStart.replace(/[.]/g, "");
};

export const getInitialFormName = (nameString: string): string => {
  const fullName: string[] = nameString.split(" ");
  const initials =
    (fullName.shift()?.charAt(0) || "") + (fullName.pop()?.charAt(0) || "");
  return initials.toUpperCase();
};
