import { IUserInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_USER } from "./firebaseConstants";

export function fetchUserFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_USER);
  return collectionWithArgs(ref, args || []);
}
export function listenToUserFromFirestore(id: string) {
  return db.collection(COLLECTION_USER).doc(id);
}

export function addUserToFirestore(doc: IUserInput) {
  return db.collection(COLLECTION_USER).add({
    ...doc,
    createDate: new Date(),
  });
}

export function updateUserInFirestore(docId: string, doc: IUserInput | any) {
  return db.collection(COLLECTION_USER).doc(docId).update(doc);
}

export function activeToggleUserInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_USER).doc(docId).update({
    status,
  });
}

export function deleteUserInFirestore(docId: string) {
  return db.collection(COLLECTION_USER).doc(docId).delete();
}
