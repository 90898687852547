/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableProps } from "antd";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IUnit23243 } from "../../../common/types";
import { deleteUnit23243InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { listenToUnit23243Filter } from "../../../redux/data/unit23243/unit23243Actions";
import { InventoryWrapper } from "./Inventory.style";
import { UnitsListToolbar } from "./UnitsListToolbar";

export const Units: React.FC = () => {
  const { unit23243, filterUnit23243 } = useSelector(
    (state: any) => state.unit23243
  );
  const { loading } = useSelector((state: any) => state.async);
  const dispatch = useDispatch();
  const handelDeleteUnit23243 = async (id: string) => {
    await deleteUnit23243InFirestore(id);
  };
  const handelEditUnits = (record: IUnit23243) => () => {
    dispatch(
      openModal({
        modalType: "AddUnitModal",
        modalProps: { title: "Update Unit", record },
      })
    );
  };
  const columnsList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v: string) => v || "",
      filteredValue: filterUnit23243.name || null,
      sorter: (a: IUnit23243, b: IUnit23243) => a.name.localeCompare(b.name),
      onFilter: (value: any, record: IUnit23243) =>
        record.name.toLowerCase().indexOf(value.toLowerCase()) === 0,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IUnit23243) => (
        <ActionCell
          onDelete={() => handelDeleteUnit23243(record.id)}
          onEdit={handelEditUnits(record)}
        />
      ),
    },
  ];
  const handleChange: TableProps<IUnit23243>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    dispatch(listenToUnit23243Filter(filters));
  };
  return (
    <AdminPageBody pageToolbar={<UnitsListToolbar />}>
      <InventoryWrapper>
        <Table
          columns={columnsList}
          dataSource={unit23243}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
          onChange={handleChange}
        />
      </InventoryWrapper>
    </AdminPageBody>
  );
};
