import { IReturn5216Input, IQueryConfigArgs } from "../../common/types";
import {
  db,
  collectionWithArgs,
  setUserAddLog,
  setUserUpdateLog,
} from "./firestoreService";
import { COLLECTION_RETURN5216 } from "./firebaseConstants";

export async function getReturnIdFromFirestore() {
  try {
    const currentReturns = await db
      .collection(COLLECTION_RETURN5216)
      .orderBy("returnId", "desc")
      .limit(1)
      .get();
    const returned = currentReturns.docs[0]?.data();
    return returned ? Number(returned.returnId) + 1 : 1;
  } catch (err) {
    throw err;
  }
}

export function fetchReturn5216FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_RETURN5216);
  return collectionWithArgs(ref, args || []);
}
export function listenToReturn5216FromFirestore(id: string) {
  return db.collection(COLLECTION_RETURN5216).doc(id);
}

export async function addReturn5216ToFirestore(doc: IReturn5216Input) {
  const returnId = await getReturnIdFromFirestore();
  return db.collection(COLLECTION_RETURN5216).add({
    ...doc,
    ...setUserAddLog(),
    returnId,
  });
}

export function updateReturn5216InFirestore(
  docId: string,
  doc: IReturn5216Input | any
) {
  return db
    .collection(COLLECTION_RETURN5216)
    .doc(docId)
    .update({
      ...doc,
      ...setUserUpdateLog(),
    });
}

export function activeToggleReturn5216InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_RETURN5216).doc(docId).update({
    status,
  });
}

export function deleteReturn5216InFirestore(docId: string) {
  return db.collection(COLLECTION_RETURN5216).doc(docId).delete();
}
