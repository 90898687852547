/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IUser94013 } from "../../../common/types";
import { deleteUser94013InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { LocationWrapper } from "./Location.style";
import { UsersListToolbar } from "./LocationUserListToolbar";

export const LocationUsers: React.FC = () => {
  const { user94013 } = useSelector((state: any) => state.user94013);
  const { loading } = useSelector((state: any) => state.async);
  const history = useNavigate();
  const handelDeleteUser94013 = async (id: string) => {
    await deleteUser94013InFirestore(id);
  };
  const handelEditUsers = (id: string) => () => {
    history(`/user/${id}`);
  };
  const columnsList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v: string) => v || "",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (v: string) => v || "",
    },

    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (v: string) => v || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IUser94013) => (
        <ActionCell
          onDelete={() => handelDeleteUser94013(record.id)}
          onEdit={handelEditUsers(record.id)}
        />
      ),
    },
  ];
  return (
    <AdminPageBody pageToolbar={<UsersListToolbar />}>
      <LocationWrapper>
        <Table
          columns={columnsList}
          dataSource={user94013}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
        />
      </LocationWrapper>
    </AdminPageBody>
  );
};
