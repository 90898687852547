/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { find } from "lodash";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IGoodsReceived26115 } from "../../../common/types";
import { deleteGoodsReceived26115InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { ProductionLineWrapper } from "./ProductionLine.style";
import { GoodsReceivedListToolbar } from "./GoodsReceivedListToolbar";

export const GoodsReceived: React.FC = () => {
  const { goodsReceived26115 } = useSelector(
    (state: any) => state.goodsReceived26115
  );
  const { user94013 } = useSelector((state: any) => state.user94013);
  const { vendor83788 } = useSelector((state: any) => state.vendor83788);
  const { loading } = useSelector((state: any) => state.async);
  const history = useNavigate();
  const params = useParams();
  const handelDeleteGoodsReceived26115 = async (id: string) => {
    await deleteGoodsReceived26115InFirestore(id);
  };
  const productionLineId = params.productionLineId || "";
  const handelEditGoodsReceived = (id: string) => () => {
    history(`/production-line/${productionLineId}/goodsReceived/${id}`);
  };
  const columnsList = [
    {
      title: "Date",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "Vendor",
      dataIndex: "vendorId",
      key: "vendorId",
      render: (v: string) => find(vendor83788, ["id", v])?.name || "",
    },
    {
      title: "Doc. number",
      dataIndex: "documentNumber",
      key: "documentNumber",
      render: (v: string) => v || "",
    },
    {
      title: "Created by",
      dataIndex: "createdById",
      key: "createdById",
      render: (v: string) => find(user94013, ["id", v])?.name || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IGoodsReceived26115) => (
        <ActionCell
          onDelete={() => handelDeleteGoodsReceived26115(record.id)}
          onEdit={handelEditGoodsReceived(record.id)}
        />
      ),
    },
  ];
  return (
    <AdminPageBody pageToolbar={<GoodsReceivedListToolbar />}>
      <ProductionLineWrapper>
        <Table
          columns={columnsList}
          dataSource={goodsReceived26115}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
        />
      </ProductionLineWrapper>
    </AdminPageBody>
  );
};
