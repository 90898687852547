import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableProps } from "antd";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { ICategory59983 } from "../../../common/types";
import { deleteCategory59983InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { listenToCategory59983Filter } from "../../../redux/data/category59983/category59983Actions";
import { InventoryWrapper } from "./Inventory.style";
import { CategoriesListToolbar } from "./CategoriesListToolbar";

export const Categories: React.FC = () => {
  const { category59983, filterCategory59983 } = useSelector(
    (state: any) => state.category59983
  );
  const dispatch = useDispatch();
  const { loading } = useSelector((state: any) => state.async);
  const handelDeleteCategory59983 = async (id: string) => {
    await deleteCategory59983InFirestore(id);
  };
  const handelEditCategories = (record: ICategory59983) => () => {
    dispatch(
      openModal({
        modalType: "AddCategoryModal",
        modalProps: { title: "Update Category", record },
      })
    );
  };
  const columnsList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v: string) => v || "",
      filteredValue: filterCategory59983.name || null,
      sorter: (a: ICategory59983, b: ICategory59983) =>
        a.name.localeCompare(b.name),
      onFilter: (value: any, record: ICategory59983) =>
        record.name.toLowerCase().indexOf(value.toLowerCase()) === 0,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: ICategory59983) => (
        <ActionCell
          onDelete={() => handelDeleteCategory59983(record.id)}
          onEdit={handelEditCategories(record)}
        />
      ),
    },
  ];
  const handleChange: TableProps<ICategory59983>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    dispatch(listenToCategory59983Filter(filters));
  };
  return (
    <AdminPageBody pageToolbar={<CategoriesListToolbar />}>
      <InventoryWrapper>
        <Table
          columns={columnsList}
          dataSource={category59983}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
          onChange={handleChange}
        />
      </InventoryWrapper>
    </AdminPageBody>
  );
};
