import { Skeleton } from "antd";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import ForgetPasswordPage from "../../Domain/Auth/ForgetPasswordPage";
import SignInPage from "../../Domain/Auth/SignInPage";
import SignOutPage from "../../Domain/Auth/SignOutPage";
import DashboardPage from "../../Domain/Dashboard/DashboardPage";
import {
  Dashboard,
  // appendImportRouteHere
  CustomerReports,
  Vendors,
  Categories,
  Stock,
  ReturnLists,
  GoodsReceived,
  AddGoodsReceived,
  Users,
  LocationOrderDetails,
  AdminOrderDetails,
  ProductionOrderDetails,
  UpdateGoodsReceived,
  Recipes,
  Locations,
  Units,
  OrderList,
  Productions,
  ProductionLineOrders,
  Ingredients,
  MainDetails,
  Products,
  ProductionLineList,
  UserList,
  UserDetails,
  LocationOrderList,
  LocationOrders,
  LocationReturnList,
  LocationMainDetails,
  LocationUsers,
  // AddOrderLocation,
  ProductionLineUsers,
  GoodsReceivedDetails,
  ProductionOrderList,
  AddReturnLocation,
  AdminReturnDetails,
  ProductionReturnDetails,
  ReturnList,
  ProductionReturnList,
  LocationUpdateStock,
  AddProductionStock,
  UpdateProduction,
  ProductionLineSuggestionOrders,
  UpdateSuggestionOrder,
  LocationOrderHistoryList,
  FleetProductions,
  LocationStockHistoryView,
  ProductionBakerProductions,
} from "../components/organisms";
import SettingPage from "../../Domain/Setting/SettingPage";
// appendImportDomainRouteHere
import ProductionPage from "../../Domain/Production/ProductionPage";
import ReportsPage from "../../Domain/Reports/ReportsPage";
import ReturnsPage from "../../Domain/Returns/ReturnsPage";
import ProductionLinePage from "../../Domain/ProductionLine/ProductionLinePage";
import UsersPage from "../../Domain/Users/UsersPage";
import HomePage from "../../Domain/Home/HomePage";
import OrdersPage from "../../Domain/Orders/OrdersPage";
import ProductionLinesPage from "../../Domain/ProductionLines/ProductionLinesPage";
import OrderPage from "../../Domain/Order/OrderPage";
import LocationsPage from "../../Domain/Locations/LocationsPage";
import GoodsReceivedPage from "../../Domain/GoodsReceived/GoodsReceivedPage";
import InventoryPage from "../../Domain/Inventory/InventoryPage";
import FleetPage from "../../Domain/Fleet/FleetPage";
import ProductionBakerPage from "../../Domain/ProductionBaker/ProductionBaker";
import { useFirestoreCollection } from "../hooks/useFirestoreCollection";
import {
  fetchCategory59983FromFirestore,
  fetchIngredient56227FromFirestore,
  fetchLocation1688FromFirestore,
  fetchProduct2143FromFirestore,
  fetchUnit23243FromFirestore,
  fetchUser94013FromFirestore,
  fetchVendor83788FromFirestore,
} from "../firestore/firestoreService";
import { listenToCategory59983 } from "../redux/data/category59983/category59983Actions";
import { listenToUnit23243 } from "../redux/data/unit23243/unit23243Actions";
import LocationPage from "../../Domain/Location/LocationPage";
import { listenToUser94013 } from "../redux/data/user94013/user94013Actions";
import { listenToLocation1688 } from "../redux/data/location1688/location1688Actions";
import { listenToProduct2143 } from "../redux/data/product2143/product2143Actions";
import { listenToVendor83788 } from "../redux/data/vendor83788/vendor83788Actions";
import { listenToIngredient56227 } from "../redux/data/ingredient56227/ingredient56227Actions";
import { permissionTypes, productionTypes } from "../enums";
import ReturnPage from "../../Domain/Return/ReturnPage";
import ShopPage from "../../Domain/Shop/ShopPage";
import PrivateRoute from "./PrivateRoute";

interface IClickableProps {
  childProps: string;
  userRole: string;
}

const orderList = {
  [permissionTypes.ADMIN.value]: <OrderList />,
  [permissionTypes.PRODUCTION_MANAGER.value]: <ProductionOrderList />,
  [permissionTypes.LOCATION_MANAGER.value]: <LocationOrderList />,
};
const orderDetails = {
  [permissionTypes.ADMIN.value]: <AdminOrderDetails />,
  [permissionTypes.PRODUCTION_MANAGER.value]: <ProductionOrderDetails />,
  [permissionTypes.LOCATION_MANAGER.value]: <LocationOrderDetails />,
};
const returnDetails = {
  [permissionTypes.ADMIN.value]: <AdminReturnDetails />,
  [permissionTypes.PRODUCTION_MANAGER.value]: <ProductionReturnDetails />,
  [permissionTypes.LOCATION_MANAGER.value]: <AdminReturnDetails />,
};
const returnList = {
  [permissionTypes.ADMIN.value]: <ReturnList />,
  [permissionTypes.PRODUCTION_MANAGER.value]: <ProductionReturnList />,
  [permissionTypes.LOCATION_MANAGER.value]: <LocationReturnList />,
};
const AppRouter: React.FC<IClickableProps> = ({
  childProps,
  userRole,
}): JSX.Element => {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () =>
      fetchCategory59983FromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCategory59983,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchUnit23243FromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToUnit23243,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () => fetchUser94013FromFirestore([]),
    data: listenToUser94013,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () => fetchLocation1688FromFirestore([]),
    data: listenToLocation1688,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () => fetchProduct2143FromFirestore([]),
    data: listenToProduct2143,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () => fetchVendor83788FromFirestore([]),
    data: listenToVendor83788,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () => fetchIngredient56227FromFirestore([]),
    data: listenToIngredient56227,
    deps: [dispatch],
  });
  return (
    <Suspense fallback={<Skeleton paragraph active loading />}>
      <Routes>
        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-out" element={<SignOutPage />} />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        >
          <Route path="/" element={<Dashboard />} />
        </Route>
        <Route
          path="/setting"
          element={
            <PrivateRoute>
              <SettingPage />
            </PrivateRoute>
          }
        >
          {/* //appendSettingRouteHere */}
          <Route path="/setting/user" element={<UserList />} />
          <Route path="/setting/user/:userId" element={<UserDetails />} />
        </Route>
        {/* //appendMainRouteHere */}
        <Route
          path="/production-location/:productionLineId"
          element={
            <PrivateRoute>
              <ProductionPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/production-location/:productionLineId/productions"
            element={<Productions role={userRole} />}
          />
          <Route
            path="/production-location/:productionLineId/baked-stocks"
            element={<Stock productionType={productionTypes.BAKED.value} />}
          />
          <Route
            path="/production-location/:productionLineId/raw-stocks"
            element={<Stock productionType={productionTypes.RAW.value} />}
          />
          <Route
            path="/production-location/:productionLineId/inventory"
            element={<GoodsReceived />}
          />
          <Route
            path="/production-location/:productionLineId/orders"
            element={<ProductionOrderList />}
          />
          <Route
            path="/production-location/:productionLineId/order/:orderId"
            element={<ProductionOrderDetails />}
          />
          <Route
            path="/production-location/:productionLineId/returns"
            element={<ProductionReturnList />}
          />
          <Route
            path="/production-location/:productionLineId/add-production"
            element={
              <AddProductionStock
                productionType={productionTypes.BAKED.value}
              />
            }
          />
          <Route
            path="/production-location/:productionLineId/add-raw-production"
            element={
              <AddProductionStock productionType={productionTypes.RAW.value} />
            }
          />
          <Route
            path="/production-location/:productionLineId/production/:productionId"
            element={<UpdateProduction />}
          />
          <Route
            path="/production-location/:productionLineId/suggestion-orders"
            element={<ProductionLineSuggestionOrders />}
          />
          <Route
            path="/production-location/:productionLineId/suggestion-order/:suggestionOrderId"
            element={<UpdateSuggestionOrder />}
          />
        </Route>
        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <ReportsPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/reports/customerReports"
            element={<CustomerReports />}
          />
        </Route>
        <Route
          path="/returns"
          element={
            <PrivateRoute>
              <ReturnsPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/returns"
            element={get(returnList, userRole, <div></div>)}
          />
        </Route>
        <Route
          path="/production-line/:productionLineId"
          element={
            <PrivateRoute>
              <ProductionLinePage />
            </PrivateRoute>
          }
        >
          <Route
            path="/production-line/:productionLineId/baked-stocks"
            element={<Stock productionType={productionTypes.BAKED.value} />}
          />
          <Route
            path="/production-line/:productionLineId/raw-stocks"
            element={<Stock productionType={productionTypes.RAW.value} />}
          />
          <Route
            path="/production-line/:productionLineId/returnLists"
            element={<ReturnLists />}
          />
          <Route
            path="/production-line/:productionLineId/goodsReceived"
            element={<GoodsReceived />}
          />
          <Route
            path="/production-line/:productionLineId/addGoodsReceived"
            element={<GoodsReceivedDetails />}
          />
          <Route
            path="/production-line/:productionLineId/users"
            element={<ProductionLineUsers />}
          />
          <Route
            path="/production-line/:productionLineId/productions"
            element={<Productions role={userRole} />}
          />
          <Route
            path="/production-line/:productionLineId/orderLists"
            element={<ProductionLineOrders />}
          />
          <Route
            path="/production-line/:productionLineId/mainDetails"
            element={<MainDetails />}
          />
        </Route>
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <UsersPage />
            </PrivateRoute>
          }
        >
          <Route path="/users" element={<Users />} />
        </Route>
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        >
          <Route path="/home/dashboard" element={<Dashboard />} />
        </Route>
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <OrdersPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/orders"
            element={get(orderList, userRole, <div></div>)}
          />
        </Route>
        <Route
          path="/productionLines"
          element={
            <PrivateRoute>
              <ProductionLinesPage />
            </PrivateRoute>
          }
        >
          <Route path="/productionLines" element={<ProductionLineList />} />
        </Route>
        <Route
          path="/order"
          element={
            <PrivateRoute>
              <OrderPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/order/production-line/:productionLineId/order/:orderId"
            element={get(orderDetails, userRole, <div></div>)}
          />
          <Route
            path="/order/newOrder/:productionLineId/:locationId"
            element={<LocationUpdateStock productionType="" />}
          />
        </Route>
        <Route
          path="/return"
          element={
            <PrivateRoute>
              <ReturnPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/return/updateReturn/:returnId/:productionLineId"
            element={get(returnDetails, userRole, <div></div>)}
          />
          <Route
            path="/return/newReturn/:productionLineId/:locationId"
            element={<AddReturnLocation />}
          />
        </Route>
        <Route
          path="/locations"
          element={
            <PrivateRoute>
              <LocationsPage />
            </PrivateRoute>
          }
        >
          <Route path="/locations" element={<Locations />} />
        </Route>
        <Route
          path="/location/:locationId"
          element={
            <PrivateRoute>
              <LocationPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/location/:locationId/returnList"
            element={<LocationReturnList />}
          />
          <Route
            path="/location/:locationId/users"
            element={<LocationUsers />}
          />

          <Route
            path="/location/:locationId/orderList"
            element={<LocationOrders />}
          />
          <Route
            path="/location/:locationId/mainDetails"
            element={<LocationMainDetails />}
          />
        </Route>
        <Route
          path="/shop-location/:locationId"
          element={
            <PrivateRoute>
              <ShopPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/shop-location/:locationId/returns"
            element={<LocationReturnList />}
          />
          <Route
            path="/shop-location/:locationId/orders"
            element={<LocationOrders />}
          />
          <Route
            path="/shop-location/:locationId/production-line/:productionLineId/order/:orderId"
            element={<LocationOrderDetails />}
          />

          <Route
            path="/shop-location/:locationId/update-stock-history"
            element={<LocationOrderHistoryList />}
          />
          <Route
            path="/shop-location/:locationId/production-line/:productionLineId/stock-history/:stockHistoryId"
            element={<LocationStockHistoryView />}
          />
          <Route
            path="/shop-location/:locationId/update-stock/:productionLineId"
            element={
              <LocationUpdateStock
                productionType={
                  permissionTypes.LOCATION_BAKER.value === userRole
                    ? "RAW"
                    : "BAKED"
                }
              />
            }
          />
        </Route>
        <Route
          path="/goodsReceived"
          element={
            <PrivateRoute>
              <GoodsReceivedPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/goodsReceived/addGoodsReceived"
            element={<AddGoodsReceived />}
          />
          <Route
            path="/goodsReceived/updateGoodsReceived"
            element={<UpdateGoodsReceived />}
          />
        </Route>
        <Route
          path="/inventory"
          element={
            <PrivateRoute>
              <InventoryPage />
            </PrivateRoute>
          }
        >
          <Route path="/inventory/vendors" element={<Vendors />} />
          <Route path="/inventory/categories" element={<Categories />} />
          <Route path="/inventory/recipes" element={<Recipes />} />
          <Route path="/inventory/units" element={<Units />} />
          <Route path="/inventory/ingredients" element={<Ingredients />} />
          <Route path="/inventory/products" element={<Products />} />
        </Route>
        <Route
          path="/fleet"
          element={
            <PrivateRoute>
              <FleetPage />
            </PrivateRoute>
          }
        >
          <Route path="/fleet/productions" element={<FleetProductions />} />
          <Route
            path="/fleet/production/:productionId/production-location/:productionLineId"
            element={<UpdateProduction />}
          />

          <Route
            path="/fleet/production-line/:productionLineId/baked-order"
            element={
              <AddProductionStock
                productionType={productionTypes.BAKED.value}
                createOrders
              />
            }
          />
          <Route
            path="/fleet/production-line/:productionLineId/raw-order"
            element={
              <AddProductionStock
                productionType={productionTypes.RAW.value}
                createOrders
              />
            }
          />
        </Route>
        <Route
          path="/production-baker"
          element={
            <PrivateRoute>
              <ProductionBakerPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/production-baker/:productionLineId/productions"
            element={<ProductionBakerProductions />}
          />
          <Route
            path="/production-baker/:productionLineId/production/:productionId"
            element={<UpdateProduction />}
          />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>Theres nothing here!</p>
            </main>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
