/* eslint-disable no-console */
import { IRecipe75190 } from "../../../common/types";
import {
  UPDATE_RECIPE75190,
  DELETE_RECIPE75190,
  FETCH_RECIPE75190,
  LISTEN_TO_SELECTED_RECIPE75190,
} from "./recipe75190Constants";

export function listenToRecipe75190(recipe75190: IRecipe75190[]) {
  return {
    type: FETCH_RECIPE75190,
    payload: recipe75190,
  };
}

export function listenToSelectedRecipe75190(recipe75190: IRecipe75190) {
  return {
    type: LISTEN_TO_SELECTED_RECIPE75190,
    payload: recipe75190,
  };
}

export function updateRecipe75190(recipe75190: IRecipe75190) {
  return {
    type: UPDATE_RECIPE75190,
    payload: recipe75190,
  };
}

export function deleteRecipe75190(recipe75190Id: string) {
  return {
    type: DELETE_RECIPE75190,
    payload: recipe75190Id,
  };
}
