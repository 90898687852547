/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { IProduct2143 } from "../../../common/types";
import {
  UPDATE_PRODUCT2143,
  DELETE_PRODUCT2143,
  FETCH_PRODUCT2143,
  LISTEN_TO_SELECTED_PRODUCT2143,
  FILTER_PRODUCT21433,
} from "./product2143Constants";

export function listenToProduct2143(product2143: IProduct2143[]) {
  return {
    type: FETCH_PRODUCT2143,
    payload: product2143,
  };
}

export function listenToSelectedProduct2143(product2143: IProduct2143) {
  return {
    type: LISTEN_TO_SELECTED_PRODUCT2143,
    payload: product2143,
  };
}

export function updateProduct2143(product2143: IProduct2143) {
  return {
    type: UPDATE_PRODUCT2143,
    payload: product2143,
  };
}

export function deleteProduct2143(product2143Id: string) {
  return {
    type: DELETE_PRODUCT2143,
    payload: product2143Id,
  };
}

export function listenToProduct2143Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_PRODUCT21433,
    payload: filters,
  };
}
