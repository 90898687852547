/* eslint-disable no-console */
import { IUser } from "../../../common/types";
import {
  UPDATE_USER,
  DELETE_USER,
  FETCH_USERS,
  LISTEN_TO_SELECTED_USER,
} from "./userConstants";

export function listenToUsers(users: IUser[]) {
  return {
    type: FETCH_USERS,
    payload: users,
  };
}

export function listenToSelectedUser(user: IUser) {
  return {
    type: LISTEN_TO_SELECTED_USER,
    payload: user,
  };
}

export function updateUser(user: IUser) {
  return {
    type: UPDATE_USER,
    payload: user,
  };
}

export function deleteUser(userId: string) {
  return {
    type: DELETE_USER,
    payload: userId,
  };
}
