/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ProductionList } from "../../molecules";
import { FleetWrapper } from "./ProductionBaker.style";
import { ProductionBakerProductionsToolbar } from "./ProductionBakerProductionsToolbar";

export const ProductionBakerProductions: React.FC = () => {
  const history = useNavigate();
  const { production67969 } = useSelector(
    (state: any) => state.production67969
  );
  const handelEditProduction = (id: string, productionLineId: string) => () => {
    history(`/production-baker/${productionLineId}/production/${id}`);
  };
  return (
    <AdminPageBody pageToolbar={<ProductionBakerProductionsToolbar />}>
      <FleetWrapper>
        <ProductionList
          data={production67969}
          showPagination
          showProductionLine={false}
          showDeleteBtn={false}
          editProduction={handelEditProduction}
        />
      </FleetWrapper>
    </AdminPageBody>
  );
};
