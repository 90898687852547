/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { InputField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IUnit23243, IUnit23243Input } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import {
  addUnit23243ToFirestore,
  updateUnit23243InFirestore,
} from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";

interface IProps {
  record?: IUnit23243;
  title: string;
}
export const AddUnitModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IUnit23243Input = await form.validateFields();
      setLoading(true);
      if (props.record) {
        await updateUnit23243InFirestore(props.record.id, {
          name: values.name,
          status: true,
        });
      } else {
        await addUnit23243ToFirestore({
          name: values.name,
          status: true,
        });
      }

      setLoading(false);
      dispatch(closeModal());
      // history(`/setting/user/${user.id}`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={props.record || {}}
          >
            <InputField
              label="Name"
              name="name"
              rules={[{ required: true, message: "Missing Unit Name" }]}
              placeholder="Unit Name"
            />

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {props.title}
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
