import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const permissionTypes: ISelectOptionMap = {
  ADMIN: {
    label: "Admin",
    value: "ADMIN",
  },
  PRODUCTION_MANAGER: {
    label: "Production Manager",
    value: "PRODUCTION_MANAGER",
  },
  LOCATION_MANAGER: {
    label: "Location Manager",
    value: "LOCATION_MANAGER",
  },
  FLEET_MANAGER: {
    label: "Fleet Manager",
    value: "FLEET_MANAGER",
  },
  PRODUCTION_BAKER: {
    label: "Production baker",
    value: "PRODUCTION_BAKER",
  },
  LOCATION_BAKER: {
    label: "Location baker",
    value: "LOCATION_BAKER",
  },
};

export const permissionTypeOptions = values(permissionTypes);
