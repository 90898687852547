/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { IRecipe75190 } from "../../../common/types";
import { deleteRecipe75190InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { InventoryWrapper } from "./Inventory.style";
import { RecipesListToolbar } from "./RecipesListToolbar";

export const Recipes: React.FC = () => {
  const { recipe75190 } = useSelector((state: any) => state.recipe75190);
  const { loading } = useSelector((state: any) => state.async);
  const history = useNavigate();
  const handelDeleteRecipe75190 = async (id: string) => {
    await deleteRecipe75190InFirestore(id);
  };
  const handelEditRecipes = (id: string) => () => {
    history(`/inventory/recipes/${id}`);
  };
  const columnsList = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      render: (v: string) => v || "",
    },
    {
      title: "created by id",
      dataIndex: "createdbyid",
      key: "createdbyid",
      render: (v: string) => v || "",
    },
    {
      title: "ingredients",
      dataIndex: "ingredients",
      key: "ingredients",
      render: (v: string) => v || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IRecipe75190) => (
        <ActionCell
          onDelete={() => handelDeleteRecipe75190(record.id)}
          onEdit={handelEditRecipes(record.id)}
        />
      ),
    },
  ];
  return (
    <AdminPageBody pageToolbar={<RecipesListToolbar />}>
      <InventoryWrapper>
        <Table
          columns={columnsList}
          dataSource={recipe75190}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
        />
      </InventoryWrapper>
    </AdminPageBody>
  );
};
