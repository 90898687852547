/* eslint-disable no-console */
import { IStock16371Input, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_LOCATION1688,
  COLLECTION_STOCK16371,
} from "./firebaseConstants";

export function fetchStock16371FromFirestore(
  productionLineId: string,
  args?: IQueryConfigArgs[]
) {
  const ref = db
    .collection(COLLECTION_LOCATION1688)
    .doc(productionLineId)
    .collection(COLLECTION_STOCK16371);
  return collectionWithArgs(ref, args || []);
}

export function listenToStock16371FromFirestore(id: string) {
  return db.collection(COLLECTION_STOCK16371).doc(id);
}

export function addStock16371ToFirestore(doc: IStock16371Input) {
  return db.collection(COLLECTION_STOCK16371).add({
    ...doc,
    createDate: new Date(),
  });
}

export function updateStock16371InFirestore(
  docId: string,
  doc: IStock16371Input | any
) {
  return db.collection(COLLECTION_STOCK16371).doc(docId).update(doc);
}

export function activeToggleStock16371InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_STOCK16371).doc(docId).update({
    status,
  });
}

export function deleteStock16371InFirestore(docId: string) {
  return db.collection(COLLECTION_STOCK16371).doc(docId).delete();
}
