import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IProduction67969 } from "../../../common/types";
import { productionStatusTypes } from "../../../enums";
import { fetchProduction67969FromFirestore } from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { ProductionList } from "../../molecules";
import { LocationDashboardWrapper } from "./Home.style";

export const ProductionBakerDashboard: React.FC = () => {
  const [productions, setProductions] = React.useState<IProduction67969[]>([]);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const history = useNavigate();
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () =>
      fetchProduction67969FromFirestore([
        {
          type: "filter",
          name: "productionLineId",
          opr: "==",
          value: myProfile?.locationId,
        },
        {
          type: "filter",
          name: "status",
          opr: "==",
          value: productionStatusTypes.PENDING_PRODUCTION.value,
        },
      ]),
    data: setProductions,
    local: true,
    deps: [dispatch],
  });
  const handelEditProduction = (id: string, productionLineId: string) => () => {
    history(`/production-baker/${productionLineId}/production/${id}`);
  };
  return (
    <LocationDashboardWrapper>
      <div className="input-form-description text-center"></div>
      <ProductionList
        data={productions}
        showPagination={false}
        showProductionLine={false}
        showDeleteBtn={false}
        editProduction={handelEditProduction}
      />
    </LocationDashboardWrapper>
  );
};
