/* eslint-disable no-console */
import { get, orderBy } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { IProduct2143, IQueryConfigArgs } from "../../../common/types";
import { orderIssuesTypesOptions } from "../../../enums";
import { fetchProduct2143FromFirestore } from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { Form, Table } from "../../atoms";
import {
  InputNumberField,
  InputTextAreaField,
  SelectField,
} from "../commonFields";

interface IViewSetting {
  show: boolean;
  disabled: boolean;
}
interface IViewSettingMap {
  [key: string]: IViewSetting;
}
interface IProps {
  categoryId: string;
  userRole?: string;
  viewsSetting: IViewSettingMap;
  isRaw?: boolean;
  locationId?: string;
  stocks?: any;
}
const filter = (categoryId: string, isRaw?: boolean): IQueryConfigArgs[] => {
  if (isRaw) {
    return [
      {
        type: "filter",
        name: "categoryId",
        opr: "==",
        value: categoryId,
      },
      {
        type: "filter",
        name: "isRaw",
        opr: "==",
        value: true,
      },
    ];
  }
  return [
    {
      type: "filter",
      name: "categoryId",
      opr: "==",
      value: categoryId,
    },
  ];
};

export const OrderCategory: React.FC<IProps> = ({
  categoryId,
  userRole,
  viewsSetting,
  isRaw,
  stocks,
  locationId,
}) => {
  const [products, setProducts] = React.useState<IProduct2143[]>([]);
  const [productsSentOnly, setProductsSentOnly] = React.useState<
    IProduct2143[]
  >([]);
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchProduct2143FromFirestore(filter(categoryId, isRaw)),
    data: setProducts,
    local: true,
    deps: [dispatch, categoryId],
  });
  const columnsList: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v: string) => v || "",
    },
    viewsSetting.inventoryStock.show && {
      title: "Inventory stock",
      dataIndex: "stock",
      key: "stock",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "stock"]}
          min="0"
          placeholder="Stock"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.inventoryStock.disabled}
        />
      ),
    },
    viewsSetting.currentStock.show && {
      title: "Current stock",
      dataIndex: "currentStock",
      key: "currentStock",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "currentStock"]}
          min="0"
          placeholder="Current stock"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.currentStock.disabled}
        />
      ),
    },
    viewsSetting.request.show && {
      title: "Request",
      dataIndex: "requestedStock",
      key: "requestedStock",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "requestedStock"]}
          min="0"
          placeholder="Request"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.request.disabled}
        />
      ),
    },

    viewsSetting.productionStock?.show && {
      title: "current Stock",
      dataIndex: "productionStock",
      key: "productionStock",
      render: (v: string, record: IProduct2143) => {
        const stock = isRaw
          ? get(record, `stocks[${locationId}].rawStock`, 0)
          : get(record, `stocks[${locationId}].stock`, 0);
        form.setFieldValue([record.id, "productionStock"], +stock);
        return (
          <InputNumberField
            label=""
            name={[record.id, "productionStock"]}
            min="0"
            placeholder="current Stock"
            initialValue={+stock}
            style={{ width: "100%", marginBottom: 0 }}
            disabled={viewsSetting.productionStock.disabled}
          />
        );
      },
    },

    viewsSetting.qtySent.show && {
      title: "Qty sent",
      dataIndex: "qtySent",
      key: "qtySent",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "qtySent"]}
          min="0"
          placeholder="Qty sent"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.qtySent.disabled}
        />
      ),
    },
    viewsSetting.receivedStock.show && {
      title: "Received stock",
      dataIndex: "receivedStock",
      key: "receivedStock",
      render: (v: string, record: IProduct2143) => (
        <InputNumberField
          label=""
          name={[record.id, "receivedStock"]}
          min="0"
          placeholder="Received stock"
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.receivedStock.disabled}
        />
      ),
    },

    viewsSetting.comment.show && {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (v: string, record: IProduct2143) => (
        <InputTextAreaField
          label=""
          name={[record.id, "comment"]}
          placeholder="Comments"
          rows={1}
          style={{ width: "100%", marginBottom: 0 }}
          disabled={viewsSetting.comment.disabled}
        />
      ),
    },
    viewsSetting.issueComment.show && {
      title: "Received Comment ",
      dataIndex: "issueComment",
      key: "comment",
      render: (v: string, record: IProduct2143) => (
        <SelectField
          label=""
          name={[record.id, "issueComment"]}
          placeholder="Issues Comments"
          style={{ width: "100%", marginBottom: 0 }}
          options={orderIssuesTypesOptions}
          disabled={viewsSetting.issueComment.disabled}
          allowClear
        />
      ),
    },
  ].filter((v) => !!v);
  React.useEffect(() => {
    const productsWithData: IProduct2143[] = [];
    products.map((product) => {
      if (get(stocks[product.id], "qtySent", 0) > 0) {
        productsWithData.push(product);
      }
    });
    setProductsSentOnly(productsWithData);
  }, [products, categoryId]);
  return (
    <Table
      columns={columnsList}
      dataSource={orderBy(productsSentOnly, ["sorting"])}
      loading={false}
      rowKey="id"
      pagination={false}
    />
  );
};
