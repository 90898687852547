import { IStockHistory36376 } from "../../../common/types";
import {
  CREATE_STOCKHISTORY36376,
  UPDATE_STOCKHISTORY36376,
  DELETE_STOCKHISTORY36376,
  FETCH_STOCKHISTORY36376,
  LISTEN_TO_SELECTED_STOCKHISTORY36376,
  FILTER_STOCKHISTORY36376,
} from "./stockHistory36376Constants";

const initialState = {
  stockHistory36376: [],
  selectedStockHistory36376: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterStockHistory36376: {},
};

export default function stockHistory36376Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_STOCKHISTORY36376:
      return {
        ...state,
        stockHistory36376: [...state.stockHistory36376, payload],
      };
    case UPDATE_STOCKHISTORY36376:
      return {
        ...state,
        stockHistory36376: [
          ...state.stockHistory36376.filter(
            (evt: IStockHistory36376) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_STOCKHISTORY36376:
      return {
        ...state,
        stockHistory36376: [
          ...state.stockHistory36376.filter(
            (evt: IStockHistory36376) => evt.id !== payload
          ),
        ],
      };
    case FETCH_STOCKHISTORY36376:
      return {
        ...state,
        stockHistory36376: payload,
      };
    case LISTEN_TO_SELECTED_STOCKHISTORY36376:
      return {
        ...state,
        selectedSTOCKHISTORY36376: payload,
      };
    case FILTER_STOCKHISTORY36376:
      return {
        ...state,
        filterSTOCKHISTORY36376: payload || {},
      };
    default:
      return state;
  }
}
