/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchUser94013FromFirestore } from "../../../firestore/firestoreService";
import { listenToUser94013ProductionLines } from "../../../redux/data/user94013/user94013Actions";

export const ProductionLineUsersToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const productionLineId = params.productionLineId || "";
  useFirestoreCollection({
    query: () =>
      fetchUser94013FromFirestore([
        {
          type: "filter",
          name: "locationId",
          opr: "==",
          value: productionLineId,
        },
      ]),
    data: listenToUser94013ProductionLines,
    deps: [dispatch, productionLineId],
  });

  return <></>;
};
