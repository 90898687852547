/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import {
  CategorySelectedField,
  InputField,
  InputNumberField,
} from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IProduct2143, IProduct2143Input } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import {
  addProduct2143ToFirestore,
  updateProduct2143InFirestore,
} from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";
interface IProps {
  record?: IProduct2143;
  title: string;
}

export const AddProductModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IProduct2143Input = await form.validateFields();
      setLoading(true);
      if (props.record) {
        await updateProduct2143InFirestore(props.record.id, {
          name: values.name,
          status: true,
          categoryId: values.categoryId,
          sorting: values.sorting || 0,
        });
      } else {
        await addProduct2143ToFirestore({
          name: values.name,
          status: true,
          categoryId: values.categoryId,
          isRaw: false,
          stocks: {},
          sorting: values.sorting || 0,
        });
      }

      setLoading(false);
      dispatch(closeModal());
      // history(`/setting/Product/${Product.id}`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={props.record || {}}
          >
            <InputField
              label="Name"
              name="name"
              rules={[{ required: true, message: "Missing Product Name" }]}
              placeholder="Product Name"
            />
            <CategorySelectedField
              label="Category"
              name="categoryId"
              style={{ width: "100%" }}
              placeholder="Category"
            />
            <InputNumberField
              label="Sorting"
              name="sorting"
              min={0}
              rules={[{ required: true, message: "Missing Sorting" }]}
              placeholder="sorting"
            />
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {props.title}
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
