import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { find, forEach, isArray, mergeWith } from "lodash";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Form,
  InputFormWrapper,
} from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { OrderHeader, ReturnCategory } from "../../molecules";
import {
  fetchStock16371FromFirestore,
  listenToLocation1688FromFirestore,
  listenToReturn5216FromFirestore,
  updateReturn5216InFirestore,
} from "../../../firestore/firestoreService";
import { ILocation1688, IReturn5216Input } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedReturn5216 } from "../../../redux/data/return5216/return5216Actions";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { listenToStock16371 } from "../../../redux/data/stock16371/stock16371Actions";
import { returnTypes } from "../../../enums";

const merged = (map1: any, map2: any) =>
  mergeWith(map1, map2, (objValue, srcValue) => {
    if (isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  });

export const ProductionReturnDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [stocks, setStocks] = React.useState(false);
  const { stock16371 } = useSelector((state: any) => state.stock16371);
  const { category59983 } = useSelector((state: any) => state.category59983);
  const [loading, setLoading] = React.useState(false);

  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const [error, setError] = React.useState("");
  const params = useParams();
  const returnId = params.returnId || "";
  const productionLineId = params.productionLineId || "";
  const { selectedReturn5216 } = useSelector((state: any) => state.return5216);
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToReturn5216FromFirestore(returnId),
    data: listenToSelectedReturn5216,
    deps: [dispatch, returnId],
    shouldExecute: !!returnId,
  });
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });
  useFirestoreCollection({
    query: () => fetchStock16371FromFirestore(productionLineId, []),
    data: listenToStock16371,
    deps: [dispatch, productionLineId],
  });
  const handleSubmit = async () => {
    try {
      const values: IReturn5216Input = await form.validateFields();
      forEach(values.stocks, (value, key: string) => {
        values.stocks[key] = {
          ...value,
          wasteStock: +value.wasteStock || 0,
          backToShop: +value.backToShop || 0,
          reproduction: +value.reproduction || 0,
          charityStock: +value.charityStock || 0,
        };
      });
      setLoading(true);
      await updateReturn5216InFirestore(returnId, {
        status: returnTypes.ACCEPTED.value,
        stocks: { ...values.stocks },
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => {
    setStocks(merged(selectedReturn5216?.stocks || {}, stock16371 || {}));
  }, [selectedReturn5216?.stocks, returnId, stock16371]);
  React.useEffect(
    () => form.resetFields,
    [selectedReturn5216, returnId, stocks]
  );
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={{
              ...selectedReturn5216,
              stocks,
            }}
          >
            {selectedReturn5216 && (
              <OrderHeader
                order={selectedReturn5216}
                productionLine={productionLine}
              />
            )}
            <Form.List name="stocks">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Collapse
                    defaultActiveKey={[...(productionLine?.categories || [])]}
                  >
                    {productionLine?.categories?.map((category) => (
                      <Collapse.Panel
                        header={
                          find(category59983, ["id", category])?.name || ""
                        }
                        key={category}
                      >
                        <ReturnCategory
                          categoryId={category}
                          viewsSetting={{
                            returnStock: { show: true, disabled: true },
                            wasteStock: { show: true, disabled: false },
                            backToShop: { show: true, disabled: false },
                            reproduction: { show: true, disabled: false },
                            charityStock: { show: true, disabled: false },
                            comment: { show: true, disabled: true },
                          }}
                        />
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </>
              )}
            </Form.List>

            <Divider></Divider>
            {selectedReturn5216?.status === returnTypes.PENDING.value && (
              <Button
                type="primary"
                className="input-form-btn"
                onClick={handleSubmit}
                loading={loading}
              >
                Accept Return
              </Button>
            )}
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
