import { IGoodsReceived26115Input, IQueryConfigArgs } from "../../common/types";
import {
  db,
  collectionWithArgs,
  setUserAddLog,
  setUserUpdateLog,
} from "./firestoreService";
import { COLLECTION_GOODSRECEIVED26115 } from "./firebaseConstants";

export function fetchGoodsReceived26115FromFirestore(
  args?: IQueryConfigArgs[]
) {
  const ref = db.collection(COLLECTION_GOODSRECEIVED26115);
  return collectionWithArgs(ref, args || []);
}
export function listenToGoodsReceived26115FromFirestore(id: string) {
  return db.collection(COLLECTION_GOODSRECEIVED26115).doc(id);
}

export function addGoodsReceived26115ToFirestore(
  doc: IGoodsReceived26115Input
) {
  return db.collection(COLLECTION_GOODSRECEIVED26115).add({
    ...doc,
    ...setUserAddLog(),
  });
}

export function updateGoodsReceived26115InFirestore(
  docId: string,
  doc: IGoodsReceived26115Input | any
) {
  return db
    .collection(COLLECTION_GOODSRECEIVED26115)
    .doc(docId)
    .update({
      ...doc,
      ...setUserUpdateLog(),
    });
}

export function activeToggleGoodsReceived26115InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_GOODSRECEIVED26115).doc(docId).update({
    status,
  });
}

export function deleteGoodsReceived26115InFirestore(docId: string) {
  return db.collection(COLLECTION_GOODSRECEIVED26115).doc(docId).delete();
}
