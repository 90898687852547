/* eslint-disable no-console */
import { find } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Tag } from "../../App/components/atoms";
import { listenToLocation1688FromFirestore } from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToSelectedLocation1688 } from "../../App/redux/data/location1688/location1688Actions";

const currentMenu = (id: string) => [
  // appendRouteHere
  {
    key: "mainDetails",
    label: "Main details",
    link: `/location/${id}/mainDetails`,
  },
  {
    key: "users",
    label: "Users",
    link: `/location/${id}/users`,
  },
  {
    key: "orderLists",
    label: "Order list",
    link: `/location/${id}/orderList`,
  },
  {
    key: "returnLists",
    label: "Return lists",
    link: `/location/${id}/returnList`,
  },
];
const currentBreadcrumb = (name: string) => [
  {
    key: "page",
    label: name,
    link: "#",
  },
];

const LocationPage: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [pageName, setPageName] = React.useState<string>("");
  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  const locationId = params.locationId || "";
  const headerMenu = currentMenu(locationId);
  const { selectedLocation1688 } = useSelector(
    (state: any) => state.location1688
  );
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(locationId),
    data: listenToSelectedLocation1688,
    deps: [dispatch, locationId],
  });

  React.useEffect(() => {
    const name = find(headerMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(name?.label || "") || []);
    setPageName(name?.label || "");
  }, [location.pathname]);
  console.log(selectedLocation1688, locationId);
  return (
    <AdminPage
      title={
        <div className="admin-page-title">
          Location: {selectedLocation1688?.name} <Tag>{pageName}</Tag>
        </div>
      }
      headerMenu={headerMenu}
      breadcrumb={breadcrumb}
    >
      <Outlet />
    </AdminPage>
  );
};

export default LocationPage;
