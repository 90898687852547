import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IOrder50453 } from "../../../common/types";
import { fetchOrder50453FromFirestore } from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { OrderListMole } from "../../molecules";
import { LocationDashboardWrapper } from "./Home.style";

const startOfDay = new Date();
startOfDay.setHours(0, 0, 0, 0);
export const AdminDashboard: React.FC = () => {
  const [orders, setOrders] = React.useState<IOrder50453[]>([]);
  const history = useNavigate();
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () =>
      fetchOrder50453FromFirestore([
        {
          type: "filter",
          name: "createAt",
          opr: ">=",
          value: startOfDay,
        },
      ]),
    data: setOrders,
    local: true,
    deps: [dispatch],
  });
  const handelEditOrder = (id: string, productionLineId: string) => () => {
    history(`/order/production-line/${productionLineId}/order/${id}`);
  };
  return (
    <LocationDashboardWrapper>
      <div className="input-form-description text-center">Today Orders</div>
      <OrderListMole
        data={orders}
        showPagination={false}
        showLocation
        showProduction
        showDeleteBtn={false}
        editOrder={handelEditOrder}
      />
    </LocationDashboardWrapper>
  );
};
