import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Loader } from "../components/molecules";
import ModalManager from "../common/modals/ModalManager";
import AppRouter from "./AppRouter";
import { AppWrapper } from "./App.style";

const App: React.FC = () => {
  const { initialized } = useSelector((state: any) => state.async);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  if (!initialized) return <Loader color="red" />;
  return (
    <BrowserRouter>
      <ModalManager />
      <AppWrapper>
        <div className="app-body">
          <AppRouter childProps="fdfd" userRole={myProfile?.role}></AppRouter>
        </div>
      </AppWrapper>
    </BrowserRouter>
  );
};

export default App;
