import styled from "styled-components";
export const HomeWrapper = styled.div`
  background-color: #fff;
  width: 100%;
`;

export const LocationDashboardWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  .LocationDashboard {
    &-orders {
      margin: 30px;
      font-size: 16px;
      &-item {
        display: flex;
        gap: 30px;
      }
      &-btn {
        width: 500px;
        height: auto;
        background-color: #971203;
        border-color: #971203;
        white-space: normal;
        padding: 10px 20px;
        color: #fff;
        border-radius: 5px;
        font-size: 16px;
      }
      &-name {
        font-size: 18px;
        width: 100%;
        border-top: 1px solid #fff;
        margin-top: 10px;
        padding: 10px;
      }
    }
    &-returns {
      &-btn {
        width: 500px;
        height: auto;
        background-color: #ffd420;
        border-color: #ffd420;
        white-space: normal;
        padding: 10px 20px;
        color: #000;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 20px;
      }
    }
  }
`;
