/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { IIngredient56227 } from "../../../common/types";
import {
  UPDATE_INGREDIENT56227,
  DELETE_INGREDIENT56227,
  FETCH_INGREDIENT56227,
  LISTEN_TO_SELECTED_INGREDIENT56227,
  FILTER_INGREDIENT56227,
} from "./ingredient56227Constants";

export function listenToIngredient56227(ingredient56227: IIngredient56227[]) {
  return {
    type: FETCH_INGREDIENT56227,
    payload: ingredient56227,
  };
}

export function listenToSelectedIngredient56227(
  ingredient56227: IIngredient56227
) {
  return {
    type: LISTEN_TO_SELECTED_INGREDIENT56227,
    payload: ingredient56227,
  };
}

export function updateIngredient56227(ingredient56227: IIngredient56227) {
  return {
    type: UPDATE_INGREDIENT56227,
    payload: ingredient56227,
  };
}

export function deleteIngredient56227(ingredient56227Id: string) {
  return {
    type: DELETE_INGREDIENT56227,
    payload: ingredient56227Id,
  };
}

export function listenToIngredient56227Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_INGREDIENT56227,
    payload: filters,
  };
}
