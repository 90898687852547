/* eslint-disable no-console */
import React from "react";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { find, get, orderBy } from "lodash";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { InputFormWrapper } from "../../atoms";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { Button } from "../../atoms/Button/Button";
import {
  fetchProduct2143FromFirestore,
  listenToLocation1688FromFirestore,
  listenToOrder50453FromFirestore,
} from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { IProduct2143, IQueryConfigArgs } from "../../../common/types";

const filter = (categoryIds: string[], isRaw?: boolean): IQueryConfigArgs[] => {
  if (isRaw) {
    return [
      {
        type: "filter",
        name: "categoryId",
        opr: "in",
        value: categoryIds,
      },
      {
        type: "filter",
        name: "isRaw",
        opr: "==",
        value: true,
      },
    ];
  }
  return [
    {
      type: "filter",
      name: "categoryId",
      opr: "in",
      value: categoryIds,
    },
  ];
};
function exportToExcel(data: any, fileName: string) {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Create a buffer
  const buf = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });

  // Create a Blob
  const blob = new Blob([buf], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a link element and download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.xlsx`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

interface IProps {
  orderId: string;
  productionLineId: string;
}

export const OrderExclModal: React.FC<IProps> = ({
  orderId,
  productionLineId,
  ...props
}) => {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState<any>();
  const [products, setProducts] = React.useState([]);
  const [productionLine, setProductionLine] = React.useState<any>();
  const [productsSentOnly, setProductsSentOnly] = React.useState<
    IProduct2143[]
  >([]);
  const { category59983 } = useSelector((state: any) => state.category59983);
  userFirestoreDoc({
    query: () => listenToOrder50453FromFirestore(orderId),
    data: setOrder,
    deps: [dispatch, orderId],
    local: true,
    shouldExecute: !!orderId,
  });
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });
  useFirestoreCollection({
    query: () =>
      fetchProduct2143FromFirestore(filter(productionLine?.categories, false)),
    data: setProducts,
    local: true,
    shouldExecute: !!productionLine?.categories,
    deps: [dispatch, productionLine?.categories],
  });

  const handleExport = () => {
    exportToExcel(
      orderBy(productsSentOnly, ["Category"]),
      `production-${orderId}`
    );
  };

  React.useEffect(() => {
    const productsWithData: any[] = [];
    products.map((product: any) => {
      if (get(order?.stocks[product.id], "qtySent", 0) > 0) {
        productsWithData.push({
          "Order Id": order.orderId,
          Category: find(category59983, ["id", product.categoryId])?.name || "",
          "Product Name": product.name,
          "Current Stock": get(product?.stocks[productionLineId], "stock"),
          "Quantity Sent": get(order?.stocks[product.id], "qtySent"),
        });
      }
    });
    setProductsSentOnly(productsWithData);
  }, [products, orderId]);

  console.log({ productionLine, order, products, productsSentOnly });
  return (
    <ModalWrapper {...props} footer={null}>
      <InputFormWrapper>
        <Button type="primary" onClick={handleExport}>
          Download
        </Button>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
