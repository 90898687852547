import React from "react";
import { useSelector } from "react-redux";
import {
  AddUserModal,
  // appendModalHere
  AddVendorModal,
  AddCategoryModal,
  AddProductModal,
  AddUnitModal,
  AddIngredientModal,
  AddProductionLineModal,
  AddLocationModal,
  OrderExclModal,
} from "../../components/organisms";
export default function ModalManager() {
  const modalLookup: any = {
    AddUserModal,
    // appendModalLookupHere
    AddVendorModal,
    AddCategoryModal,
    AddProductModal,
    AddUnitModal,
    AddIngredientModal,
    AddProductionLineModal,
    AddLocationModal,
    OrderExclModal,
  };
  const currentModal = useSelector((state: any) => state.modals);
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
