import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionCell } from "..";
import { IUser } from "../../../common/types";
import { deleteUserInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { UserWrapper } from "./User.style";

interface IUserListProps {
  users: IUser[];
  loading: boolean;
}
export const UserList: React.FC<IUserListProps> = ({ users, loading }) => {
  const history = useNavigate();
  const handelDeleteUser = async (id: string) => {
    await deleteUserInFirestore(id);
  };
  const handelEditUser = (id: string) => () => {
    history(`/setting/user/${id}`);
  };
  const columnsList = [
    {
      title: "User name",
      dataIndex: "name",
      key: "name",
      fixed: true,
      render: (v: string, record: IUser) => v,
    },
    {
      title: "Code",
      dataIndex: "userCode",
      key: "userCode",
      render: (v: string) => v || "",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (v: string) => v || "",
    },
    {
      title: "Phone2",
      dataIndex: "phoneNumber2",
      key: "phoneNumber2",
      render: (v: string) => v || "",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (v: string) => v || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IUser) => (
        <ActionCell
          onDelete={() => handelDeleteUser(record.id)}
          onEdit={handelEditUser(record.id)}
        />
      ),
    },
  ];
  return (
    <UserWrapper>
      <Table
        columns={columnsList}
        dataSource={users}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
      />
    </UserWrapper>
  );
};
