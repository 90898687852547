import { IReturnStock78285 } from "../../../common/types";
import {
  CREATE_RETURNSTOCK78285,
  UPDATE_RETURNSTOCK78285,
  DELETE_RETURNSTOCK78285,
  FETCH_RETURNSTOCK78285,
  LISTEN_TO_SELECTED_RETURNSTOCK78285,
} from "./returnStock78285Constants";

const initialState = {
  returnStock78285: [],
  selectedReturnStock78285: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function returnStock78285Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_RETURNSTOCK78285:
      return {
        ...state,
        returnStock78285: [...state.returnStock78285, payload],
      };
    case UPDATE_RETURNSTOCK78285:
      return {
        ...state,
        returnStock78285: [
          ...state.returnStock78285.filter(
            (evt: IReturnStock78285) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_RETURNSTOCK78285:
      return {
        ...state,
        returnStock78285: [
          ...state.returnStock78285.filter(
            (evt: IReturnStock78285) => evt.id !== payload
          ),
        ],
      };
    case FETCH_RETURNSTOCK78285:
      return {
        ...state,
        returnStock78285: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_RETURNSTOCK78285:
      return {
        ...state,
        selectedreturnStock78285: payload,
      };

    default:
      return state;
  }
}
