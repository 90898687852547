/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { find } from "lodash";
import { TableProps } from "antd";
import { ActionCell } from "../../molecules";
import { ILocation1688, IReturn5216, IUser94013 } from "../../../common/types";
import { deleteReturn5216InFirestore } from "../../../firestore/firestoreService";
import { Table, Tag } from "../../atoms";
import { returnTypeOptions, returnTypes } from "../../../enums";
import { listenToReturn5216Filter } from "../../../redux/data/return5216/return5216Actions";

interface IProps {
  data: IReturn5216[];
  showPagination: boolean;
  showLocation: boolean;
  showProduction: boolean;
  showDeleteBtn: boolean;
}

export const ReturnListMole: React.FC<IProps> = ({
  data,
  showPagination,
  showLocation,
  showProduction,
  showDeleteBtn,
}) => {
  const dispatch = useDispatch();
  const { user94013 } = useSelector((state: any) => state.user94013);
  const { filterReturn5216 } = useSelector((state: any) => state.return5216);
  const { location1688 } = useSelector((state: any) => state.location1688);
  const { loading } = useSelector((state: any) => state.async);
  const history = useNavigate();
  const handelDeleteReturn5216 = async (id: string) => {
    await deleteReturn5216InFirestore(id);
  };
  const handelEditReturnList = (id: string, productionLineId: string) => () => {
    history(`/return/updateReturn/${id}/${productionLineId}`);
  };
  const columnsList: any = [
    {
      title: "Return id",
      dataIndex: "returnId",
      key: "returnId",
      filteredValue: filterReturn5216.returnId || null,
      render: (v: string, record: IReturn5216) =>
        `R-${record?.locationCode || ""}-${v}` || "",
      sorter: (a: IReturn5216, b: IReturn5216) => +a.returnId - +b.returnId,
      onFilter: (value: any, record: IReturn5216) =>
        +record.returnId === +value,
    },
    {
      title: "Date",
      dataIndex: "createAt",
      key: "createAt",
      filteredValue: filterReturn5216.createAt || null,
      render: (v: string) => moment(v).format("DD/MM/YYYY"),
      onFilter: (value: any, record: IReturn5216) => {
        const [weekStart, weekEnd] = value.split(",") || [];
        console.log(weekStart, weekEnd, value);
        return moment(record.createAt).isBetween(weekStart, weekEnd);
      },
    },
    {
      title: "Time",
      dataIndex: "createAt",
      key: "createAt",
      render: (v: string) => moment(v).format("HH:mm"),
    },
    {
      title: "Submitted by",
      dataIndex: "createdById",
      key: "createdById",
      filterSearch: true,
      filteredValue: filterReturn5216.createdById || null,
      render: (v: string) => find(user94013, ["id", v])?.name || "",
      filters: user94013.map((user: IUser94013) => ({
        text: user.name,
        value: user.id,
      })),
      onFilter: (value: any, record: IReturn5216) =>
        record?.createdById?.indexOf(value) === 0,
    },
    showLocation && {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      filterSearch: true,
      filteredValue: filterReturn5216.locationId || null,
      render: (v: string) => find(location1688, ["id", v])?.name || "",
      filters: location1688.map((location: ILocation1688) => ({
        text: location.name,
        value: location.id,
      })),
      onFilter: (value: any, record: IReturn5216) =>
        record?.locationId?.indexOf(value) === 0,
    },
    showProduction && {
      title: "Production Line",
      dataIndex: "productionLineId",
      key: "productionLineId",
      filterSearch: true,
      filteredValue: filterReturn5216.productionLineId || null,
      render: (v: string) => find(location1688, ["id", v])?.name || "",
      filters: location1688.map((location: ILocation1688) => ({
        text: location.name,
        value: location.id,
      })),
      onFilter: (value: any, record: IReturn5216) =>
        record?.productionLineId?.indexOf(value) === 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: string) => (
        <Tag color={returnTypes[v].color}>{returnTypes[v].label}</Tag>
      ),
      filteredValue: filterReturn5216.status || null,
      filters: returnTypeOptions.map((returned: any) => ({
        text: returned.label,
        value: returned.value,
      })),
      onFilter: (value: any, record: IReturn5216) =>
        record?.status?.indexOf(value) === 0,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IReturn5216) => (
        <ActionCell
          onDelete={
            showDeleteBtn ? () => handelDeleteReturn5216(record.id) : undefined
          }
          onEdit={handelEditReturnList(
            record.id,
            record?.productionLineId || ""
          )}
        />
      ),
    },
  ].filter((v) => !!v);
  const handleChange: TableProps<IReturn5216>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    dispatch(listenToReturn5216Filter(filters));
  };
  return (
    <Table
      columns={columnsList}
      dataSource={data}
      loading={loading}
      rowKey="id"
      pagination={
        showPagination && {
          pageSize: 20,
        }
      }
      onChange={handleChange}
      onRow={(record, rowIndex) => ({
        onDoubleClick: handelEditReturnList(
          record.id,
          record?.productionLineId || ""
        ),
        // double click row
      })}
    />
  );
};
