import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const orderIssuesTypes: ISelectOptionMap = {
  WRONG_STOCK: {
    label: "Wrong stock",
    value: "WRONG_STOCK",
  },
  DAMAGED_GOODS: {
    label: "Damaged goods",
    value: "DAMAGED_GOODS",
  },
  WRONG_ORDER: {
    label: "Wrong order",
    value: "WRONG_ORDER",
  },
  OTHER: {
    label: "Other",
    value: "OTHER",
  },
};

export const orderIssuesTypesOptions = values(orderIssuesTypes);
