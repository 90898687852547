import { List } from "antd";
import { find, orderBy } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ILocation1688, IOrder50453 } from "../../../common/types";
import { orderTypes } from "../../../enums";
import {
  fetchLocation1688FromFirestore,
  fetchOrder50453FromFirestore,
} from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { Button, Divider } from "../../atoms";
import { OrderListMole } from "../../molecules";
import { LocationDashboardWrapper } from "./Home.style";

interface IProps {
  locationId: string;
  productionType: string;
}

export const LocationDashboard: React.FC<IProps> = ({
  locationId,
  productionType,
}) => {
  const [productionLines, setProductionLines] = React.useState<ILocation1688[]>(
    []
  );
  const dispatch = useDispatch();
  const history = useNavigate();
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const { category59983 } = useSelector((state: any) => state.category59983);
  const [orders, setOrders] = React.useState<IOrder50453[]>([]);
  useFirestoreCollection({
    query: () =>
      fetchOrder50453FromFirestore([
        {
          type: "filter",
          name: "locationId",
          opr: "==",
          value: myProfile?.locationId,
        },
        {
          type: "filter",
          name: "type",
          opr: "==",
          value: productionType,
        },
        {
          type: "filter",
          name: "status",
          opr: "==",
          value: orderTypes.SHIPPED.value,
        },
      ]),
    data: setOrders,
    local: true,
    deps: [dispatch, myProfile?.locationId, productionType],
  });
  useFirestoreCollection({
    query: () =>
      fetchLocation1688FromFirestore([
        {
          type: "filter",
          name: "type",
          opr: "==",
          value: "PRODUCTION_LINE",
        },
      ]),
    data: setProductionLines,
    local: true,
    deps: [dispatch],
  });
  const handleCreateNewOrder = (id: string) => () => {
    history(`/shop-location/${locationId}/update-stock/${id}`);
  };
  const handleCreateNewReturn = (id: string) => () => {
    history(`/return/newReturn/${id}/${locationId}`);
  };
  const handelEditOrder = (id: string, productionLineId: string) => () => {
    history(
      `/shop-location/${locationId}/production-line/${productionLineId}/order/${id}`
    );
  };
  return (
    <LocationDashboardWrapper>
      <OrderListMole
        data={orders}
        showPagination={false}
        showLocation={false}
        showProduction
        showDeleteBtn={false}
        editOrder={handelEditOrder}
      />
      <Divider> Update Location Stocks</Divider>
      <List
        size="large"
        bordered
        className="LocationDashboard-orders"
        dataSource={orderBy(productionLines, ["sorting"])}
        renderItem={(item: ILocation1688) => (
          <List.Item className="LocationDashboard-orders-item">
            <div>
              <Button
                onClick={handleCreateNewOrder(item.id)}
                className="LocationDashboard-orders-btn"
              >
                {item.categories
                  ?.map(
                    (category) => find(category59983, ["id", category])?.name
                  )
                  .join(" / ") || ""}
                <div className="LocationDashboard-orders-name">
                  {item.name || ""}
                </div>
              </Button>{" "}
              <Button
                onClick={handleCreateNewReturn(item.id)}
                className="LocationDashboard-returns-btn"
              >
                {item.name || ""} Return
              </Button>
            </div>
            <div>{item.orderingSchedule || ""}</div>
          </List.Item>
        )}
      />
    </LocationDashboardWrapper>
  );
};
