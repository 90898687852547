/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import {
  InputField,
  LocationSelectedField,
  SelectField,
} from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IUser94013, IUser94013Input } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import {
  addUser94013ToFirestore,
  updateUser94013InFirestore,
} from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { permissionTypeOptions } from "../../../enums/permissionType";

interface IProps {
  record?: IUser94013;
  title: string;
}
export const AddUserModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IUser94013Input = await form.validateFields();
      setLoading(true);
      if (props.record) {
        await updateUser94013InFirestore(props.record.id, {
          name: values.name,
          locationId: values.locationId,
          role: values.role,
        });
      } else {
        await addUser94013ToFirestore({
          name: values.name,
          status: true,
          locationId: values.locationId,
          email: values.email,
          role: values.role,
          password: values.password,
        });
      }

      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={props.record || {}}
          >
            <InputField
              label="User Name"
              name="name"
              rules={[{ required: true, message: "Missing User Name" }]}
              placeholder="User Name"
            />
            {!props.record && (
              <InputField
                label="Email"
                name="email"
                rules={[{ required: true, message: "Missing Email" }]}
                placeholder="Email"
              />
            )}
            {!props.record && (
              <InputField
                label="Password"
                name="password"
                rules={[{ required: true, message: "Missing password" }]}
                placeholder="Password"
              />
            )}
            {!props.record && (
              <InputField
                label="re Password"
                name="re-password"
                rules={[{ required: true, message: "Missing password" }]}
                placeholder="Re-Password"
              />
            )}
            <SelectField
              label="Role"
              name="role"
              rules={[{ required: true, message: "Missing Role" }]}
              placeholder="Role"
              options={permissionTypeOptions}
            />
            <LocationSelectedField
              label="Location"
              name="locationId"
              rules={[{ required: true, message: "Missing Location" }]}
              placeholder="Location"
            />
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {props.title}
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
