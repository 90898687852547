import { IStockHistory36376Input, IQueryConfigArgs } from "../../common/types";
import {
  db,
  collectionWithArgs,
  setUserAddLog,
  setUserUpdateLog,
} from "./firestoreService";
import { COLLECTION_STOCKHISTORY36376 } from "./firebaseConstants";

export function fetchStockHistory36376FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_STOCKHISTORY36376);
  return collectionWithArgs(ref, args || []);
}
export function listenToStockHistory36376FromFirestore(id: string) {
  return db.collection(COLLECTION_STOCKHISTORY36376).doc(id);
}

export function addStockHistory36376ToFirestore(doc: IStockHistory36376Input) {
  return db.collection(COLLECTION_STOCKHISTORY36376).add({
    ...doc,
    ...setUserAddLog(),
  });
}

export function updateStockHistory36376InFirestore(
  docId: string,
  doc: IStockHistory36376Input | any
) {
  return db
    .collection(COLLECTION_STOCKHISTORY36376)
    .doc(docId)
    .update({
      ...doc,
      ...setUserUpdateLog(),
    });
}

export function activeToggleStockHistory36376InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_STOCKHISTORY36376).doc(docId).update({
    status,
  });
}

export function deleteStockHistory36376InFirestore(docId: string) {
  return db.collection(COLLECTION_STOCKHISTORY36376).doc(docId).delete();
}
