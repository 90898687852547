import { find } from "lodash";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";

const currentMenu = [
  // appendRouteHere
  {
    key: "home",
    label: "Home",
    link: "/",
  },
  {
    key: "productions",
    label: "Productions",
    link: `/fleet/productions`,
  },
];
const currentBreadcrumb = (name: string) => [
  {
    key: "page",
    label: name,
    link: "#",
  },
];

const FleetPage: React.FC = () => {
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  const headerMenu = currentMenu;

  React.useEffect(() => {
    const name = find(headerMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(name?.label || "") || []);
  }, [location.pathname]);

  return (
    <AdminPage
      title={<div className="admin-page-title">Fleet Manager</div>}
      headerMenu={headerMenu}
      breadcrumb={breadcrumb}
    >
      <Outlet />
    </AdminPage>
  );
};

export default FleetPage;
