import React from "react";
import { useSelector } from "react-redux";
import { IUnit23243 } from "../../../common/types";
import { Form, Select } from "../../atoms";
import { UserSelectedFieldWrapper } from "./Fields.style";

interface IUnitSelectedFieldProps {
  bordered?: boolean;
  name: string | Array<string | number>;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getUnit?: () => IUnit23243;
  onChange?: (value: any) => void;
}
export const UnitSelectedField: React.FC<IUnitSelectedFieldProps> = ({
  name,
  label,
  mode,
  rules,
  getUnit,
  onChange,
  bordered = true,
  style,
  placeholder,
  maxTagCount,
  initialValue,
}) => {
  const { unit23243 } = useSelector((state: any) => state.unit23243);

  return (
    <UserSelectedFieldWrapper>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
      >
        <Select
          showSearch
          bordered={bordered}
          style={style}
          placeholder={placeholder || label}
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={unit23243.map((category: IUnit23243) => ({
            value: category.id,
            label: category.name,
            search: `${category?.name || ""}`,
          }))}
          mode={mode}
          maxTagCount={maxTagCount}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </UserSelectedFieldWrapper>
  );
};

export default UnitSelectedField;
