/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { IReturn5216 } from "../../../common/types";
import {
  UPDATE_RETURN5216,
  DELETE_RETURN5216,
  FETCH_RETURN5216,
  LISTEN_TO_SELECTED_RETURN5216,
  FILTER_RETURN5216,
} from "./return5216Constants";

export function listenToReturn5216(return5216: IReturn5216[]) {
  return {
    type: FETCH_RETURN5216,
    payload: return5216,
  };
}

export function listenToSelectedReturn5216(return5216: IReturn5216) {
  return {
    type: LISTEN_TO_SELECTED_RETURN5216,
    payload: return5216,
  };
}

export function updateReturn5216(return5216: IReturn5216) {
  return {
    type: UPDATE_RETURN5216,
    payload: return5216,
  };
}

export function deleteReturn5216(return5216Id: string) {
  return {
    type: DELETE_RETURN5216,
    payload: return5216Id,
  };
}

export function listenToReturn5216Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_RETURN5216,
    payload: filters,
  };
}
