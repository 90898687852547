import { IOrderStocks35474 } from "../../../common/types";
import {
  CREATE_ORDERSTOCKS35474,
  UPDATE_ORDERSTOCKS35474,
  DELETE_ORDERSTOCKS35474,
  FETCH_ORDERSTOCKS35474,
  LISTEN_TO_SELECTED_ORDERSTOCKS35474,
} from "./orderStocks35474Constants";

const initialState = {
  orderStocks35474: [],
  selectedOrderStocks35474: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function orderStocks35474Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_ORDERSTOCKS35474:
      return {
        ...state,
        orderStocks35474: [...state.orderStocks35474, payload],
      };
    case UPDATE_ORDERSTOCKS35474:
      return {
        ...state,
        orderStocks35474: [
          ...state.orderStocks35474.filter(
            (evt: IOrderStocks35474) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_ORDERSTOCKS35474:
      return {
        ...state,
        orderStocks35474: [
          ...state.orderStocks35474.filter(
            (evt: IOrderStocks35474) => evt.id !== payload
          ),
        ],
      };
    case FETCH_ORDERSTOCKS35474:
      return {
        ...state,
        orderStocks35474: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_ORDERSTOCKS35474:
      return {
        ...state,
        selectedorderStocks35474: payload,
      };

    default:
      return state;
  }
}
