/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchRecipe75190FromFirestore } from "../../../firestore/firestoreService";
import { listenToRecipe75190 } from "../../../redux/data/recipe75190/recipe75190Actions";

export const RecipesListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchRecipe75190FromFirestore([]),
    data: listenToRecipe75190,
    deps: [dispatch],
  });
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddRecipesModal",
        modalProps: { title: "Add Recipes Form" },
      })
    );
  };

  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Recipes
      </Button>

      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
};
