/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { OrderListMole } from "../../molecules";
import { OrdersWrapper } from "./Orders.style";
import { ProductionOrderListToolbar } from "./ProductionOrderListToolbar";

export const ProductionOrderList: React.FC = () => {
  const { order50453 } = useSelector((state: any) => state.order50453);
  const history = useNavigate();
  const handelEditOrder = (id: string, productionLineId: string) => () => {
    history(`/production-location/${productionLineId}/order/${id}`);
  };
  return (
    <AdminPageBody pageToolbar={<ProductionOrderListToolbar />}>
      <OrdersWrapper>
        <OrderListMole
          data={order50453}
          showPagination
          showLocation
          showProduction={false}
          showDeleteBtn
          showExportBtn
          editOrder={handelEditOrder}
        />
      </OrdersWrapper>
    </AdminPageBody>
  );
};
