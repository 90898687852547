/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Button } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchUser94013FromFirestore } from "../../../firestore/firestoreService";
import { listenToUser94013 } from "../../../redux/data/user94013/user94013Actions";

export const UsersListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const locationId = params.locationId || "";
  useFirestoreCollection({
    query: () =>
      fetchUser94013FromFirestore([
        {
          type: "filter",
          name: "locationId",
          opr: "==",
          value: locationId,
        },
      ]),
    data: listenToUser94013,
    deps: [dispatch, locationId],
  });
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddUsersModal",
        modalProps: { title: "Add Users Form" },
      })
    );
  };

  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Users
      </Button>

      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
};
