/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { IOrder50453 } from "../../../common/types";
import {
  UPDATE_ORDER50453,
  DELETE_ORDER50453,
  FETCH_ORDER50453,
  LISTEN_TO_SELECTED_ORDER50453,
  FILTER_ORDER50453,
} from "./order50453Constants";

export function listenToOrder50453(order50453: IOrder50453[]) {
  return {
    type: FETCH_ORDER50453,
    payload: order50453,
  };
}

export function listenToSelectedOrder50453(order50453: IOrder50453) {
  return {
    type: LISTEN_TO_SELECTED_ORDER50453,
    payload: order50453,
  };
}

export function updateOrder50453(order50453: IOrder50453) {
  return {
    type: UPDATE_ORDER50453,
    payload: order50453,
  };
}

export function deleteOrder50453(order50453Id: string) {
  return {
    type: DELETE_ORDER50453,
    payload: order50453Id,
  };
}

export function listenToOrder50453Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_ORDER50453,
    payload: filters,
  };
}
