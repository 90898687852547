import { ILocation1688 } from "../../../common/types";
import {
  CREATE_LOCATION1688,
  UPDATE_LOCATION1688,
  DELETE_LOCATION1688,
  FETCH_LOCATION1688,
  LISTEN_TO_SELECTED_LOCATION1688,
  FETCH_LOCATION1688_LOCATIONS,
  FETCH_LOCATION1688_PRODUCTION_LINES,
  FILTER_LOCATION1688,
} from "./location1688Constants";

const initialState = {
  location1688: [],
  location1688Locations: [],
  location1688ProductionLines: [],
  selectedLocation1688: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterLocation1688: {},
};

export default function location1688Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_LOCATION1688:
      return {
        ...state,
        location1688: [...state.location1688, payload],
      };
    case UPDATE_LOCATION1688:
      return {
        ...state,
        location1688: [
          ...state.location1688.filter(
            (evt: ILocation1688) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_LOCATION1688:
      return {
        ...state,
        location1688: [
          ...state.location1688.filter(
            (evt: ILocation1688) => evt.id !== payload
          ),
        ],
      };
    case FETCH_LOCATION1688:
      return {
        ...state,
        location1688: payload,
      };
    case FETCH_LOCATION1688_LOCATIONS:
      return {
        ...state,
        location1688Locations: payload,
      };
    case FETCH_LOCATION1688_PRODUCTION_LINES:
      return {
        ...state,
        location1688ProductionLines: payload,
      };
    case LISTEN_TO_SELECTED_LOCATION1688:
      return {
        ...state,
        selectedLocation1688: payload,
      };
    case FILTER_LOCATION1688:
      return {
        ...state,
        filterLocation1688: payload || {},
      };

    default:
      return state;
  }
}
