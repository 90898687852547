import styled from "styled-components";
export const InputFormWrapper = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
  flex: 1;
  .input-form {
    &-body {
      max-width: 1000px;
      margin: 32px auto 40px;
      font-weight: 300;
      &-full {
        max-width: 100%;
        margin: 0 32px;
      }
      .ant-form {
        padding: 0 30px;
      }
    }
    &-title {
      font-size: 32px;
      font-weight: 300;
      line-height: 40px;
      color: #1e1f21;
    }
    &-description {
      font-size: 15px;
      line-height: 32px;
      margin-bottom: 16px;
    }
    &-btn {
      width: 100%;
      height: 40px;
    }
    &-slider {
      &.ant-slider {
        flex: 1;
        &-with-marks {
          margin-bottom: 28px;
          margin: 20px;
        }
      }
    }
    &-space {
      width: 100%;
      display: flex;
      .ant-space-item {
        flex: 1;
      }
    }
    &-difficultly {
      display: flex;
      align-items: center;
      gap: 20px;
      .ant-slider {
        flex: 1;
        &-with-marks {
          margin-bottom: 28px;
          margin: 20px;
        }
      }
      .anticon {
        font-size: 24px;
        color: #898989;
      }
    }
  }
  .text-center {
    text-align: center;
  }
  .ant-input[disabled] {
    color: #000;
  }
  .stockLastUpdate {
    display: block;
    font-size: 12px;
    color: #9a9a9a;
    font-weight: 400;
  }
`;
