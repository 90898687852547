import { IProduct2143Input, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_PRODUCT2143 } from "./firebaseConstants";

export function fetchProduct2143FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_PRODUCT2143);
  return collectionWithArgs(ref, args || []);
}
export function listenToProduct2143FromFirestore(id: string) {
  return db.collection(COLLECTION_PRODUCT2143).doc(id);
}

export function addProduct2143ToFirestore(doc: IProduct2143Input) {
  return db.collection(COLLECTION_PRODUCT2143).add({
    ...doc,
    createDate: new Date(),
  });
}

export function updateProduct2143InFirestore(
  docId: string,
  doc: IProduct2143Input | any
) {
  return db.collection(COLLECTION_PRODUCT2143).doc(docId).update(doc);
}

export function toggleIsRawProduct2143IInFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_PRODUCT2143).doc(docId).update({
    isRaw: status,
  });
}

export function deleteProduct2143InFirestore(docId: string) {
  return db.collection(COLLECTION_PRODUCT2143).doc(docId).delete();
}
