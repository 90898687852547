/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { InputField, UnitSelectedField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IIngredient56227, IIngredient56227Input } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import {
  addIngredient56227ToFirestore,
  updateIngredient56227InFirestore,
} from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";

interface IProps {
  record?: IIngredient56227;
  title: string;
}
export const AddIngredientModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IIngredient56227Input = await form.validateFields();
      setLoading(true);
      if (props.record) {
        await updateIngredient56227InFirestore(props.record.id, {
          name: values.name,
          status: true,
          unitId: values.unitId,
        });
      } else {
        await addIngredient56227ToFirestore({
          name: values.name,
          status: true,
          unitId: values.unitId,
        });
      }

      setLoading(false);
      dispatch(closeModal());
      // history(`/setting/user/${user.id}`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={props.record || {}}
          >
            <InputField
              label="Name"
              name="name"
              rules={[{ required: true, message: "Missing Ingredient Name" }]}
              placeholder="Ingredient Name"
            />
            <UnitSelectedField
              label="Unit"
              name="unitId"
              style={{ width: "100%" }}
              placeholder="Unit"
              rules={[{ required: true, message: "Missing Unit Name" }]}
            />

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {props.title}
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
