/* eslint-disable no-console */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";
import { ColumnsType, TableProps } from "antd/lib/table";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { ILocation1688, IUser94013 } from "../../../common/types";
import { deleteUser94013InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import {
  permissionTypeOptions,
  permissionTypes,
} from "../../../enums/permissionType";
import { listenToUser94013Filter } from "../../../redux/data/user94013/user94013Actions";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { UsersWrapper } from "./Users.style";
import { UsersListToolbar } from "./UsersListToolbar";

export const Users: React.FC = () => {
  const dispatch = useDispatch();
  const { user94013, filterUser94013 } = useSelector(
    (state: any) => state.user94013
  );
  const { location1688 } = useSelector((state: any) => state.location1688);
  const { loading } = useSelector((state: any) => state.async);
  const handelDeleteUser94013 = async (id: string) => {
    await deleteUser94013InFirestore(id);
  };
  const handelEditUsers = (record: IUser94013) => () => {
    dispatch(
      openModal({
        modalType: "AddUserModal",
        modalProps: { title: "Update User", record },
      })
    );
  };
  const columnsList: ColumnsType<IUser94013> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: filterUser94013.name || null,
      render: (v: string) => v || "",
      sorter: (a: IUser94013, b: IUser94013) => a.name.localeCompare(b.name),
      onFilter: (value: any, record: IUser94013) =>
        record.name.toLowerCase().indexOf(value.toLowerCase()) === 0,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (v: string) => v || "",
      sorter: (a: IUser94013, b: IUser94013) => a.email.localeCompare(b.email),
    },

    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (v: string) => permissionTypes[v]?.label || "",
      filteredValue: filterUser94013.role || null,
      filters: permissionTypeOptions.map((order: any) => ({
        text: order.label,
        value: order.value,
      })),
      onFilter: (value: any, record: IUser94013) =>
        record?.role?.indexOf(value) === 0,
    },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      filterSearch: true,
      filteredValue: filterUser94013.locationId || null,
      render: (v: string) => find(location1688, ["id", v])?.name || "",
      filters: location1688.map((location: ILocation1688) => ({
        text: location.name,
        value: location.id,
      })),
      onFilter: (value: any, record: IUser94013) =>
        record?.locationId?.indexOf(value) === 0,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IUser94013) => (
        <ActionCell
          onDelete={() => handelDeleteUser94013(record.id)}
          onEdit={handelEditUsers(record)}
        />
      ),
    },
  ];
  const handleChange: TableProps<IUser94013>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    dispatch(listenToUser94013Filter(filters));
  };
  return (
    <AdminPageBody pageToolbar={<UsersListToolbar />}>
      <UsersWrapper>
        <Table
          columns={columnsList}
          dataSource={user94013}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
          onChange={handleChange}
        />
      </UsersWrapper>
    </AdminPageBody>
  );
};
