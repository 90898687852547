/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import { notification, Switch, TableProps } from "antd";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { ICategory59983, IProduct2143 } from "../../../common/types";
import {
  deleteProduct2143InFirestore,
  toggleIsRawProduct2143IInFirestore,
} from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { listenToProduct2143Filter } from "../../../redux/data/product2143/product2143Actions";
import { InventoryWrapper } from "./Inventory.style";
import { ProductsListToolbar } from "./ProductsListToolbar";

export const Products: React.FC = () => {
  const { product2143, filterProduct2143 } = useSelector(
    (state: any) => state.product2143
  );
  const { category59983 } = useSelector((state: any) => state.category59983);
  const { loading } = useSelector((state: any) => state.async);
  const dispatch = useDispatch();
  const handelDeleteProduct2143 = async (id: string) => {
    await deleteProduct2143InFirestore(id);
  };
  const handelEditProducts = (record: IProduct2143) => () => {
    dispatch(
      openModal({
        modalType: "AddProductModal",
        modalProps: { title: "Update Product", record },
      })
    );
  };
  const handelIsRawChange = (id: string) => async (status: boolean) => {
    try {
      await toggleIsRawProduct2143IInFirestore(id, status);
      notification.success({
        message: "Update product raw status",
        description: "update successfully",
      });
    } catch (error: any) {
      notification.error({
        message: "Update product raw status",
        description: error.message,
      });
    }
  };
  const columnsList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: filterProduct2143.name || null,
      render: (v: string) => v || "",
      sorter: (a: IProduct2143, b: IProduct2143) =>
        a.name.localeCompare(b.name),
      onFilter: (value: any, record: IProduct2143) =>
        record.name.toLowerCase().indexOf(value.toLowerCase()) === 0,
    },
    {
      title: "Category",
      dataIndex: "categoryId",
      key: "categoryId",
      filterSearch: true,
      filteredValue: filterProduct2143.categoryId || null,
      render: (v: string) => find(category59983, ["id", v])?.name || "",
      filters: category59983.map((category: ICategory59983) => ({
        text: category.name,
        value: category.id,
      })),
      onFilter: (value: any, record: IProduct2143) =>
        record?.categoryId?.indexOf(value) === 0,
    },
    {
      title: "Sorting",
      dataIndex: "sorting",
      key: "sorting",
      render: (v: string) => v || "",
    },
    {
      title: "Mark as raw",
      dataIndex: "isRaw",
      key: "isRaw",
      filterSearch: true,
      filteredValue: filterProduct2143.categoryId || null,
      render: (v: boolean, record: IProduct2143) => (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={v}
          onChange={handelIsRawChange(record.id)}
        />
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IProduct2143) => (
        <ActionCell
          onDelete={() => handelDeleteProduct2143(record.id)}
          onEdit={handelEditProducts(record)}
        />
      ),
    },
  ];
  const handleChange: TableProps<IProduct2143>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    dispatch(listenToProduct2143Filter(filters));
  };
  return (
    <AdminPageBody pageToolbar={<ProductsListToolbar />}>
      <InventoryWrapper>
        <Table
          columns={columnsList}
          dataSource={product2143}
          loading={loading}
          rowKey="id"
          onChange={handleChange}
        />
      </InventoryWrapper>
    </AdminPageBody>
  );
};
