import { List } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderBy } from "lodash";
import { ILocation1688, IProduction67969 } from "../../../common/types";
import { productionStatusTypes } from "../../../enums";
import {
  fetchLocation1688FromFirestore,
  fetchProduction67969FromFirestore,
} from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { Button, Divider } from "../../atoms";
import { ProductionList } from "../../molecules";
import { LocationDashboardWrapper } from "./Home.style";

export const FleetManagerDashboard: React.FC = () => {
  const [productionLines, setProductionLines] = React.useState<ILocation1688[]>(
    []
  );
  const [productions, setProductions] = React.useState<IProduction67969[]>([]);
  const history = useNavigate();
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () =>
      fetchProduction67969FromFirestore([
        {
          type: "filter",
          name: "status",
          opr: "==",
          value: productionStatusTypes.COMPLETED.value,
        },
      ]),
    data: setProductions,
    local: true,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchLocation1688FromFirestore([
        {
          type: "filter",
          name: "type",
          opr: "==",
          value: "PRODUCTION_LINE",
        },
      ]),
    data: setProductionLines,
    local: true,
    deps: [dispatch],
  });
  const handelEditProduction = (id: string, productionLineId: string) => () => {
    history(`/fleet/production/${id}/production-location/${productionLineId}`);
  };
  const handleCreateBakedOrder = (productionLineId: string) => () => {
    history(`/fleet/production-line/${productionLineId}/baked-order`);
  };
  const handleCreateRawOrder = (productionLineId: string) => () => {
    history(`/fleet/production-line/${productionLineId}/raw-order`);
  };
  return (
    <LocationDashboardWrapper>
      <div className="input-form-description text-center"></div>
      <ProductionList
        data={productions}
        showPagination={false}
        showProductionLine
        showDeleteBtn={false}
        editProduction={handelEditProduction}
      />
      <Divider>Create Order</Divider>
      <List
        size="large"
        bordered
        className="LocationDashboard-orders"
        dataSource={orderBy(productionLines, ["sorting"])}
        renderItem={(item: ILocation1688) => (
          <List.Item className="LocationDashboard-orders-item">
            <div>
              <Button
                onClick={handleCreateBakedOrder(item.id)}
                className="LocationDashboard-orders-btn"
              >
                {item.name || ""} Baked
              </Button>{" "}
              <Button
                onClick={handleCreateRawOrder(item.id)}
                className="LocationDashboard-returns-btn"
              >
                {item.name || ""} Raw
              </Button>
            </div>
            <div>{item.orderingSchedule || ""}</div>
          </List.Item>
        )}
      />
    </LocationDashboardWrapper>
  );
};
