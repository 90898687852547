/* eslint-disable no-console */
import { forEach } from "lodash";
import { IOrder50453Input, IQueryConfigArgs } from "../../common/types";
import {
  db,
  collectionWithArgs,
  setUserAddLog,
  setUserUpdateLog,
} from "./firestoreService";
import {
  COLLECTION_ORDER50453,
  COLLECTION_PRODUCT2143,
} from "./firebaseConstants";

export async function getOrderIdFromFirestore() {
  try {
    const currentOrders = await db
      .collection(COLLECTION_ORDER50453)
      .orderBy("orderId", "desc")
      .limit(1)
      .get();
    const order = currentOrders.docs[0]?.data();
    return order ? Number(order.orderId) + 1 : 1;
  } catch (err) {
    throw err;
  }
}

export function fetchOrder50453FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_ORDER50453);
  return collectionWithArgs(ref, args || []);
}
export function listenToOrder50453FromFirestore(id: string) {
  return db.collection(COLLECTION_ORDER50453).doc(id);
}

export async function addOrder50453ToFirestore(doc: IOrder50453Input) {
  const orderId = await getOrderIdFromFirestore();
  return db.collection(COLLECTION_ORDER50453).add({
    ...doc,
    ...setUserAddLog(),
    orderId,
  });
}

export async function updateOrder50453InFirestore(
  docId: string,
  doc: IOrder50453Input
) {
  try {
    const batch = db.batch();
    const orderRef = db.collection(COLLECTION_ORDER50453).doc(docId);
    const productRef = db.collection(COLLECTION_PRODUCT2143);
    if (doc.status === "SHIPPED") {
      forEach(doc.stocks, (value, key: string) => {
        const stockType = doc.type === "RAW" ? "rawStock" : "stock";
        if (value.productionStock && value.productionStock >= value.qtySent) {
          console.log(key, value?.productionStock);
          batch.update(productRef.doc(key), {
            [`stocks.${doc.productionLineId}`]: {
              [stockType]:
                +(value?.productionStock || 0) - +(value.qtySent || 0),
              locationId: doc.productionLineId,
            },
          });
          delete value.productionStock;
        } else if (
          value.qtySent &&
          +(value?.productionStock || 0) < value.qtySent
        ) {
          throw new Error(`you can have requested more then you in the stock `);
        }
      });
    }
    batch.update(orderRef, {
      ...doc,
      ...setUserUpdateLog(),
    });
    await batch.commit();
    return true;
  } catch (error) {
    throw error;
  }
}

export function activeToggleOrder50453InFirestore(
  docId: string,
  status: boolean
) {
  return db
    .collection(COLLECTION_ORDER50453)
    .doc(docId)
    .update({
      status,
      ...setUserUpdateLog(),
    });
}

export function deleteOrder50453InFirestore(docId: string) {
  return db.collection(COLLECTION_ORDER50453).doc(docId).delete();
}
