/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchProduction67969FromFirestore } from "../../../firestore/firestoreService";
import { listenToProduction67969 } from "../../../redux/data/production67969/production67969Actions";
import { productionStatusTypes } from "../../../enums";

export const ProductionBakerProductionsToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const productionLineId = params.productionLineId || "";
  useFirestoreCollection({
    query: () =>
      fetchProduction67969FromFirestore([
        {
          type: "filter",
          name: "productionLineId",
          opr: "==",
          value: productionLineId,
        },
        {
          type: "filter",
          name: "status",
          opr: "==",
          value: productionStatusTypes.DISTRIBUTED.value,
        },
      ]),
    data: listenToProduction67969,
    deps: [dispatch],
  });

  return (
    <PageToolbar>
      <div></div>
      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
};
