import { IReturn5216 } from "../../../common/types";
import {
  CREATE_RETURN5216,
  UPDATE_RETURN5216,
  DELETE_RETURN5216,
  FETCH_RETURN5216,
  LISTEN_TO_SELECTED_RETURN5216,
  FILTER_RETURN5216,
} from "./return5216Constants";

const initialState = {
  return5216: [],
  selectedReturn5216: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterReturn5216: {},
};

export default function return5216Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_RETURN5216:
      return {
        ...state,
        return5216: [...state.return5216, payload],
      };
    case UPDATE_RETURN5216:
      return {
        ...state,
        return5216: [
          ...state.return5216.filter(
            (evt: IReturn5216) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_RETURN5216:
      return {
        ...state,
        return5216: [
          ...state.return5216.filter((evt: IReturn5216) => evt.id !== payload),
        ],
      };
    case FETCH_RETURN5216:
      return {
        ...state,
        return5216: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_RETURN5216:
      return {
        ...state,
        selectedReturn5216: payload,
      };
    case FILTER_RETURN5216:
      return {
        ...state,
        filterReturn5216: payload || {},
      };
    default:
      return state;
  }
}
