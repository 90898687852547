import { Col, Row } from "antd";
import { find } from "lodash";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ILocation1688, IOrder50453 } from "../../../common/types";
import { orderTypes } from "../../../enums";
import { listenToLocation1688FromFirestore } from "../../../firestore/firestoreService";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { Tag } from "../../atoms";
import { FieldView } from "../commonFields";
import { OrderHeaderWrapper } from "./Order.style";

interface IOrderHeaderProps {
  order: IOrder50453;
  productionLine: ILocation1688 | undefined;
}
export const OrderHeader: React.FC<IOrderHeaderProps> = ({
  order,
  productionLine,
}) => {
  const [location, setLocation] = React.useState<ILocation1688>();
  const { user94013 } = useSelector((state: any) => state.user94013);
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(order?.locationId || ""),
    data: setLocation,
    local: true,
    deps: [dispatch, order.locationId],
  });
  return (
    <OrderHeaderWrapper>
      <Row gutter={[8, 8]}>
        <Col span={16}>
          <FieldView label={"Order ID"}>
            <div>{order?.orderId}</div>
          </FieldView>
          <FieldView label={"Submit By"}>
            {find(user94013, ["id", order?.createdById])?.name || ""}
          </FieldView>
          <FieldView label={"Date created"}>
            {moment(order?.createAt).format("DD/MM/YYYY")}
          </FieldView>
          <FieldView label={"Time created"}>
            {moment(order?.createAt).format("HH:mm")}
          </FieldView>
          <FieldView label={"Order Status"}>
            <Tag color={orderTypes[order?.status].color}>
              {orderTypes[order?.status].label}
            </Tag>
          </FieldView>
        </Col>
        <Col span={8}>
          <FieldView label={"Location"}>{location?.name || ""}</FieldView>
          <FieldView label={"Production Line"}>
            {productionLine?.name || ""}
          </FieldView>
          <FieldView label={"Update By"}>
            {find(user94013, ["id", order?.updateById])?.name || ""}
          </FieldView>
          <FieldView label={"Date update"}>
            {moment(order?.updateAt).format("DD/MM/YYYY")}
          </FieldView>
          <FieldView label={"Time update"}>
            {moment(order?.updateAt).format("HH:mm")}
          </FieldView>
        </Col>
      </Row>
    </OrderHeaderWrapper>
  );
};
