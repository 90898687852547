export const CREATE_LOCATION1688 = "CREATE_LOCATION1688";
export const UPDATE_LOCATION1688 = "UPDATE_LOCATION1688";
export const DELETE_LOCATION1688 = "DELETE_LOCATION1688";
export const FETCH_LOCATION1688 = "FETCH_LOCATION1688";
export const LISTEN_TO_SELECTED_LOCATION1688 =
  "LISTEN_TO_SELECTED_LOCATION1688";
export const FETCH_LOCATION1688_LOCATIONS = "FETCH_LOCATION1688_LOCATIONS";
export const FETCH_LOCATION1688_PRODUCTION_LINES =
  "FETCH_LOCATION1688_PRODUCTION_LINES";
export const FILTER_LOCATION1688 = "FILTER_LOCATION1688";
