/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { find } from "lodash";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ActionCell } from "../../molecules";
import { ILocation1688 } from "../../../common/types";
import { deleteLocation1688InFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { LocationsWrapper } from "./Locations.style";
import { LocationsListToolbar } from "./LocationsListToolbar";

export const Locations: React.FC = () => {
  const { location1688Locations } = useSelector(
    (state: any) => state.location1688
  );
  const { user94013 } = useSelector((state: any) => state.user94013);
  const { loading } = useSelector((state: any) => state.async);
  const history = useNavigate();
  const handelDeleteLocation1688 = async (id: string) => {
    await deleteLocation1688InFirestore(id);
  };
  const handelEditLocations = (id: string) => () => {
    history(`/location/${id}/mainDetails`);
  };
  const columnsList = [
    {
      title: "Location name",
      dataIndex: "name",
      key: "name",
      render: (v: string) => v || "",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (v: string) => v || "",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (v: string) => v || "",
    },

    {
      title: "Manager",
      dataIndex: "managerId",
      key: "managerId",
      render: (v: string) => find(user94013, ["id", v])?.name || "",
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: ILocation1688) => (
        <ActionCell
          onDelete={() => handelDeleteLocation1688(record.id)}
          onEdit={handelEditLocations(record.id)}
        />
      ),
    },
  ];
  return (
    <AdminPageBody pageToolbar={<LocationsListToolbar />}>
      <LocationsWrapper>
        <Table
          columns={columnsList}
          dataSource={location1688Locations}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 20,
          }}
        />
      </LocationsWrapper>
    </AdminPageBody>
  );
};
