import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { find, forEach } from "lodash";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Form,
  InputFormWrapper,
} from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { OrderHeader, OrderCategory } from "../../molecules";
import {
  listenToLocation1688FromFirestore,
  listenToOrder50453FromFirestore,
  updateOrder50453InFirestore,
} from "../../../firestore/firestoreService";
import { ILocation1688, IOrder50453Input } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedOrder50453 } from "../../../redux/data/order50453/order50453Actions";
import { orderTypes, productionTypes } from "../../../enums";

export const LocationOrderDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const { category59983 } = useSelector((state: any) => state.category59983);

  const [loading, setLoading] = React.useState(false);

  const [productionLine, setProductionLine] = React.useState<ILocation1688>();
  const [error, setError] = React.useState("");
  const params = useParams();
  const orderId = params.orderId || "";
  const productionLineId = params.productionLineId || "";
  const { selectedOrder50453 } = useSelector((state: any) => state.order50453);
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToOrder50453FromFirestore(orderId),
    data: listenToSelectedOrder50453,
    deps: [dispatch, orderId],
    shouldExecute: !!orderId,
  });
  userFirestoreDoc({
    query: () => listenToLocation1688FromFirestore(productionLineId),
    data: setProductionLine,
    local: true,
    deps: [dispatch, productionLineId],
    shouldExecute: !!productionLineId,
  });
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values: IOrder50453Input = await form.validateFields();
      let hasIssues = false;
      forEach(values.stocks, (value, key: string) => {
        if (value.issueComment) {
          hasIssues = true;
        }
        values.stocks[key] = {
          ...value,
          qtySent: +value.qtySent || 0,
          receivedStock: +(value?.receivedStock || 0),
          issueComment: value.issueComment || "",
        };
      });
      await updateOrder50453InFirestore(orderId, {
        status: hasIssues
          ? orderTypes.ACCEPTED_WITH_ISSUES.value
          : orderTypes.ACCEPTED.value,
        stocks: { ...values.stocks },
        type: productionTypes.BAKED.value,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields, [selectedOrder50453, orderId]);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedOrder50453}
          >
            {selectedOrder50453 && (
              <OrderHeader
                order={selectedOrder50453}
                productionLine={productionLine}
              />
            )}
            <Form.List name="stocks">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Collapse
                    defaultActiveKey={[...(productionLine?.categories || [])]}
                  >
                    {productionLine?.categories?.map((category) => (
                      <Collapse.Panel
                        header={
                          find(category59983, ["id", category])?.name || ""
                        }
                        key={category}
                      >
                        <OrderCategory
                          categoryId={category}
                          stocks={selectedOrder50453?.stocks}
                          viewsSetting={{
                            currentStock: { show: false, disabled: true },
                            request: { show: false, disabled: true },
                            inventoryStock: { show: false, disabled: false },
                            qtySent: { show: true, disabled: true },
                            receivedStock: {
                              show: true,
                              disabled:
                                selectedOrder50453?.status !==
                                orderTypes.SHIPPED.value,
                            },
                            comment: { show: false, disabled: false },
                            issueComment: {
                              show: true,
                              disabled:
                                selectedOrder50453?.status !==
                                orderTypes.SHIPPED.value,
                            },
                          }}
                        />
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </>
              )}
            </Form.List>
            <Divider></Divider>
            {selectedOrder50453?.status === orderTypes.SHIPPED.value && (
              <Button
                type="primary"
                className="input-form-btn"
                onClick={handleSubmit}
                loading={loading}
              >
                Accept Order
              </Button>
            )}
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
