import { FilterValue } from "antd/lib/table/interface";
import { IOrderSuggestion71819 } from "../../../common/types";
import {
  UPDATE_ORDERSUGGESTION71819,
  DELETE_ORDERSUGGESTION71819,
  FETCH_ORDERSUGGESTION71819,
  LISTEN_TO_SELECTED_ORDERSUGGESTION71819,
  FILTER_ORDERSUGGESTION71819,
} from "./orderSuggestion71819Constants";

export function listenToOrderSuggestion71819(
  orderSuggestion71819: IOrderSuggestion71819[]
) {
  return {
    type: FETCH_ORDERSUGGESTION71819,
    payload: orderSuggestion71819,
  };
}

export function listenToSelectedOrderSuggestion71819(
  orderSuggestion71819: IOrderSuggestion71819
) {
  return {
    type: LISTEN_TO_SELECTED_ORDERSUGGESTION71819,
    payload: orderSuggestion71819,
  };
}

export function updateOrderSuggestion71819(
  orderSuggestion71819: IOrderSuggestion71819
) {
  return {
    type: UPDATE_ORDERSUGGESTION71819,
    payload: orderSuggestion71819,
  };
}

export function deleteOrderSuggestion71819(orderSuggestion71819Id: string) {
  return {
    type: DELETE_ORDERSUGGESTION71819,
    payload: orderSuggestion71819Id,
  };
}

export function listenToOrderSuggestion71819Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_ORDERSUGGESTION71819,
    payload: filters,
  };
}
