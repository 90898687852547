export const CREATE_USER94013 = "CREATE_USER94013";
export const UPDATE_USER94013 = "UPDATE_USER94013";
export const DELETE_USER94013 = "DELETE_USER94013";
export const FETCH_USER94013 = "FETCH_USER94013";
export const LISTEN_TO_SELECTED_USER94013 = "LISTEN_TO_SELECTED_USER94013";

export const FETCH_USER94013_LOCATIONS = "FETCH_USER94013_LOCATIONS";
export const FETCH_USER94013_PRODUCTION_LINES =
  "FETCH_USER94013_PRODUCTION_LINES";
export const FILTER_USER94013 = "FILTER_USER94013";
