import { Table as TableAnt, TableProps } from "antd";
import styled from "styled-components";

export const Table: typeof TableAnt = styled(TableAnt)<TableProps<any>>`
  &.vertical {
    .ant-table-thead > tr > th {
      height: 100px;
      writing-mode: vertical-rl;
      text-orientation: sideways-right;
    }
  }
  .ant-table-thead > tr > th {
    color: #444;
    font-weight: 700;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    padding: 0px 16px;
    height: 40px;
    font-size: 16px;
  }
  .ant-table-tbody > tr > td {
    padding: 5px 16px;
    font-size: 16px;
  }
` as any;
