/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../atoms";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchProduction67969FromFirestore } from "../../../firestore/firestoreService";
import { listenToProduction67969 } from "../../../redux/data/production67969/production67969Actions";

export const ProductionsListToolbar: React.FC = ({}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useNavigate();

  const productionLineId = params.productionLineId || "";
  useFirestoreCollection({
    query: () =>
      fetchProduction67969FromFirestore([
        {
          type: "filter",
          name: "productionLineId",
          opr: "==",
          value: productionLineId,
        },
      ]),
    data: listenToProduction67969,
    deps: [dispatch],
  });
  const handelAddRawProduction = () => {
    history(`/production-location/${productionLineId}/add-raw-production`);
  };
  const handelAddProduction = () => {
    history(`/production-location/${productionLineId}/add-production`);
  };
  return (
    <PageToolbar>
      <div></div>
      <div className="page-toolbar-right-children-elements">
        <>
          <Button
            type="primary"
            onClick={handelAddRawProduction}
            block
            icon={<PlusOutlined />}
            className="page-toolbar-btn"
          >
            New raw production
          </Button>
          <Button
            type="primary"
            onClick={handelAddProduction}
            block
            icon={<PlusOutlined />}
            className="page-toolbar-btn"
          >
            New baked production
          </Button>
        </>
      </div>
    </PageToolbar>
  );
};
