/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ReturnListMole } from "../../molecules";
import { ReturnsWrapper } from "./Returns.style";
import { ProductionReturnListToolbar } from "./ProductionReturnListToolbar";

export const ProductionReturnList: React.FC = () => {
  const { return5216 } = useSelector((state: any) => state.return5216);
  return (
    <AdminPageBody pageToolbar={<ProductionReturnListToolbar />}>
      <ReturnsWrapper>
        <ReturnListMole
          data={return5216}
          showPagination
          showLocation
          showProduction={false}
          showDeleteBtn={false}
        />
      </ReturnsWrapper>
    </AdminPageBody>
  );
};
