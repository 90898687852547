/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ProductionList } from "../../molecules";
import { ProductionLineWrapper } from "./ProductionLine.style";
import { ProductionsListToolbar } from "./ProductionsListToolbar";

interface IProps {
  role: string;
}
export const Productions: React.FC<IProps> = ({ role }) => {
  const { production67969 } = useSelector(
    (state: any) => state.production67969
  );
  const history = useNavigate();
  const handelEditProduction67969 =
    (id: string, productionLineId: string) => () => {
      history(`/production-location/${productionLineId}/production/${id}`);
    };

  return (
    <AdminPageBody pageToolbar={<ProductionsListToolbar />}>
      <ProductionLineWrapper>
        <ProductionList
          data={production67969}
          showPagination
          showProductionLine
          showDeleteBtn
          editProduction={handelEditProduction67969}
        />
      </ProductionLineWrapper>
    </AdminPageBody>
  );
};
