/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchReturn5216FromFirestore } from "../../../firestore/firestoreService";
import { listenToReturn5216 } from "../../../redux/data/return5216/return5216Actions";

export const ReturnListsListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const productionLineId = params.productionLineId || "";
  useFirestoreCollection({
    query: () =>
      fetchReturn5216FromFirestore([
        {
          type: "filter",
          name: "productionId",
          opr: "==",
          value: productionLineId,
        },
      ]),
    data: listenToReturn5216,
    deps: [dispatch, productionLineId],
  });

  return (
    <PageToolbar>
      <div></div>
      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
};
