/* eslint-disable no-console */
import { FilterValue } from "antd/lib/table/interface";
import { IProduction67969 } from "../../../common/types";
import {
  UPDATE_PRODUCTION67969,
  DELETE_PRODUCTION67969,
  FETCH_PRODUCTION67969,
  LISTEN_TO_SELECTED_PRODUCTION67969,
  FILTER_PRODUCTION67969,
} from "./production67969Constants";

export function listenToProduction67969(production67969: IProduction67969[]) {
  return {
    type: FETCH_PRODUCTION67969,
    payload: production67969,
  };
}

export function listenToSelectedProduction67969(
  production67969: IProduction67969
) {
  return {
    type: LISTEN_TO_SELECTED_PRODUCTION67969,
    payload: production67969,
  };
}

export function updateProduction67969(production67969: IProduction67969) {
  return {
    type: UPDATE_PRODUCTION67969,
    payload: production67969,
  };
}

export function deleteProduction67969(production67969Id: string) {
  return {
    type: DELETE_PRODUCTION67969,
    payload: production67969Id,
  };
}

export function listenToProduction67969Filter(
  filters: Record<string, FilterValue | null>
) {
  return {
    type: FILTER_PRODUCTION67969,
    payload: filters,
  };
}
