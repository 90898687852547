import { IRecipe75190Input, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_RECIPE75190 } from "./firebaseConstants";

export function fetchRecipe75190FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_RECIPE75190);
  return collectionWithArgs(ref, args || []);
}
export function listenToRecipe75190FromFirestore(id: string) {
  return db.collection(COLLECTION_RECIPE75190).doc(id);
}

export function addRecipe75190ToFirestore(doc: IRecipe75190Input) {
  return db.collection(COLLECTION_RECIPE75190).add({
    ...doc,
    createDate: new Date(),
  });
}

export function updateRecipe75190InFirestore(
  docId: string,
  doc: IRecipe75190Input | any
) {
  return db.collection(COLLECTION_RECIPE75190).doc(docId).update(doc);
}

export function activeToggleRecipe75190InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_RECIPE75190).doc(docId).update({
    status,
  });
}

export function deleteRecipe75190InFirestore(docId: string) {
  return db.collection(COLLECTION_RECIPE75190).doc(docId).delete();
}
