import {
  CREATE_STOCK16371,
  FETCH_STOCK16371,
  LISTEN_TO_SELECTED_STOCK16371,
} from "./stock16371Constants";

const initialState = {
  stock16371: {},
  selectedStock16371: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function stock16371Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_STOCK16371:
      return {
        ...state,
        stock16371: payload,
      };
    case FETCH_STOCK16371:
      return {
        ...state,
        stock16371: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_STOCK16371:
      return {
        ...state,
        selectedstock16371: payload,
      };

    default:
      return state;
  }
}
