/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { InputField, InputNumberField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { ILocation1688, ILocation1688Input } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { addLocation1688ToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";

interface IProps {
  record?: ILocation1688;
  title: string;
}
export const AddProductionLineModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: ILocation1688Input = await form.validateFields();
      setLoading(true);
      await addLocation1688ToFirestore({
        name: values.name,
        sorting: values.sorting || 0,
        status: true,
        city: "",
        managerId: "",
        address: "",
        type: "PRODUCTION_LINE",
      });

      setLoading(false);
      dispatch(closeModal());
      // history(`/setting/user/${user.id}`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={props.record || {}}
          >
            <InputField
              label="Location Name"
              name="name"
              rules={[{ required: true, message: "Missing Location Name" }]}
              placeholder="Location Name"
            />
            <InputNumberField
              label="Sorting"
              name="sorting"
              min={0}
              rules={[{ required: true, message: "Missing Sorting" }]}
              placeholder="sorting"
            />
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {props.title}
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
