/* eslint-disable no-console */
import { mapKeys } from "lodash";
import { IStock16371 } from "../../../common/types";
import {
  UPDATE_STOCK16371,
  DELETE_STOCK16371,
  FETCH_STOCK16371,
  LISTEN_TO_SELECTED_STOCK16371,
} from "./stock16371Constants";

export function listenToStock16371(stock16371: IStock16371[]) {
  return {
    type: FETCH_STOCK16371,
    payload: mapKeys(stock16371, (item: IStock16371) => item.id),
  };
}

export function listenToSelectedStock16371(stock16371: IStock16371) {
  return {
    type: LISTEN_TO_SELECTED_STOCK16371,
    payload: stock16371,
  };
}

export function updateStock16371(stock16371: IStock16371) {
  return {
    type: UPDATE_STOCK16371,
    payload: stock16371,
  };
}

export function deleteStock16371(stock16371Id: string) {
  return {
    type: DELETE_STOCK16371,
    payload: stock16371Id,
  };
}
