/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined } from "@ant-design/icons";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchReturn5216FromFirestore } from "../../../firestore/firestoreService";
import {
  listenToReturn5216,
  listenToReturn5216Filter,
} from "../../../redux/data/return5216/return5216Actions";
import { Button, DatePicker, Input } from "../../atoms";
const { RangePicker } = DatePicker;
export const ReturnListListToolbar: React.FC = () => {
  const { filterReturn5216 } = useSelector((state: any) => state.return5216);
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchReturn5216FromFirestore([]),
    data: listenToReturn5216,
    deps: [dispatch],
  });
  const handelRestFilter = () => {
    dispatch(listenToReturn5216Filter({}));
  };
  const handelOnSearch = (value: string) => {
    dispatch(
      listenToReturn5216Filter({ ...filterReturn5216, returnId: [value] })
    );
  };
  const handelOnData = (value: any) => {
    console.log(value);
    dispatch(
      listenToReturn5216Filter({ ...filterReturn5216, createAt: [value] })
    );
  };
  return (
    <PageToolbar>
      <div></div>
      <div className="page-toolbar-right-children-elements">
        <Input.Search
          placeholder="Search Return"
          allowClear
          onSearch={handelOnSearch}
          style={{ width: 304 }}
        />
        <RangePicker
          allowClear
          onChange={handelOnData}
          style={{ width: 304 }}
        />
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          Reset Filters
        </Button>
      </div>
    </PageToolbar>
  );
};
