import { IOrderSuggestion71819 } from "../../../common/types";
import {
  CREATE_ORDERSUGGESTION71819,
  UPDATE_ORDERSUGGESTION71819,
  DELETE_ORDERSUGGESTION71819,
  FETCH_ORDERSUGGESTION71819,
  LISTEN_TO_SELECTED_ORDERSUGGESTION71819,
  FILTER_ORDERSUGGESTION71819,
} from "./orderSuggestion71819Constants";

const initialState = {
  orderSuggestion71819: [],
  selectedOrderSuggestion71819: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterOrderSuggestion71819: {},
};

export default function orderSuggestion71819Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_ORDERSUGGESTION71819:
      return {
        ...state,
        orderSuggestion71819: [...state.orderSuggestion71819, payload],
      };
    case UPDATE_ORDERSUGGESTION71819:
      return {
        ...state,
        orderSuggestion71819: [
          ...state.orderSuggestion71819.filter(
            (evt: IOrderSuggestion71819) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_ORDERSUGGESTION71819:
      return {
        ...state,
        orderSuggestion71819: [
          ...state.orderSuggestion71819.filter(
            (evt: IOrderSuggestion71819) => evt.id !== payload
          ),
        ],
      };
    case FETCH_ORDERSUGGESTION71819:
      return {
        ...state,
        orderSuggestion71819: payload,
      };
    case LISTEN_TO_SELECTED_ORDERSUGGESTION71819:
      return {
        ...state,
        selectedORDERSUGGESTION71819: payload,
      };
    case FILTER_ORDERSUGGESTION71819:
      return {
        ...state,
        filterORDERSUGGESTION71819: payload || {},
      };
    default:
      return state;
  }
}
