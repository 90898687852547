import { LISTEN_TO_MY_PROFILE } from "./myProfileConstants";

const initialState = {
  myProfile: null,
  myMemberProfile: null,
  myProfileInitiatives: [],
  myProfileOkrs: [],
  lastVisible: null,
  filter: "all",
};

export default function cycleReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case LISTEN_TO_MY_PROFILE:
      return {
        ...state,
        myProfile: payload,
      };
    default:
      return state;
  }
}
