import { IGoodsReceived26115 } from "../../../common/types";
import {
  CREATE_GOODSRECEIVED26115,
  UPDATE_GOODSRECEIVED26115,
  DELETE_GOODSRECEIVED26115,
  FETCH_GOODSRECEIVED26115,
  LISTEN_TO_SELECTED_GOODSRECEIVED26115,
} from "./goodsReceived26115Constants";

const initialState = {
  goodsReceived26115: [],
  selectedGoodsReceived26115: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function goodsReceived26115Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_GOODSRECEIVED26115:
      return {
        ...state,
        goodsReceived26115: [...state.goodsReceived26115, payload],
      };
    case UPDATE_GOODSRECEIVED26115:
      return {
        ...state,
        goodsReceived26115: [
          ...state.goodsReceived26115.filter(
            (evt: IGoodsReceived26115) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_GOODSRECEIVED26115:
      return {
        ...state,
        goodsReceived26115: [
          ...state.goodsReceived26115.filter(
            (evt: IGoodsReceived26115) => evt.id !== payload
          ),
        ],
      };
    case FETCH_GOODSRECEIVED26115:
      return {
        ...state,
        goodsReceived26115: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_GOODSRECEIVED26115:
      return {
        ...state,
        selectedgoodsReceived26115: payload,
      };

    default:
      return state;
  }
}
