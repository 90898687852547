/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { InputField, SelectField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IVendor83788, IVendor83788Input } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import {
  addVendor83788ToFirestore,
  updateVendor83788InFirestore,
} from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { cityTypeOptions } from "../../../enums";
interface IProps {
  record?: IVendor83788;
  title: string;
}

export const AddVendorModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values: IVendor83788Input = await form.validateFields();

      if (props.record) {
        await updateVendor83788InFirestore(props.record.id, {
          name: values.name,
          status: true,
          email: values.email,
          city: values.city,
          phone: values.phone,
        });
      } else {
        await addVendor83788ToFirestore({
          name: values.name,
          status: true,
          email: values.email,
          city: values.city,
          phone: values.phone,
        });
      }

      setLoading(false);
      dispatch(closeModal());
      // history(`/setting/user/${user.id}`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={props.record || {}}
          >
            <InputField
              label="Name"
              name="name"
              rules={[{ required: true, message: "Missing User Name" }]}
              placeholder="User Name"
            />
            <SelectField
              label="City"
              name="city"
              style={{ width: "100%" }}
              placeholder="City"
              options={cityTypeOptions}
            />
            <InputField
              label="email"
              name="email"
              rules={[{ required: true, message: "Missing Email" }]}
              placeholder="Email"
            />
            <InputField
              label="Phone"
              name="phone"
              rules={[{ required: true, message: "Missing Phone" }]}
              placeholder="Phone"
            />

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {props.title}
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
