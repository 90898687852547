/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchLocation1688FromFirestore } from "../../../firestore/firestoreService";
import { listenToLocation1688ProductionLine } from "../../../redux/data/location1688/location1688Actions";

export const ProductionLineListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () =>
      fetchLocation1688FromFirestore([
        {
          type: "filter",
          name: "type",
          opr: "==",
          value: "PRODUCTION_LINE",
        },
      ]),
    data: listenToLocation1688ProductionLine,
    deps: [dispatch],
  });
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddProductionLineModal",
        modalProps: { title: "Add Production Line" },
      })
    );
  };

  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Production Line
      </Button>

      <div className="page-toolbar-right-children-elements"></div>
    </PageToolbar>
  );
};
