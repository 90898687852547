import { IIngredient56227Input, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_INGREDIENT56227 } from "./firebaseConstants";

export function fetchIngredient56227FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_INGREDIENT56227);
  return collectionWithArgs(ref, args || []);
}
export function listenToIngredient56227FromFirestore(id: string) {
  return db.collection(COLLECTION_INGREDIENT56227).doc(id);
}

export function addIngredient56227ToFirestore(doc: IIngredient56227Input) {
  return db.collection(COLLECTION_INGREDIENT56227).add({
    ...doc,
    createDate: new Date(),
  });
}

export function updateIngredient56227InFirestore(
  docId: string,
  doc: IIngredient56227Input | any
) {
  return db.collection(COLLECTION_INGREDIENT56227).doc(docId).update(doc);
}

export function activeToggleIngredient56227InFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_INGREDIENT56227).doc(docId).update({
    status,
  });
}

export function deleteIngredient56227InFirestore(docId: string) {
  return db.collection(COLLECTION_INGREDIENT56227).doc(docId).delete();
}
