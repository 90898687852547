import { find } from "lodash";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Tag } from "../../App/components/atoms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";

const currentMenu = [
  // appendRouteHere
  {
    key: "orderList",
    label: "Order list",
    link: "/orders",
  },
];
const currentBreadcrumb = (name: string) => [
  {
    key: "page",
    label: name,
    link: "#",
  },
];

const OrdersPage: React.FC = () => {
  const location = useLocation();
  const [pageName, setPageName] = React.useState<string>("");
  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  React.useEffect(() => {
    const name = find(currentMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(name?.label || "") || []);
    setPageName(name?.label || "");
  }, [location.pathname]);

  return (
    <AdminPage
      title={
        <div className="admin-page-title">
          Orders <Tag>{pageName}</Tag>
        </div>
      }
      headerMenu={currentMenu}
      breadcrumb={breadcrumb}
    >
      <Outlet />
    </AdminPage>
  );
};

export default OrdersPage;
