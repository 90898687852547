import { IIngredient56227 } from "../../../common/types";
import {
  CREATE_INGREDIENT56227,
  UPDATE_INGREDIENT56227,
  DELETE_INGREDIENT56227,
  FETCH_INGREDIENT56227,
  LISTEN_TO_SELECTED_INGREDIENT56227,
  FILTER_INGREDIENT56227,
} from "./ingredient56227Constants";

const initialState = {
  ingredient56227: [],
  selectedIngredient56227: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterIngredient56227: {},
};

export default function ingredient56227Reducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_INGREDIENT56227:
      return {
        ...state,
        ingredient56227: [...state.ingredient56227, payload],
      };
    case UPDATE_INGREDIENT56227:
      return {
        ...state,
        ingredient56227: [
          ...state.ingredient56227.filter(
            (evt: IIngredient56227) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_INGREDIENT56227:
      return {
        ...state,
        ingredient56227: [
          ...state.ingredient56227.filter(
            (evt: IIngredient56227) => evt.id !== payload
          ),
        ],
      };
    case FETCH_INGREDIENT56227:
      return {
        ...state,
        ingredient56227: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_INGREDIENT56227:
      return {
        ...state,
        selectedIngredient56227: payload,
      };
    case FILTER_INGREDIENT56227:
      return {
        ...state,
        filterIngredient56227: payload || {},
      };
    default:
      return state;
  }
}
