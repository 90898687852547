/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { ILocation1688Input } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { addLocation1688ToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";

export const AddLocationModal: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: ILocation1688Input = await form.validateFields();
      setLoading(true);
      const location = await addLocation1688ToFirestore({
        name: values.name,
        status: true,
        address: "",
        managerId: "",
        city: "",
        type: "LOCATION",
      });
      setLoading(false);
      dispatch(closeModal());
      history(`/location/${location.id}/mainDetails`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <InputField
              label="Location Name"
              name="name"
              rules={[{ required: true, message: "Missing Location Name" }]}
              placeholder="Location Name"
            />
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Add Location
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
