/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchUser94013FromFirestore } from "../../../firestore/firestoreService";
import {
  listenToUser94013,
  listenToUser94013Filter,
} from "../../../redux/data/user94013/user94013Actions";

export const UsersListToolbar: React.FC = () => {
  const { filterUser94013 } = useSelector((state: any) => state.user94013);
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchUser94013FromFirestore([]),
    data: listenToUser94013,
    deps: [dispatch],
  });
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddUserModal",
        modalProps: { title: "Add Users Form" },
      })
    );
  };
  const handelRestFilter = () => {
    dispatch(listenToUser94013Filter({}));
  };
  const handelOnSearch = (value: string) => {
    dispatch(listenToUser94013Filter({ ...filterUser94013, name: [value] }));
  };
  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Users
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Input.Search
          placeholder="Search User"
          allowClear
          onSearch={handelOnSearch}
          style={{ width: 304 }}
        />
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          Reset Filters
        </Button>
      </div>
    </PageToolbar>
  );
};
