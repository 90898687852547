/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputField, VendorSelectedField } from "../../molecules";
import { addGoodsReceived26115ToFirestore } from "../../../firestore/firestoreService";
import { IGoodsReceived26115Input } from "../../../common/types";
import { ProductionLineGoods } from "../../molecules/ProductionLine/ProductionLineGoods";

export const GoodsReceivedDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const productionLineId = params.productionLineId || "";

  const { selectedGoodsReceived26115 } = useSelector(
    (state: any) => state.goodsReceived26115
  );
  const handleSubmit = async () => {
    try {
      const values: IGoodsReceived26115Input = await form.validateFields();
      setLoading(true);
      await addGoodsReceived26115ToFirestore({
        vendorId: values.vendorId,
        productionLineId,
        goods: values.goods,
        documentNumber: values.documentNumber,
        status: false,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && <Alert message={error} type="error" />}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedGoodsReceived26115}
          >
            <Row gutter={30}>
              <Col span={24}>
                <VendorSelectedField
                  label="Vendor"
                  name="vendorId"
                  rules={[{ required: true, message: "Missing Vendor" }]}
                  placeholder="Vendor"
                />
                <InputField
                  label="Document Number"
                  name="documentNumber"
                  rules={[
                    { required: true, message: "Missing Document Numbere" },
                  ]}
                  placeholder="Document Number"
                />
              </Col>
            </Row>
            <Form.List name="goods">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    // const val = Form.useWatch([field.name, "extraId"], form);
                    // console.log(val);
                    <ProductionLineGoods
                      key={field.key}
                      field={field}
                      index={index}
                      remove={remove}
                    />
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Item
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
